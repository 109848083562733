<template>
  <div class="navbar">
    <img src="../../assets/images/logo.png" alt="" class="user-logo" @click="$router.push('/community/collectionList')"/>

    <!-- v-show="!isModelPage" -->
    <div class="nav-tool">
      <ul class="tool-container" v-if="isScreenShow">
        <!-- 颜色筛选 -->
        <el-popover
          placement="bottom"
          width="225"
          trigger="click"
          popper-class="color-popper"
        >
          <div class="color-select-layer">
            <chrome-picker
              v-model="colorParamsTest"
              @input="colorValueChange"
            ></chrome-picker>
            <div class="slider-box">
              <span>准确度</span
              ><el-slider
                :max="1"
                :min="0.6"
                :step="0.01"
                v-model="searchParams.accuracy"
                :show-tooltip="false"
                @change="handleAccurancyChange"
              ></el-slider>
            </div>
          </div>
          <li
            class="tool-item tool-item-first"
            :class="{ active: colorParams.r }"
            slot="reference"
            title="颜色筛选"
          >
            <div
              class="tool-icon icon-fill-color"
              :style="{
                background:
                  'rgb(' +
                  colorParams.r +
                  ',' +
                  colorParams.g +
                  ',' +
                  colorParams.b +
                  ')',
              }"
              v-if="colorParams.r"
            ></div>
            <div class="tool-icon icon-color" v-else></div>

            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="colorParams.r"
              @click="handleClearColor"
            ></i>
          </li>
        </el-popover>
        <!-- 标签筛选 -->
        <el-popover placement="bottom" width="300" trigger="click">
          <el-select
            style="width: 100%"
            v-model="tagIds"
            clearable
            multiple
            filterable
            remote
            value-key="id"
            placeholder="请输入关键词"
            :loading="tagLoading"
            :remote-method="remoteMethodGetTag"
            @change="handleTagChange"
            @clear="handleTagClear"
          >
            <el-option
              v-for="item in tagOptions"
              :key="item.type + '_' + item.id"
              :label="item.name"
              :value="item"
            >
              <span style="float: left">{{ item.name }}</span>
              <span
                style="
                  float: right;
                  color: #8492a6;
                  font-size: 13px;
                  margin-right: 15px;
                "
                >{{ item.type == "com" ? "默认" : "自定义" }}</span
              >
            </el-option>
          </el-select>
          <li
            class="tool-item"
            slot="reference"
            title="标签筛选"
            :class="{ active: tagIds.length }"
          >
            <div class="tool-icon icon-tag">
              {{ tagIds.map((item) => item.name).join() }}
            </div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="tagIds.length"
              @click="handleTagClear"
            ></i>
          </li>
        </el-popover>
        <!-- 形状加比例筛选 -->
        <el-popover placement="bottom" width="200" trigger="click">
          <div class="checkbox-wrapper bili-wrapper">
            <el-checkbox-group
              v-model="shapeParams.check"
              @change="handleShapeChange"
            >
              <el-checkbox :label="0">竖图</el-checkbox>
              <el-checkbox :label="1">横图</el-checkbox>
              <el-checkbox :label="2">方形</el-checkbox>
              <el-checkbox :label="3">自定义</el-checkbox>
            </el-checkbox-group>
            <el-row
              type="flex"
              align="middle"
              v-if="shapeParams.check.indexOf(3) != -1"
            >
              <el-col :span="10">
                <el-input
                  placeholder="W"
                  v-model.trim="shapeParams.width"
                  @change="handleShapeChange"
                ></el-input>
              </el-col>
              <el-col :span="2">:</el-col>
              <el-col :span="10">
                <el-input
                  placeholder="H"
                  v-model.trim="shapeParams.height"
                  @change="handleShapeChange"
                ></el-input>
              </el-col>
            </el-row>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: searchParams.shape }"
            title="形状筛选"
          >
            <div class="tool-icon icon-shape">
              {{ shapeParams.desc }}
            </div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="searchParams.shape || searchParams.ratio"
              @click="handleClearShape"
            ></i>
          </li>
        </el-popover>
        <!-- 尺寸筛选 -->
        <el-popover placement="bottom" width="200" trigger="click">
          <div class="chicun-wrapper">
            <el-row type="flex" align="middle">
              <el-col :span="3">宽</el-col>
              <el-col :span="10">
                <el-input
                  placeholder="最小"
                  v-model.trim="searchParams.minWidth"
                  @change="handleMeasurementChange"
                ></el-input>
              </el-col>
              <el-col :span="1">-</el-col>
              <el-col :span="10">
                <el-input
                  placeholder="最大"
                  v-model.trim="searchParams.maxWidth"
                  @change="handleMeasurementChange"
                ></el-input>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="3">高</el-col>
              <el-col :span="10">
                <el-input
                  placeholder="最小"
                  v-model.trim="searchParams.minHeight"
                  @change="handleMeasurementChange"
                ></el-input>
              </el-col>
              <el-col :span="1">-</el-col>
              <el-col :span="10">
                <el-input
                  placeholder="最大"
                  v-model.trim="searchParams.maxHeight"
                  @change="handleMeasurementChange"
                ></el-input>
              </el-col>
            </el-row>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: measurementParams }"
            title="尺寸筛选"
          >
            <div class="tool-icon icon-chicun">
              {{ measurementParams }}
            </div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="measurementParams"
              @click="handleClearMeasurement"
            ></i>
          </li>
        </el-popover>
        <!-- 类型筛选 -->
        <el-popover placement="bottom-end" trigger="click">
          <div class="div-scroll" style="height: 300px">
            <div class="type-wrapper">
              <el-checkbox-group
                v-model="formatParams.check"
                @change="handleFormatChange"
              >
                <el-checkbox
                  :label="item.id"
                  v-for="item in formatList"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: formatParams.desc }"
            title="类型筛选"
          >
            <div class="tool-icon icon-type">{{ formatParams.desc }}</div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="formatParams.desc"
              @click="handleClearFormat"
            ></i>
          </li>
        </el-popover>
        <!-- 日期筛选 -->
        <el-popover placement="bottom" width="424" trigger="click">
          <el-date-picker
            v-model="timeParams"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="handleTimeChange"
          >
          </el-date-picker>
          <li
            class="tool-item"
            slot="reference"
            title="时间筛选"
            :class="{ active: timeParams.length }"
          >
            <div class="tool-icon icon-time">{{ timeParams.join() }}</div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="timeParams.length"
              @click="handleClearTime"
            ></i>
          </li>
        </el-popover>
        <!-- 大小筛选 -->
        <el-popover placement="bottom" width="400" trigger="click">
          <div class="bili-wrapper">
            <el-row type="flex" align="middle" :gutter="10">
              <el-col :span="9">
                <el-input
                  placeholder="最小"
                  v-model.trim="searchParams.minSize"
                  @change="handleSizeChange"
                ></el-input>
              </el-col>
              <el-col :span="1">-</el-col>
              <el-col :span="9">
                <el-input
                  placeholder="最大"
                  v-model.trim="searchParams.maxSize"
                  @change="handleSizeChange"
                ></el-input>
              </el-col>
              <el-col :span="5">
                <el-select
                  v-model="sizeParams.unit"
                  placeholder="请选择"
                  @change="handleSizeChange"
                >
                  <el-option label="KB" value="KB"> </el-option>
                  <!-- <el-option label="MB" value="MB"> </el-option> -->
                </el-select>
              </el-col>
            </el-row>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: sizeParams.desc }"
            title="大小筛选"
          >
            <div class="tool-icon icon-size">{{ sizeParams.desc }}</div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="sizeParams.desc"
              @click="handleClearSize"
            ></i>
          </li>
        </el-popover>
        <!-- 注释筛选 -->
        <el-popover placement="bottom" width="200" trigger="click">
          <div class="checkbox-wrapper">
            <el-checkbox
              v-model="commentParams.checked1"
              @change="(checked) => handleCommentChange(checked, 1)"
              >有注释</el-checkbox
            >
            <el-checkbox
              v-model="commentParams.checked2"
              @change="(checked) => handleCommentChange(checked, 2)"
              >没有注释</el-checkbox
            >
            <el-input
              :disabled="commentParams.checked2"
              placeholder="包含关键字(使用,隔开)"
              :rows="3"
              type="textarea"
              v-model.trim="searchParams.commentKey"
              @change="handleCommentChange()"
            ></el-input>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: commentParams.desc }"
            title="注释筛选"
          >
            <div class="tool-icon icon-mark">{{ commentParams.desc }}</div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="commentParams.desc"
              @click="handleClearComment"
            ></i>
          </li>
        </el-popover>
        <!-- 链接筛选 -->
        <el-popover placement="bottom" width="200" trigger="click">
          <div class="checkbox-wrapper">
            <el-checkbox
              v-model="urlParams.checked1"
              @change="(checked) => handlelinkChange(checked, 1)"
              >有链接</el-checkbox
            >
            <el-checkbox
              v-model="urlParams.checked2"
              @change="(checked) => handlelinkChange(checked, 2)"
              >没有链接</el-checkbox
            >
            <el-input
              :disabled="urlParams.checked2"
              placeholder="包含关键字(使用,隔开)"
              :rows="3"
              type="textarea"
              v-model.trim="searchParams.urlKey"
              @change="handlelinkChange()"
            ></el-input>
          </div>
          <li
            class="tool-item"
            slot="reference"
            :class="{ active: urlParams.desc }"
            title="链接筛选"
          >
            <div class="tool-icon icon-link">{{ urlParams.desc }}</div>
            <i class="el-icon-arrow-down"></i>
            <i
              class="el-icon-error"
              v-if="urlParams.desc"
              @click="handleClearLink"
            ></i>
          </li>
        </el-popover>
      </ul>
      <div class="trash-btn-wrapper" v-if="isTrash">
        <div class="opt-item" @click="handleRecover" title="批量恢复">
          <i class="iconfont icon-huifushanchu"></i>
        </div>
        <div class="opt-item" @click="handleDeleteReal" title="彻底删除">
          <i class="iconfont icon-chedishanchu"></i>
        </div>
      </div>
      <el-divider direction="vertical" v-if="isScreenShow"></el-divider>

      <div class="nav-tool-right">
        <el-button
          v-if="isModelUpload && !isBatch"
          style="margin-right: 15px"
          size="small"
          @click="$emit('imageUpload')"
        >
          上传图片
        </el-button>
        <model-upload
          style="margin-right: 15px"
          v-if="isModelUpload && !isBatch"
        ></model-upload>
        <!-- <el-button
          size="small"
          @click="handleBatchDelete"
          v-if="isDelete && !isDeleteWork"
          class="batch-btn"
          style="margin-right: 5px"
          >批量删除</el-button
        >
        <div
          class="opt-item"
          v-if="isDelete && isDeleteWork"
          title="删除"
          @click="handleDeleteImages"
        >
          <i class="iconfont icon-shanchu"></i>
        </div> -->
        <el-button
          size="small"
          @click="$emit('exitBatchManage')"
          v-if="isBatch && !isBack && !isOffshelf && !isModelPage"
          type="primary"
          >完成</el-button
        >
        <el-button
          size="small"
          @click="handleBatchManage"
          v-if="!isBatch && !isOffshelf"
          class="batch-btn"
          >批量管理</el-button
        >

      

        <ul class="operation-container" v-if="isBatch">
          <li class="opt-item" title="复制" v-if="isCopy" @click="handleCopy">
            <i class="iconfont icon-fuzhi"></i>
          </li>
          <li
            class="opt-item"
            @click="handleShare"
            v-if="isShareTo"
            title="共享"
          >
            <i class="iconfont icon-user-shared"></i>
          </li>
          <li
            class="opt-item"
            @click="handleUnshared"
            v-if="isUnshared"
            title="取消共享"
          >
            <i class="iconfont icon-quxiaogongxiang"></i>
          </li>
          <li
            class="opt-item"
            v-if="isDownload"
            title="下载"
            @click="handleDownload"
          >
            <i class="iconfont icon-xiazai"></i>
          </li>
          <li
            class="opt-item"
            v-if="isDelete"
            title="删除"
            @click="handleDeleteImages"
          >
            <i class="iconfont icon-shanchu"></i>
          </li>
        </ul>
        <div class="search-container">
          <el-input
            placeholder="搜索作品"
            prefix-icon="el-icon-search"
            v-model="searchVal"
            clearable
            @clear="searchByWords"
            @input="searchByWords"
          >
          </el-input>
        </div>
      </div>
    </div>
    <div class="nav-user">
      <!-- ‘上传模型列表’菜单对应工具栏按钮 -->
      <model-upload
        style="margin-right: 15px"
        v-if="isModelListPage"
      ></model-upload>
      <el-dropdown trigger="click" @command="handleToggleRole">
        <span class="el-dropdown-link">
          <img class="user-avatar" :src="userInfo.avatar" alt="" />
          <span class="user-name">{{ username }}</span>
          <i class="el-icon-arrow-down"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="personal">个人中心</el-dropdown-item>
          <el-dropdown-item command="manager" v-if="admin"
            >管理中心</el-dropdown-item
          > -->
          <el-dropdown-item command="exit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码弹框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      @close="handleDialogClose"
      :lock-scroll="false"
    >
      <c-form
        v-model="handleData"
        ref="cForm"
        :formRules="formRules"
        :formItems="formItems"
        @submit="submit"
        @resetFields="resetFields"
      >
      </c-form>
    </el-dialog>
  </div>
</template>

<script>
import CForm from "@/components/CForm/index.vue";
import modelUpload from "@/page/home/component/modelUpload";
import Configs from "./config";
import { Chrome } from "vue-color";
import { EventBus } from "@/EventBus";
export default {
  components: { CForm, "chrome-picker": Chrome, modelUpload },
  data() {
    let { formItems, formRules } = Configs(this);
    return {
      colorTimer: null,
      SUCCESS_CODE: 0,
      formItems,
      formRules,
      isModelPage: false, // 是否是模型页面
      isBatch: false, // 是否批量管理
      isActive: true,
      isScreenShow: true, // 头部筛选按钮是否显示(左侧)
      isCopy: true, // 是否显示复制按钮
      isShareTo: true, // 是否显示共享按钮
      isUnshared: false, // 是否显示取消共享按钮
      isDownload: true, // 是否显示下载按钮
      isDelete: true, // 是否显示批量删除按钮
      isDeleteWork: false, // 是否展示删除功能按钮
      isBack: false, // 是否显示返回按钮
      isTrash: false, // 是否是已删除文件菜单
      isModelUpload: true, // 是否展示模型上传按钮
      isOffshelf: false, // 是否是下架页面
      isModelListPage: false, //是否为模型列表页面
      username: "", // 用户名
      admin: false, // 是否管理员
      dialogFormVisible: false,
      handleData: {
        password: "",
        newPassword: "",
        secondPassword: "",
      },
      userInfo: {
        avatar: "",
      },
      searchVal: "", // 搜索关键子
      currentTime: new Date(),
      defaultAvatarUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      searchParams: {
        accuracy: 0.8, // 颜色准确度
        rval: "",
        gval: "",
        bval: "",
        shape: "", // 形状
        ratio: "", // 自定义比例
        maxWidth: "", // 最大宽度
        minWidth: "", // 最小宽度
        maxHeight: "", // 最大高度
        minHeight: "", // 最小高度
        format: "", // 图片类型1是GIF,2是JPG,3是PNG
        start: "", // 开始时间
        end: "", // 结束时间
        maxSize: "", // 图片最大大小
        minSize: "", // 图片最小大小
        hasComment: null, // 是否有注释
        commentKey: "", //注释搜索关键词
        hasUrl: null, // 是否有链接
        urlKey: "", // 链接筛选关键词
        word: "", // 搜索关键词
        comTagIds: [], // 默认标签搜索
        selfTagIds: [], // 自定义标签搜索
      }, //搜索参数
      // isShowColors: false,  // 是否显示颜色选择器
      colorParams: {}, // 颜色参数
      colorParamsTest: {}, // 颜色参数测试
      tagIds: [], // 标签id
      tagOptions: [], // 标签选项
      tagLoading: false,
      accuracy: 0, // 颜色准确度
      shapeParams: {
        desc: "",
        ratio: "",
        check: [],
        width: "",
        height: "",
      }, // 形状参数
      measurementParams: "", // 尺寸参数
      formatList: [],
      formatParams: {
        check: [],
        desc: "",
      }, // 类型参数
      timeParams: [], // 时间参数
      sizeParams: {
        desc: "",
        unit: "KB",
      }, // 大小参数
      commentParams: {
        desc: "",
        checked1: false, // 有注释
        checked2: false, // 没有注释
      }, // 注释参数
      urlParams: {
        desc: "",
        checked1: false, // 有链接
        checked2: false, // 没有链接
      }, // 链接参数
    };
  },
  created() {
    // this.formatList = this.$store.state.dic._FORMAT;
    // 临时调整
    this.formatList = [
      { id: "gif", name: "GIF" },
      { id: "jpeg", name: "JPEG" },
      { id: "png", name: "PNG" },
      { id: "webp", name: "webp" },
      { id: "gltf", name: "gltf" },
      { id: "glb", name: "glb" },
      { id: "stl", name: "stl" },
      { id: "obj", name: "obj" },
      { id: "stp", name: "stp" },
      // { id: "skp", name: "skp" },
      // { id: "step", name: "step" },
      // { id: "jt", name: "jt" },
      { id: "catpart", name: "catpart" },
      { id: "catshape", name: "catshape" },
      { id: "cgr", name: "cgr" },
      { id: "par", name: "par" },
      { id: "psm", name: "psm" },
      { id: "sldasm", name: "sldasm" },
      { id: "sldprt", name: "sldprt" },
      { id: "neu", name: "neu" },
      { id: "prt", name: "prt" },
      { id: "x_b", name: "x_b" },
      { id: "x_t", name: "x_t" },
      { id: "ifc", name: "ifc" },
      { id: "u3d", name: "u3d" },
      { id: "3ds", name: "3ds" },
      { id: "3dxml", name: "3dxml" },
      { id: "skp", name: "skp" },
      { id: "sat", name: "sat" },
      { id: "vda", name: "vda" },
      { id: "rvt", name: "rvt" },
      { id: "rfa", name: "rfa" },
      { id: "ipt", name: "ipt" },
      { id: "prc", name: "prc" },
      { id: "3dm", name: "3dm" },
      { id: "vpb", name: "vpb" },
      { id: "zip", name: "zip" },
    ];
    let nickname = localStorage.getItem("nickname");
    if (nickname) this.username = localStorage.getItem("nickname");
    this.admin = JSON.parse(localStorage.getItem("userInfo")).admin;
    this.userInfo.avatar = JSON.parse(localStorage.getItem("userInfo")).photo;
  },
  mounted() {
    // 切换头部按钮显示
    EventBus.$on("toggleToolBar", (type) => {
      console.log(type);
      // type为1是双击图片，type为2是删除图片文件夹，
      // type为3是共享给我文件夹，type为4是我共享的文件夹
      // type为5是下架作品，type为0是全部作品
      // type为8是我的模型菜单，type为9是收藏的作品，type为10是收藏的模型
      switch (type) {
        case 0:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = true;
          this.isUnshared = false;
          this.isDownload = true;
          this.isDelete = true;
          this.isModelUpload = true;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 1:
          this.isScreenShow = false; // 筛选按钮
          this.isBack = true; // 返回按钮（单张看大图时）
          this.isBatch = true; // 批量管理按钮
          this.isTrash = false; // 是否是删除菜单（展示彻底删除和全部恢复）
          this.isCopy = true; // 复制按钮
          this.isShareTo = true; // 共享按钮
          this.isUnshared = false; // 取消共享按钮
          this.isDownload = true; // 下载按钮
          this.isDelete = false; // 删除按钮
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 2:
          this.isScreenShow = false;
          this.isTrash = true;
          this.isBack = false;
          this.isBatch = false;
          this.isCopy = false;
          this.isShareTo = false;
          this.isUnshared = false;
          this.isDownload = false;
          this.isDelete = false;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 3:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = false;
          this.isUnshared = false;
          this.isDownload = true;
          this.isDelete = false;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 4:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = false;
          this.isUnshared = true;
          this.isDownload = true;
          this.isDelete = true;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 5:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = true;
          this.isTrash = false;
          this.isCopy = false;
          this.isShareTo = false;
          this.isUnshared = false;
          this.isDownload = false;
          this.isDelete = true;
          this.isModelUpload = false;
          this.isOffshelf = true;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 8:
          // 当为我的模型菜单时，头部相关按钮全部不展示
          this.isScreenShow = false;
          this.isBack = false;
          this.isBatch = true;
          this.isTrash = false;
          this.isCopy = false;
          this.isShareTo = false;
          this.isUnshared = false;
          this.isDownload = false;
          this.isDelete = false;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = true;
          this.isModelListPage = true;
          break;
        case 9:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = true;
          this.isUnshared = false;
          this.isDownload = true;
          this.isDelete = true;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        case 10:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = true;
          this.isUnshared = false;
          this.isDownload = true;
          this.isDelete = true;
          this.isModelUpload = false;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
        default:
          this.isScreenShow = true;
          this.isBack = false;
          this.isBatch = false;
          this.isTrash = false;
          this.isCopy = true;
          this.isShareTo = true;
          this.isUnshared = false;
          this.isDownload = true;
          this.isDelete = true;
          this.isModelUpload = true;
          this.isOffshelf = false;
          this.isModelPage = false;
          this.isModelListPage = false;
          break;
      }
      // this.isModelPage = false;
    });
    EventBus.$on("clearParams", (data) => {
      this.resetAllParams();
    });
    EventBus.$on("hiddenToolBar", (data) => {
      this.isModelPage = true;
    });
    EventBus.$on("colorScreen", (color) => {
      // 如果当前在图片详情页，则返回到全部作品页
      if (this.isBack) {
        this.isScreenShow = true;
        this.isBack = false;
        this.isTrash = false;
        this.isCopy = true;
        this.isShareTo = true;
        this.isUnshared = false;
        this.isDownload = true;
        this.isDelete = true;
      }
      this.colorParams = { r: color.rval, g: color.gval, b: color.bval };
      this.searchParams.rval = color.rval;
      this.searchParams.gval = color.gval;
      this.searchParams.bval = color.bval;

      // 取消文件夹选中，设置全部作品选中
      EventBus.$emit("cancelFolderSelected", null);
      EventBus.$emit("setCurrent", 0);
      this.$emit("screenImage", this.searchParams);
    });
    EventBus.$on("cancelBatchManage", (data) => {
      this.isBatch = false;
      this.isDeleteWork = false;
    });
  },
  methods: {
    // 获取实时时间
    getInTime() {
      let self = this;
      setInterval(function () {
        self.currentTime = new Date();
      }, 1000);
    },
    toggle() {
      this.isActive = !this.isActive;
      this.$emit("toggleSideBar", !this.isActive);
    },
    // 切换角色或退出登录
    handleToggleRole(command) {
      console.log(command);
      if (command == "exit") {
        // 退出登录，清空localStorage
        localStorage.clear();
        this.$router.replace("/login");
        // 清除vuex中的数据
        // this.$store.commit("auth/resetState");
      } else if (command == "personal") {
        this.$router.push("/designer/home");
      } else if (command == "manager") {
        this.$router.push("/manager");
      }
    },
    handleDialogClose() {
      this.$refs.cForm.$refs.cForm.resetFields();
      this.dialogFormVisible = false;
    },
    resetFields() {
      this.dialogFormVisible = false;
    },
    // 修改密码
    submit() {
      this.$http
        .post("/user/modifyPassword", this.handleData)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 返回全部作品页面
    handleBack() {
      this.isBack = false;
      EventBus.$emit("toggleBar");
      this.$emit("showAll", true);
    },
    // 重置所有的参数
    resetAllParams() {
      this.searchParams = {
        accuracy: 0.8, // 颜色准确度
        rval: "",
        gbval: "",
        bval: "",
        shape: "", // 形状
        ratio: "", // 自定义比例
        maxWidth: "", // 最大宽度
        minWidth: "", // 最小宽度
        maxHeight: "", // 最大高度
        minHeight: "", // 最小高度
        format: "", // 图片类型1是GIF,2是JPG,3是PNG
        start: "", // 开始时间
        end: "", // 结束时间
        maxSize: "", // 图片最大大小
        minSize: "", // 图片最小大小
        hasComment: null, // 是否有注释
        commentKey: "", //注释搜索关键词
        hasUrl: null, // 是否有链接
        urlKey: "", // 链接筛选关键词
        word: "", // 搜索关键词
        comTagIds: [], // 默认标签搜索
        selfTagIds: [], // 自定义标签搜索
      }; //搜索参数
      this.colorParams = {}; // 颜色参数
      this.tagIds = [];
      this.accuracy = 0; // 颜色准确度
      this.shapeParams = {
        desc: "",
        check: [],
        width: "",
        height: "",
      }; // 形状参数
      this.measurementParams = ""; // 尺寸参数
      this.formatParams = {
        check: [],
        desc: "",
      }; // 类型参数
      this.timeParams = []; // 时间参数
      this.sizeParams = {
        desc: "",
        unit: "KB",
      }; // 大小参数
      this.commentParams = {
        desc: "",
        checked1: false, // 有注释
        checked2: false, // 没有注释
      }; // 注释参数
      this.urlParams = {
        desc: "",
        checked1: false, // 有链接
        checked2: false, // 没有链接
      }; // 链接参数
      this.searchVal = ""; // 清空搜索参数
    },
    // 颜色筛选
    colorValueChange(color) {
      let that = this;
      if (this.colorTimer) clearTimeout(this.colorTimer);
      this.colorTimer = setTimeout(() => {
        let rgba = color.rgba;
        that.colorParams = { r: rgba.r, g: rgba.g, b: rgba.b };
        that.searchParams.rval = rgba.r;
        that.searchParams.gval = rgba.g;
        that.searchParams.bval = rgba.b;
        that.$emit("screenImage", that.searchParams);
      }, 300);
    },
    // 颜色准确度
    handleAccurancyChange(data) {
      this.$emit("screenImage", this.searchParams);
    },
    // 清空颜色参数
    handleClearColor() {
      this.searchParams.accuracy = 0.8;
      this.searchParams.rval = "";
      this.searchParams.gval = "";
      this.searchParams.bval = "";
      this.colorParams = {};
      // console.log(this.searchParams)
      this.$emit("screenImage", this.searchParams);
    },
    // 远程搜做标签列表
    remoteMethodGetTag(query) {
      if (query !== "") {
        this.tagLoading = true;
        let params = { name: query };
        this.$http
          .get("/tag-info/tags", { params })
          .then((res) => {
            if (res.code == this.SUCCESS_CODE) {
              this.tagOptions = res.data;
            } else {
              this.tagOptions = [];
            }
            this.tagLoading = false;
          })
          .catch((err) => {
            this.tagLoading = false;
          });
      } else {
        this.options = [];
      }
    },
    handleTagChange() {
      // console.log(this.tagIds)
      if (this.tagIds.length) {
        let selfTagIds = [];
        let comTagIds = [];
        this.tagIds.forEach((tag) => {
          if (tag.type == "com") {
            comTagIds.push(tag.tagId);
          } else if (tag.type == "self") {
            selfTagIds.push(tag.tagId);
          }
        });
        this.searchParams.selfTagIds = selfTagIds;
        this.searchParams.comTagIds = comTagIds;
      } else {
        this.searchParams.selfTagIds = [];
        this.searchParams.comTagIds = [];
      }
      this.$emit("screenImage", this.searchParams);
    },
    handleTagClear() {
      this.tagIds = [];
      this.searchParams.selfTagIds = [];
      this.searchParams.comTagIds = [];
      this.$emit("screenImage", this.searchParams);
    },
    // 形状改变的方法
    handleShapeChange() {
      let shapArr = ["竖图", "横图", "方形"];
      // console.log(this.shapeParams.check);
      if (this.shapeParams.width && this.shapeParams.height) {
        this.shapeParams.ratio =
          this.shapeParams.width + ":" + this.shapeParams.height;
        this.searchParams.ratio = (
          this.shapeParams.width / this.shapeParams.height
        ).toFixed(2);
      }
      if (this.shapeParams.check.indexOf(3) != -1) {
        let index = this.shapeParams.check.indexOf(3);
        let arr = this.shapeParams.check.slice(0);
        let arr1 = [];
        arr.splice(index, 1);
        arr.forEach((item) => {
          arr1.push(shapArr[item]);
        });
        this.searchParams.shape = arr.join();
        this.shapeParams.desc =
          arr1.join() +
          (this.shapeParams.ratio && this.searchParams.shape ? "," : "") +
          this.shapeParams.ratio;
      } else {
        this.shapeParams.ratio = "";
        this.searchParams.ratio = "";
        this.searchParams.shape = this.shapeParams.check.join();
        let arr1 = [];
        this.shapeParams.check.forEach((item) => {
          arr1.push(shapArr[item]);
        });
        this.shapeParams.desc = arr1.join();
      }
      this.$emit("screenImage", this.searchParams);
    },
    // 清空形状筛选参数
    handleClearShape() {
      this.searchParams.shape = "";
      this.searchParams.ratio = "";
      this.shapeParams.ratio = "";
      this.shapeParams.desc = "";
      this.shapeParams.width = "";
      this.searchParams.height = "";
      this.shapeParams.check = [];
      this.$emit("screenImage", this.searchParams);
    },
    // 尺寸改变的方法
    handleMeasurementChange() {
      var widthParams = "";
      var heightParams = "";
      // 宽
      if (
        this.searchParams.minWidth != "" &&
        this.searchParams.maxWidth == ""
      ) {
        widthParams = `宽≥${this.searchParams.minWidth}`;
      } else if (
        this.searchParams.minWidth == "" &&
        this.searchParams.maxWidth != ""
      ) {
        widthParams = `宽≤${this.searchParams.maxWidth}`;
      } else if (this.searchParams.minWidth && this.searchParams.maxWidth) {
        widthParams = `${this.searchParams.minWidth}≤宽≤${this.searchParams.maxWidth}`;
      }
      // 高
      if (
        this.searchParams.minHeight != "" &&
        this.searchParams.maxHeight == ""
      ) {
        heightParams = `高≥${this.searchParams.minHeight}`;
      } else if (
        this.searchParams.minHeight == "" &&
        this.searchParams.maxHeight != ""
      ) {
        heightParams = `高≤${this.searchParams.maxHeight}`;
      } else if (this.searchParams.minHeight && this.searchParams.maxHeight) {
        heightParams = `${this.searchParams.minHeight}≤高≤${this.searchParams.maxHeight}`;
      }
      this.measurementParams =
        widthParams + (widthParams && heightParams ? "," : "") + heightParams;
      this.$emit("screenImage", this.searchParams);
    },
    // 清除尺寸筛选
    handleClearMeasurement() {
      this.measurementParams = "";
      this.searchParams.minWidth = "";
      this.searchParams.maxWidth = "";
      this.searchParams.minHeight = "";
      this.searchParams.maxHeight = "";
      this.$emit("screenImage", this.searchParams);
    },
    // 类型改变
    handleFormatChange() {
      let arr = [];
      arr = this.formatList.filter((e1) =>
        this.formatParams.check.some((e2) => e2 == e1.id)
      );
      this.formatParams.desc = arr.map((item) => item.name).join();
      this.searchParams.format = this.formatParams.check.join();
      this.$emit("screenImage", this.searchParams);
    },
    // 类型参数清除
    handleClearFormat() {
      this.searchParams.format = "";
      this.formatParams.check = [];
      this.formatParams.desc = "";
      this.$emit("screenImage", this.searchParams);
    },
    // 时间改变
    handleTimeChange() {
      this.searchParams.start = this.timeParams[0];
      this.searchParams.end = this.timeParams[1];
      this.$emit("screenImage", this.searchParams);
    },
    // 清除时间筛选
    handleClearTime() {
      this.timeParams = [];
      this.searchParams.start = "";
      this.searchParams.end = "";
      this.$emit("screenImage", this.searchParams);
    },
    // 大小改变
    handleSizeChange() {
      let size = "";
      if (this.searchParams.minSize != "" && this.searchParams.maxSize == "") {
        size = `大小≥${this.searchParams.minSize}`;
      } else if (
        this.searchParams.minSize == "" &&
        this.searchParams.maxSize != ""
      ) {
        size = `大小≤${this.searchParams.maxSize}`;
      } else if (
        this.searchParams.minSize != "" &&
        this.searchParams.maxSize != ""
      ) {
        size = `${this.searchParams.minSize}≤大小≤${this.searchParams.maxSize}`;
      }
      this.sizeParams.desc = size;
      this.$emit("screenImage", this.searchParams);
    },
    // 清除大小筛选
    handleClearSize() {
      this.searchParams.minSize = "";
      this.searchParams.maxSize = "";
      this.sizeParams = {
        desc: "",
        unit: "KB",
      };
      this.$emit("screenImage", this.searchParams);
    },
    // 注释筛选
    handleCommentChange(data, type) {
      if (type == 1) {
        if (data && this.commentParams.checked2)
          this.commentParams.checked2 = !data;
      } else if (type == 2) {
        if (data && this.commentParams.checked1)
          this.commentParams.checked1 = !data;
      }
      if (!this.commentParams.checked1 && !this.commentParams.checked2) {
        this.searchParams.hasComment = null;
        this.commentParams.desc = "";
      }
      if (this.commentParams.checked1) {
        this.searchParams.hasComment = true;
        if (this.searchParams.commentKey) {
          this.commentParams.desc = this.searchParams.commentKey;
        } else {
          this.commentParams.desc = "有注释";
        }
      }
      if (this.commentParams.checked2) {
        this.searchParams.hasComment = false;
        this.commentParams.desc = "没有注释";
        // this.searchParams.commentKey = "";
      }
      this.$emit("screenImage", this.searchParams);
    },
    // 清除注释
    handleClearComment() {
      this.searchParams.hasComment = null;
      this.searchParams.commentKey = "";
      this.commentParams = {
        desc: "",
        checked1: false,
        checked2: false,
      };
      this.$emit("screenImage", this.searchParams);
    },
    // 链接筛选
    handlelinkChange(data, type) {
      if (type == 1) {
        if (data && this.urlParams.checked2) this.urlParams.checked2 = !data;
      } else if (type == 2) {
        if (data && this.urlParams.checked1) this.urlParams.checked1 = !data;
      }
      if (!this.urlParams.checked1 && !this.urlParams.checked2) {
        this.searchParams.hasUrl = null;
        this.urlParams.desc = "";
      }
      if (this.urlParams.checked1) {
        this.searchParams.hasUrl = true;
        if (this.searchParams.urlKey) {
          this.urlParams.desc = this.searchParams.urlKey;
        } else {
          this.urlParams.desc = "有链接";
        }
      }
      if (this.urlParams.checked2) {
        this.searchParams.hasUrl = false;
        this.urlParams.desc = "没有链接";
        // this.searchParams.urlKey = "";
      }
      this.$emit("screenImage", this.searchParams);
    },
    // 清除链接
    handleClearLink() {
      this.searchParams.hasUrl = null;
      this.searchParams.urlKey = "";
      this.urlParams = {
        desc: "",
        checked1: false,
        checked2: false,
      };
      this.$emit("screenImage", this.searchParams);
    },

    // 共享作品
    handleShare() {
      this.$emit("shareWork", null, 1);
    },
    // 取消共享
    handleUnshared() {
      this.$emit("unsharedResourse");
    },
    // 删除作品
    handleDeleteImages() {
      this.$emit("deleteWork", null);
    },
    // 彻底删除作品
    handleDeleteReal() {
      this.$emit("realDeleteWork", null);
    },
    // 恢复已删除作品
    handleRecover() {
      this.$emit("revocerWork", null);
    },
    // 复制
    handleCopy() {
      this.$emit("CopyResourse");
    },
    // 下载
    handleDownload() {
      this.$emit("downloadResourse");
    },
    // 批量管理
    handleBatchManage() {
      this.isBatch = true;
      this.isDeleteWork = false;
      // ------2022.2.10修改，模型可以和图片同时批量操作------
      this.$emit("batchManage");
      // 删除菜单下可以对模型进行批量删除和批量恢复，其他状态下不可批量操作模型
      // if (this.isTrash) {
      //   this.$emit("batchManage", false);
      // } else {
      //   this.$emit("batchManage");
      // }
    },
    // 批量删除
    handleBatchDelete() {
      this.isDeleteWork = true;
      this.isBatch = false;
      this.$emit("batchManage", false);
    },
    searchByWords(val) {
      this.searchParams.word = val;
      this.$emit("screenImage", this.searchParams);
    },
  },
  beforeDestroy() {
    EventBus.$off("toggleToolBar");
    EventBus.$off("clearParams");
    EventBus.$off("hiddenToolBar");
    EventBus.$off("colorScreen");
    EventBus.$off("cancelBatchManage");
  },
};
</script>
