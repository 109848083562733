<template>
  <div
    class="app-wrapper"
    :class="{ hideSidebar: isCollapse, openSidebar: !isCollapse }"
  >
    <navbar
      @showAll="showAll"
      @shareWork="shareWork"
      @deleteWork="handleDeleteWork"
      @realDeleteWork="handleDeleteReal"
      @revocerWork="handleRecover"
      @screenImage="screenImage"
      @CopyResourse="CopyResourse"
      @unsharedResourse="unsharedResourse"
      @downloadResourse="downloadResourse"
      @batchManage="batchManage"
      @exitBatchManage="handleDrawerClose"
      @imageUpload="handleImageUpload"
    />
    <div class="main-container">
      <sidetree
        v-show="!isCollectionDetail"
        ref="sideTree"
        @showAll="showAll"
        class="sidetree-container"
        @shareWork="shareWork"
        @screenImage="screenImage"
        @clickFolder="clickFolder"
        @getMenuIndex="getMenuIndex"
      ></sidetree>
      <collection-sidebar v-if="isCollectionDetail" />
      <div class="app-main">
        <div
          class="digitial-work-all"
          id="workall"
          @dragover.prevent="handleDragOver"
          v-if="menuIndex != 8"
          v-loading="pageLoading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
        >
          <!-- 全部图片瀑布流 -->
          <div
            v-show="!isEmpty && !isFocus"
            ref="waterfallContainer"
            class="v-waterfall-content"
            :class="{ shrink: isDrawerShow }"
            id="v-waterfall"
            @click="handleDrawerClose"
          >
            <div class="select-all" v-show="showCheckbox">
              <!-- <div style="margin-bottom: 5px">
                <el-button size="small" @click="handleDrawerClose"
                  >退出批量管理</el-button
                >
              </div> -->
              <div @click.stop>
                <el-checkbox v-model="isSelectedAll" @change="handleSelectAll"
                  >全选</el-checkbox
                >
              </div>
            </div>
            <HWaterfall
              ref="waterfallWrapper"
              id="waterfallWrapper"
              :col="maxCols"
              :width="220"
              :gutterWidth="5"
              :data="waterfallList"
              :height="'100%'"
              @loadmore="getData"
              @finish="handleFinish"
              :loadDistance="100"
              :lazyDistance="0"
            >
              <template>
                <div
                  class="cell-item"
                  :class="{
                    'hover-item': imgDataInfo.id != item.id && !showCheckbox,
                    active: imgDataInfo.id == item.id,
                    check: showCheckbox,
                    checked: item.checked,
                  }"
                  v-for="(item, index) in waterfallList"
                  :key="index"
                  @dblclick.stop="handleDblclick(item)"
                  @click.stop="handleImgClick(item)"
                  @mouseenter.stop="handleMouseEnter(item)"
                  @mouseleave.stop="handleMouseLeave(item)"
                >
                  <img
                    v-if="item.obsThumbUrl"
                    :src="item.obsThumbUrl"
                    alt=""
                    class="img"
                    fit="contain"
                    data-key="mainImg"
                    :data-name="item.name"
                    :data-width="item.width && item.width - 4"
                    :data-height="item.height && item.height - 4"
                  />
                  <img
                    v-else-if="item.type == 1"
                    src="@/assets/images/coverdefault.png"
                    alt=""
                    class="img"
                    fit="contain"
                    data-key="mainImg"
                    :data-width="item.width - 4"
                  />
                  <img
                    v-else-if="item.type == 0"
                    src="@/assets/images/image_cover_default.png"
                    alt=""
                    class="img"
                    fit="contain"
                    data-key="mainImg"
                    :data-width="item.width - 4"
                  />
                  <div class="img-error" v-else>
                    <i
                      class="iconfont icon-zanwutupian"
                      style="font-size: 100px"
                    ></i>
                  </div>
                  <div class="operation-container">
                    <div
                      class="opt-btns opt-btns-name"
                      @click="handleOpenUrl(item.url)"
                    >
                      <el-tooltip
                        class="item img_url_hover_qty"
                        effect="dark"
                        :content="item.url"
                        placement="top-start"
                      >
                        <span class="opt-name">{{ item.url }}</span>
                      </el-tooltip>
                    </div>
                    <!-- 菜单是回收站时不展示下载和删除按钮 -->
                    <div class="opt-btns" v-if="menuIndex != 3">
                      <span class="opt-icon">
                        <!-- 上传模型封面 -->
                        <span
                          class="icon-wrapper"
                          v-if="
                            menuIndex != 4 && menuIndex != 6 && item.type == 1
                          "
                          @click.stop="uploadCover(item)"
                        >
                          <!-- <i
                            class="iconfont icon-bg-model"
                            title="上传模型封面"
                          ></i> -->
                          <img
                            src="../../assets/images/icon/icon_models_w.png"
                            alt=""
                            class="icon-models"
                          />
                        </span>
                        <!-- 在共享给我的作品中，不允许下载原图，则不显示下载按钮 -->
                        <span
                          class="icon-wrapper"
                          v-if="
                            menuIndex != 6 &&
                            !(menuIndex == 4 && item.allowDownload === 0)
                          "
                          @click.stop="handleDownloadPic(item)"
                        >
                          <!-- <i class="iconfont icon-xiazai" title="下载"></i> -->

                          <img
                            src="../../assets/images/icon/icon_download.png"
                            alt=""
                            class="icon-download"
                          />
                        </span>
                        <span
                          class="icon-wrapper"
                          v-if="menuIndex != 4 && menuIndex != 6"
                          @click.stop="deleteSingleWork(item)"
                        >
                          <!-- <i class="iconfont icon-shanchu" title="删除"></i> -->
                          <img
                            src="../../assets/images/icon/icon_delete.png"
                            alt=""
                            class="icon-delete"
                          />
                        </span>
                        <!-- 添加/删除su标记 -->
                        <span
                          class="icon-wrapper"
                          v-if="menuIndex != 4 && menuIndex != 6"
                          @click.stop="toggleSuTag(item)"
                        >
                          <img
                            src="../../assets/images/icon/icon_su.png"
                            alt=""
                            class="icon-su"
                          />
                        </span>
                      </span>
                    </div>
                    <!-- <span class="opt-icon more-icon">
                      <span class="icon-wrapper" title="暂无更多">
                        <i class="iconfont icon-gengduo1"></i>
                      </span>
                    </span> -->
                  </div>
                  <!-- 下架理由 -->
                  <div v-if="menuIndex == 6" class="off-comment-wrapper">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="offComment"
                      placement="top-start"
                    >
                      <span class="opt-reason">{{ offComment }}</span>
                    </el-tooltip>
                  </div>
                  <!-- 复选框 v-if="
                      (item.type == 0 && !(item.allowDownload === 0)) ||
                      (item.type == 1 && item.realiboxIsPublish === 1)
                    "-->
                  <div class="check-wrapper" @click.stop v-show="showCheckbox">
                    <el-checkbox
                      v-if="
                        (item.type == 0 && !(item.allowDownload === 0)) ||
                        item.type == 1
                      "
                      v-model="item.checked"
                      @change="(checked) => handleCheckedChange(checked, item)"
                    ></el-checkbox>
                  </div>

                  <!-- 右侧标记 -->
                  <div class="tags">
                    <!-- 模型标记 -->
                    <div class="model-flag" v-if="item.type == 1"></div>
                    <!-- su标记 -->
                    <div class="tag-su" v-if="item.isSuData">su</div>
                  </div>

                  <!-- 图片尺寸 -->
                  <div class="size" v-if="item.type == 0">
                    {{ item.width + "x" + item.height }}
                  </div>
                </div>
              </template>
            </HWaterfall>
            <!-- <div>没有更多啦</div> -->
          </div>

          <Empty v-if="isEmpty" description="没有找到相关作品" />
          <!-- 双击展示单张图片 -->
          <div class="img-wrapper" v-if="isFocus">
            <!-- <div class="back-btn-wrapper" @click="handleBackToList">
              <i class="el-icon-back"></i>
              <span>返回</span>
            </div> -->

            <div
              class="copy-wrapper"
              style="position: absolute; top: 20px; right: 20px; z-index: 9"
            ></div>
            <!-- <div id="maincontent"></div> -->
            <signal-image :imgSrc="bg_img" :imgInfo="signalInfo"></signal-image>
          </div>

          <!-- 回到顶部按钮 -->
          <el-backtop target=".vue-waterfall" :bottom="50" :right="270">
            <div class="top link-item">
              <i class="el-icon-arrow-up"></i>
            </div>
          </el-backtop>

          <!-- 上传弹框 -->
          <imageUpload
            type="drag"
            :show.sync="isUpload"
            @afterUploadImage="afterUploadImage"
          />

          <!-- 右侧详情弹框 -->
          <div class="home-drawer-wrapper">
            <!-- 右侧图片详情框(单选) -->
            <detail-dialog
              :menuIndex="menuIndex"
              :total="totalImage"
              :show="isDrawerShow"
              :infoData="imgDataInfo"
              @screenImage="screenImage"
              @closePreviewMode="isFocus = false"
            ></detail-dialog>
            <!-- 右侧图片详情框(多选) -->
            <multiple-dialog
              :show="isMultiple"
              :infoData="checkedList"
            ></multiple-dialog>
            <!-- 右侧详情弹框(点击文件夹) -->
            <folder-dialog
              :show="isFolder"
              :id="directoryId"
              :menuIndex="menuSelect"
            ></folder-dialog>
          </div>
          <!-- 共享文件弹框 -->
          <el-dialog
            :close-on-click-modal="false"
            :visible.sync="dialogShareVisible"
            :show-close="true"
            custom-class="info-dialog"
            width="40%"
            @close="handleCancelShare"
          >
            <div class="dialog-title" slot="title">共享作品(集)</div>
            <div class="dialog-content">
              <el-form :model="shareForm" ref="shareForm" :rules="shareRules">
                <el-form-item
                  label="已选作品(集)"
                  :label-width="'120px'"
                  prop="works"
                >
                  <el-input
                    disabled
                    v-model="shareForm.works"
                    placeholder="请输入"
                    type="textarea"
                  ></el-input>
                </el-form-item>

                <el-form-item label="授权" :label-width="'120px'">
                  <el-checkbox v-model="shareForm.allow"
                    >是否可以下载原图</el-checkbox
                  >
                </el-form-item>
                <el-form-item label="共享方式" :label-width="'120px'">
                  <el-radio-group v-model="shareForm.type">
                    <el-radio :label="1">指定人员共享</el-radio>
                    <el-radio :label="2">生成共享链接</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="共享给"
                  :label-width="'120px'"
                  prop="names"
                  v-if="shareForm.type == 1"
                >
                  <el-select
                    v-model="shareForm.names"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入关键词"
                    :remote-method="querySearchAsync"
                    :loading="shareLoading"
                  >
                    <el-option
                      v-for="item in userOptions"
                      :key="item.id"
                      :label="item.nickname + '(' + item.username + ')'"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div class="share-link-item" v-if="shareForm.type == 2">
                <el-button plain size="small" @click="handleCreateShreLink"
                  >生成共享链接</el-button
                >
                <div class="link-wrapper" v-if="shareLink">
                  <el-input v-model="shareLink"></el-input>
                  <el-button
                    type="success"
                    class="copy-btn"
                    plain
                    @click="handleCopy($event)"
                    >复制链接</el-button
                  >
                </div>
              </div>
              <div class="footer-box" v-if="shareForm.type == 1">
                <el-button
                  type="primary"
                  @click="handleSubmitShareInfo"
                  class="confirm-btn"
                  >确定</el-button
                >
                <el-button @click="handleCancelShare" class="cancel-btn"
                  >取消</el-button
                >
              </div>
            </div>
          </el-dialog>
          <!-- 复制文件弹框 -->
          <el-dialog
            :close-on-click-modal="false"
            :visible.sync="dialogCopyVisible"
            :show-close="false"
            custom-class="info-dialog"
            width="40%"
          >
            <div class="dialog-title" slot="title">复制作品</div>
            <div class="dialog-content">
              <el-form :model="copyForm" ref="copyForm" :rules="copyRules">
                <el-form-item
                  label="已选作品"
                  :label-width="'120px'"
                  prop="resourse"
                >
                  <el-input
                    disabled
                    v-model="copyForm.resourse"
                    placeholder="请输入"
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="目标作品集"
                  :label-width="'120px'"
                  prop="folders"
                >
                  <el-select
                    v-model="copyForm.folderId"
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in folderData"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                        >{{ item.isPublic == 0 ? "私有" : "公开" }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div class="footer-box">
                <el-button
                  type="primary"
                  @click="handleSubmitCopy"
                  class="confirm-btn"
                  >确定</el-button
                >
                <el-button @click="handleCancelCopy" class="cancel-btn"
                  >取消</el-button
                >
              </div>
            </div>
          </el-dialog>
        </div>
        <!-- 右侧按钮菜单 -->
        <c-menu :right="menuRightData"></c-menu>
        <model-list v-if="menuIndex == 8"></model-list>
        <!-- 模型封面上传弹框 -->
        <el-dialog
          title="上传模型封面"
          append-to-body
          :close-on-click-modal="false"
          :visible.sync="uploadCoverShow"
          :show-close="true"
          custom-class="info-dialog"
          :before-close="beforeUploadCoverClose"
          @close="uploadCoverShow = false"
          width="40%"
        >
          <upload-model-cover
            :modelInfo="modelInfo"
            ref="uploadModelCover"
          ></upload-model-cover>
        </el-dialog>
      </div>
    </div>

    <imageUpload ref="imageUpload" type="modal" @close="() => getData(true)" />
  </div>
</template>

<script>
import { Navbar, Sidetree } from "@/components/Layout";
import detailDialog from "@/components/detailDialog/index";
import multipleDialog from "@/components/multipleDialog/index";
import folderDialog from "@/components/folderDialog/index";
import SelectTree from "@/components/SelectTree/index";
import CMenu from "@/components/CMenu/index";
import imageUpload from "./component/imageUpload.vue";
import { EventBus } from "@/EventBus";
import * as d3 from "d3"; // 引入d3.js
import { downPic, imgToBase64, convertBase64ToBlob } from "@/libs/tools";
import Clipboard from "clipboard";
import {
  new_downloadModel,
  downloadModelV2,
  deleteModel,
  previewModel,
} from "@/util/model";
import ModelList from "./component/modelList.vue";
import signalImage from "./component/signalImage.vue";
import uploadModelCover from "./component/uploadModelCover.vue";
import CollectionSidebar from "./component/collectionSidebar.vue";
import { Loading } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "v-waterfall",
  components: {
    Navbar,
    Sidetree,
    detailDialog,
    multipleDialog,
    folderDialog,
    SelectTree,
    CollectionSidebar,
    CMenu,
    imageUpload,
    ModelList,
    signalImage,
    uploadModelCover,
  },
  data() {
    return {
      // loadMore: true,
      // diff: false,
      isCollapse: false,
      isSelectedAll: false, // 是否选择全部
      showCheckbox: false, // 操作图片时，显示复选框
      SUCCESS_CODE: 0,
      pageLoading: false, // 整个页面的loading
      waterfallList: [],
      isEmpty: false, // 是否无数据
      isSelectAll: false, // 是否全选
      isDrawerShow: true, // 单选后右侧弹框
      isMultiple: false, // 多选之后右侧弹框
      imgDataInfo: {}, // 单击之后的图片详情
      isFolder: false, // 是否是点击了文件夹
      folderInfo: {}, // 文件夹详情
      initCol: 0,
      maxCols: 4,
      isFocus: false, // 是否聚焦
      isUpload: false, // 是否在上传
      dialogVisible: false, // 图片上传成功后弹框控制
      dialogFolderVisible: false, // 图片集(文件夹)上传成功后弹框控制
      dialogSameVisible: false, // 图片去重弹框
      dialogShareVisible: false, // 共享文件弹框
      uploadLoading: false, // 上传时的loading
      imgForm: {
        name: "",
        remark: "",
      }, // 图片名称及描述
      folderForm: {
        name: "",
        remark: "",
      }, // 文件夹名称及描述
      shareForm: {
        works: "",
        names: [],
        allow: false,
        type: 1,
      }, // 共享表单
      scaleNum: 0, // 图片缩放的比例
      checkedList: [], // 勾选图片集合
      // d3初始值
      width: 0,
      height: 0,
      container: null,
      svg_: null,
      zoom_: null,
      bg_img: "",
      offsetX: 0, // X偏移量
      offsetY: 100, // Y偏移量
      sameFile: {
        src: "",
        name: "",
      },
      pageParams: {
        pageSize: 50,
        pageNum: 0,
        total: 0,
        currentPage: 0,
      },
      fileList: [], // 上传文件列表
      directoryId: "", // 文件夹id
      menuSelect: "",
      searchParams: {}, // 列表筛选参数
      shareType: 1, // 分享类型，1是图片，2是文件夹
      shareLoading: false, // 远程搜索loading
      shareFolderId: "", // 分享文件夹id
      userOptions: [], // 共享人员选择列表

      shareRules: {
        works: [
          { required: true, message: "请输入共享的图片名称", trigger: "blur" },
        ],
        names: [
          { required: true, message: "请选择共享的人员", trigger: "change" },
        ],
      },
      dialogCopyVisible: false, // 复制弹框控制
      folderData: [], // 文件夹列表
      treeProps: {
        parent: "parentId", // 父级唯一标识
        value: "id", // 唯一标识
        label: "name", // 标签显示
        children: "child", // 子级
      },
      copyForm: {
        resourse: "",
        folderId: "",
      }, // 复制表单
      copyRules: {
        resourse: [
          { required: true, message: "请选择需要复制的图片", trigger: "blur" },
        ],
        folderId: [
          {
            required: true,
            message: "请选择目标作品集",
            trigger: "change",
          },
        ],
      },
      totalImage: 0, // 总图片数
      menuIndex: 0, // 当前菜单索引
      offComment: "", // 图片下架原因
      shareLink: "",
      canChooseModel: false, //是否可选择模型
      menuRightData: 270,
      isLoad: false,
      signalInfo: {},
      uploadCoverShow: false,
      modelInfo: null,
    };
  },
  provide() {
    return {
      model: this,
    };
  },
  created() {
    // this.getData();
    // 双击图片后跳转详情页面
    // let id = this.$route.query.id;
    // if (id) {
    //   this.getImageInfo(id);
    // }
  },
  mounted() {
    let self = this;
    self.caculateCol();
    window.onresize = function () {
      self.caculateCol();
    };
    // this.copyImage();
  },
  watch: {
    menuRightData: {
      handler(val) {
        console.log(val);
      },
      immediate: true,
      deep: true,
    },
    "$route.query": {
      handler(val) {
        let id = val.id;
        if (id) {
          this.getImageInfo(id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 处于作品详情显示状态
    isCollectionDetail() {
      return this.$route.query.id && this.$route.path === "/home";
    },
    ...mapState("collectionSidebar", ["initCollection"]),
  },
  methods: {
    handleImageUpload() {
      console.log(this.$refs.imageUpload);
      this.$refs.imageUpload.open();
    },

    // 获取单张图片详情
    getImageInfo(id) {
      let params = { isPublic: 0 };
      this.pageLoading = true;
      this.$http
        .get("/resource-info/" + id, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data) {
              let data = res.data.resourceInfo;
              if (data.type == 1) {
                if (data.objectId) {
                  this.$router.push("/model/viewer/" + data.id);
                } else {
                  this.$router.push("/model/view/" + data.id);
                }
              } else {
                this.isFocus = true;
                this.imgDataInfo = res.data.resourceInfo;
                this.signalInfo = res.data;
                this.bg_img = this.imgDataInfo.obsUrl;
                // 当图片是共享给我的图片且不允许下载时，则不出现头部操作按钮
                if (this.imgDataInfo.allowDownload === 0) {
                  EventBus.$emit("toggleToolBar", 8);
                } else {
                  EventBus.$emit("toggleToolBar", 1);
                }
                if (!this.initCollection) {
                  this.$store.dispatch(
                    "collectionSidebar/initCollectionData",
                    res.data.resourceInfo.directoryId
                  );
                }
              }
            } else {
              // this.isFocus = true;
              this.imgDataInfo = {};
              // this.$alert(
              //   "抱歉，您无权查看文件，您要浏览的文件处于私密状态或者已被删除",
              //   "提示",
              //   {
              //     confirmButtonText: "确定",
              //     type: "warning",
              //   }
              // );
            }
          } else {
            this.isFocus = true;
            this.imgDataInfo = {};
            this.$alert(
              "抱歉，您无权查看文件，您要浏览的文件处于私密状态或者已被删除!",
              "提示",
              {
                confirmButtonText: "确定",
                type: "warning",
              }
            );
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.imgDataInfo = {};
          this.pageLoading = false;
        });
    },
    // 展示全部，单张图片返回到列表
    showAll(data) {
      this.isFocus = !data;
      // this.svg_.remove();
      // this.getData(true);
    },
    // 获取当前菜单索引
    getMenuIndex(index) {
      this.menuIndex = index;
      this.directoryId = "";
      if (index == 8) {
        this.menuRightData = 40;
      } else {
        this.menuRightData = 270;
      }
    },
    // 筛选图片
    screenImage(data) {
      console.log("qty", data);
      if (this.isFocus) {
        this.showAll();
      }
      this.showCheckbox = false;
      this.cancelSelect(); // 取消全选
      this.isFocus = false;
      this.checkedList = [];
      this.isSelectedAll = false;
      this.isDrawerShow = true;
      this.isMultiple = false;
      this.isFolder = false;
      this.imgDataInfo = {};
      // this.directoryId = ""; // 点击菜单时清空文件夹id
      this.searchParams = Object.assign({}, data);
      this.searchParams.directoryId = this.directoryId;
      this.searchParams.menuSelect = this.menuIndex;
      //qty 改
      if (this.searchParams.menuSelect === 8) return;
      this.getData(true);
    },
    // 点击文件夹
    clickFolder(data, menuIndex) {
      this.menuIndex = menuIndex;
      this.menuRightData = 270;
      // console.log(data)
      if (this.isFocus) {
        this.showAll();
      }
      this.showCheckbox = false;
      this.cancelSelect(); // 取消全选
      this.isFocus = false;
      this.isDrawerShow = false;
      this.isMultiple = false;
      this.isFolder = true;
      this.imgDataInfo = {};
      this.folderInfo = data;
      EventBus.$emit("toggleToolBar", null);
      // 重新请求数据
      this.directoryId = data.id.toFixed(0);
      this.menuSelect = menuIndex;
      // console.log('clickFolder:', this.directoryId)
      this.$store.commit("setBatchUploadDirecotry", data.id.toFixed(0));
      this.$store.commit("setUploadModelDirecotry", data.id.toFixed(0));
      this.searchParams = { directoryId: data.id, menuSelect: 2 };
      if (this.menuSelect === 4 || this.menuSelect === 5) {
        this.searchParams.menuSelect = this.menuSelect;
      }
      this.getData(true);
    },
    formatTooltip(val) {
      return val + "%";
    },
    caculateCol() {
      let width = this.$refs.waterfallContainer.clientWidth - 170;
      // console.log(width);
      this.initCol = parseInt(width / 225);
      // console.log(this.initCol);
      this.maxCols = this.initCol;
    },
    // 放大
    handleZoomIn() {
      // console.log(this.scaleNum);
      this.scaleNum += 1;
    },
    // 缩小
    handleZoomOut() {
      this.scaleNum -= 1;
    },
    modifyMultiplelFolder() {
      this.isDrawerShow = true;
      this.isMultiple = false;
      this.isFolder = false;
    },
    // 瀑布流元素渲染完成
    handleFinish() {
      console.log("----渲染完成6----");
      // this.loadMore = true;
      // this.isLoad = false;
      this.isToTheEnd();
      this.pageLoading = false;
      // this.loadingInstance.close();
      // const state = this.scrollToTheEnd();
      // if (this.diff && state) {
      //   this.getData();
      // }
    },
    // scrollToTheEnd() {
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   let clientHeight =
    //     document.documentElement.clientHeight || document.body.clientHeight;
    //   let scrollHeight =
    //     document.documentElement.scrollHeight || document.body.scrollHeight;
    //   if (
    //     scrollHeight > clientHeight &&
    //     scrollTop + clientHeight >= scrollHeight - 100
    //   ) {
    //     return true;
    //   }
    //   console.log("qty", false);
    //   return false;
    // },
    //判断元素是否到底部
    isToTheEnd() {
      const rootClientHeight =
        document.getElementById("waterfallWrapper").clientHeight;
      const childClientHeight = document.getElementsByClassName(
        "vue-waterfall-column"
      )[0].clientHeight;
      if (childClientHeight < rootClientHeight) {
        this.getData();
      }
    },
    // myScroll(res) {
    //   if (res.diff <= 0) {
    //     console.log("qty diff 加载");
    //     this.diff = true;
    //     this.getData();
    //   } else {
    //     this.diff = false;
    //   }
    // },
    getData(flag) {
      // console.log("loadmore");
      // if (!this.loadMore) return;
      // this.loadMore = false;
      if (flag) {
        this.waterfallList = [];
        this.pageParams = {
          pageSize: 50,
          pageNum: 0,
          total: 0,
          currentPage: 0,
        };
      }
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        // this.$refs.waterfall.waterfallOver();
        return;
      }
      this.pageLoading = true;
      this.pageParams.pageNum++;
      let params = Object.assign({}, this.pageParams, this.searchParams);
      this.$http
        .post("/resource-info/listPage", params)
        .then((res) => {
          // console.log(res);
          if (res.code == 0) {
            let data = res.data;
            data.records.forEach((item) => {
              item.checked = false;
            });
            this.pageParams.total = data.total;
            this.totalImage = data.total;
            this.pageParams.currentPage = data.current;
            if (data.records.length) {
              this.waterfallList = this.waterfallList.concat(data.records);
            }
            // this.$waterfall.forceUpdate();
            this.isEmpty = this.waterfallList.length ? false : true;

            if (data.records.length === 0) {
              this.pageLoading = false;
            }

            // console.log(this.waterfallList);
          } else {
            this.pageLoading = false;
          }
        })
        .catch((err) => {
          this.waterfallList = [];
          this.isEmpty = true;
          this.pageLoading = false;
        });
    },
    // 图片点击事件
    handleImgClick(data) {
      // 阻止a标签跳转
      // event.preventDefault();
      // console.log(data);
      this.isFolder = false;
      if (!this.isMultiple && !this.showCheckbox) {
        this.isDrawerShow = true;
        this.imgDataInfo = Object.assign({}, data);
      } else {
        if (
          (data.type == 0 && !(data.allowDownload === 0)) ||
          this.canChooseModel
        ) {
          data.checked = !data.checked;
          this.handleCheckedChange(data.checked, data);
        } else {
          return;
        }
      }
    },
    toggleSuTag(item) {
      if (item.isSuData) {
        this.handleRemoveSuTag(item);
      } else {
        this.handleAddSuTag(item);
      }
    },
    //删除su标记
    handleRemoveSuTag(item) {
      this.$http
        .put("/resource-info/updateSuTag/" + item.id + "?isSuData=0")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("清除su标记成功!");
            item.isSuData = 0;
          }
        })
        .catch((err) => {
          this.$message.error("清除su标记失败!");
        });
    },
    //添加su标记
    handleAddSuTag(item) {
      this.$http
        .put("/resource-info/updateSuTag/" + item.id + "?isSuData=1")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("添加su标记成功!");
            item.isSuData = 1;
          }
        })
        .catch((err) => {
          this.$message.error("添加su标记失败!");
        });
    },
    // 关闭弹框
    handleDrawerClose() {
      this.showCheckbox = false;
      this.isDrawerShow = true;
      this.isFolder = false;
      this.isMultiple = false;
      this.cancelSelect();
      // 取消批量操作
      EventBus.$emit("cancelBatchManage");
    },
    // 双击事件
    handleDblclick(item) {
      if (this.showCheckbox) return;
      if (item.type == 0) {
        // this.isFocus = true;
        // EventBus.$emit("toggleToolBar", 1);
        // this.bg_img = this.imgDataInfo.obsUrl;
        // this.bg_img = item.obsUrl;
        // this.$nextTick(() => {
        //   this.hwInit();
        //   this.svgInit();
        //   this.drawImg(this.bg_img);
        //   this.scaleNum = 100;
        // });
        let routeData = this.$router.resolve({
          path: "/home?id=" + item.id,
        });
        window.open(routeData.href, "_blank");
      } else {
        if (item.realiboxIsPublish == 1) {
          // 模型发布后才可以预览
          previewModel(item.id, item.objectId);
        } else {
          this.$message.warning("该模型暂未发布，请发布后预览！");
        }
      }
    },
    // 鼠标拖拽时，显示拖拽上传页面
    handleDragOver() {
      if (this.isFocus) return;
      this.isUpload = true;
    },
    afterUploadImage(data) {
      let self = this;
      this.getData(true);
      // self.isEmpty = false;
      // self.waterfallList.unshift(data);
      // self.totalImage++;
    },
    // 批量操作
    batchManage(flag = true) {
      // flag为true为非删除的批量操作，为false时为删除的批量操作
      this.showCheckbox = true;
      this.imgDataInfo = {};
      this.cancelSelect();
      this.canChooseModel = true;
      // if (flag) {
      //   this.canChooseModel = false;
      // } else {
      //   this.canChooseModel = true;
      // }
    },
    // 全选
    handleSelectAll(checked) {
      // 全选
      if (checked) {
        if (this.canChooseModel) {
          // 删除状态下全选应包括模型
          this.waterfallList.forEach((item) => {
            item.checked = true;
          });
          this.checkedList = [...this.waterfallList];
        } else {
          // 非删除状态下全选应不包括模型
          this.checkedList = this.waterfallList.filter((item) => {
            if (item.type == 0 && !(item.allowDownload === 0))
              item.checked = true;
            return item.type == 0 && !(item.allowDownload === 0);
          });
        }
        this.isDrawerShow = false;
        this.isMultiple = true;
        this.isFolder = false;
      } else {
        // 全不选
        this.waterfallList.forEach((item) => {
          item.checked = false;
        });
        this.checkedList = [];
        this.handleDrawerClose();
      }
    },
    // 图片复选框,实现图片的勾选和取消勾选
    handleCheckedChange(checked, data) {
      if (checked) {
        this.checkedList.push(data);
      } else {
        this.checkedList.forEach((item, index) => {
          if (item.id == data.id) {
            this.checkedList.splice(index, 1);
          }
        });
      }
      this.isDrawerShow = false;
      this.isFolder = false;
      this.isMultiple = true;
    },
    // 取消图片选择
    cancelSelect() {
      this.waterfallList.forEach((item) => {
        item.checked = false;
      });
      this.checkedList = [];
      this.isSelectedAll = false;
    },
    // 获取界面可用区域宽高
    hwInit() {
      this.width = window.innerWidth - 493;
      this.height = window.innerHeight - 92;
      //获取偏移量
      this.offsetX =
        this.width - this.imgDataInfo.width > 0
          ? (this.width - this.imgDataInfo.width) / 2.0
          : (this.width - this.width / 2) / 2.0;
      this.offsetY =
        this.height - this.imgDataInfo.height > 0
          ? (this.height - this.imgDataInfo.height) / 2.0
          : 50;
    },
    //初始化svg的面板
    svgInit: function () {
      this.zoom_ = d3.zoom().scaleExtent([0.01, 8]).on("zoom", this.zoomed);
      this.svg_ = d3
        .select("#maincontent")
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height)
        .call(this.zoom_);
    },
    zoomed: function (e) {
      this.scaleNum = parseInt(e.transform.k * 100);
      this.container.attr(
        "transform",
        "translate(" +
          e.transform.x +
          "," +
          e.transform.y +
          ")scale(" +
          e.transform.k +
          ")"
      );
      // }
    },
    //根据svg绘制底图
    drawImg: function (bg_img) {
      this.container = this.svg_.append("g").on("click", function () {
        // console.log(d3.mouse(this));
        console.log(d3.pointer);
      });
      let width =
        this.imgDataInfo.width > this.width - 100
          ? this.width / 2
          : this.imgDataInfo.width;
      let height =
        this.imgDataInfo.height > this.height
          ? this.height
          : this.imgDataInfo.height;
      this.container
        .attr("class", "all")
        .append("image")
        .attr("xlink:href", bg_img)
        .attr("x", this.offsetX)
        .attr("y", this.offsetY)
        .attr("width", width)
        // .attr("height", height)
        .style("cursor", "zoom-in");
    },
    // 滑块改变图片缩放
    handleScaleChange(data) {
      // console.log(data);
      if (data) {
        let scale = data / 100;
        let attr = d3.select(".all").attr("transform");
        if (attr) {
          let translate = attr.split("scale")[0];
          this.container.attr("transform", translate + "scale(" + scale + ")");
        } else {
          this.container.attr(
            "transform",
            "translate(" + 0 + "," + 0 + ")scale(" + scale + ")"
          );
        }
      }
    },
    // 远程搜索共享人
    querySearchAsync(queryString) {
      // console.log(queryString);
      this.shareLoading = true;
      let params = { search: queryString };
      this.$http
        .get("/sys-user/searchListPage", { params })
        .then((res) => {
          this.shareLoading = false;
          if (res.code == this.SUCCESS_CODE) {
            this.userOptions = res.data;
          }
        })
        .catch((err) => {
          this.shareLoading = false;
        });
    },
    // 共享文件
    shareImage() {
      let rIds = this.checkedList.map((item) => item.id).join();
      let uIds = this.shareForm.names.join();
      let params = {
        rIds,
        uIds,
        allow: this.shareForm.allow,
      };
      this.$http
        .post("/share-info/share", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$refs.shareForm.resetFields(); // 重置表单
            this.dialogShareVisible = false; // 关闭弹框
            this.cancelSelect();
            this.$message.success("共享成功！");
          }
        })
        .catch((err) => {
          this.$message.error("共享失败！");
        });
    },
    // 共享文件夹
    shareFolder() {
      let uIds = this.shareForm.names.join();
      let params = {
        uIds,
        allow: this.shareForm.allow,
      };
      this.$http
        .post("/share-info/shareDirectory/" + this.shareFolderId, null, {
          params,
        })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("共享成功！");
            this.dialogShareVisible = false; // 关闭弹框
          }
        })
        .catch((err) => {
          this.$message.error("共享失败！");
        });
    },
    // 上传共享信息
    handleSubmitShareInfo() {
      this.$refs.shareForm.validate((valid) => {
        if (valid) {
          if (this.shareType == 1) {
            this.shareImage();
          } else {
            this.shareFolder();
          }
        } else {
          return false;
        }
      });
    },
    // 取消共享(表单cancel)
    handleCancelShare() {
      this.$refs.shareForm.resetFields(); // 重置表单
      this.shareForm = {
        works: "",
        names: [],
        allow: false,
        type: 1,
      };
      this.dialogShareVisible = false; // 关闭弹框
      this.shareLink = ""; // 清空分享链接
    },
    // 生成共享链接
    handleCreateShreLink() {
      let params = {};
      if (this.shareType == 1) {
        let resourceIds = this.checkedList.map((item) => item.id);
        params = {
          allow: this.shareForm.allow,
          resourceIds,
        };
      } else {
        params = {
          allow: this.shareForm.allow,
          dirId: this.shareFolderId,
        };
      }

      this.$http.post("/share-info/getShareCode", params).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          console.log(res);
          let origin = window.location.origin + "/#/share";
          this.shareLink = origin + "?shareCode=" + res.data;
        }
      });
    },
    // 分享图片
    shareWork(data, type) {
      // console.log(data)
      this.shareType = type;
      // type为1是共享图片，为2是共享文件夹
      if (type == 1) {
        // 判断是否是双击后单张图片显示
        if (this.isFocus) {
          this.checkedList[0] = this.imgDataInfo;
        }
        console.log(this.checkedList);
        if (this.checkedList.length) {
          this.dialogShareVisible = true;
          // console.log(this.checkedList);
          this.shareForm.works = this.checkedList
            .map((item) => item.name)
            .join();
        } else {
          this.$message.warning("请选择要共享的图片！");
        }
      } else {
        this.dialogShareVisible = true;
        this.shareForm.works = data.name;
        this.shareFolderId = data.id;
      }
    },
    // 取消共享(接口调用)
    unsharedResourse() {
      if (this.checkedList.length) {
        this.$confirm("此操作将取消分享该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          let rIds = this.checkedList.map((item) => item.id).join();
          this.$http
            .post("/share-info/unshared", null, { params: { rIds } })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.checkedList = [];
                this.isSelectedAll = false;
                this.$message.success("取消共享成功!");
                this.getData(true);
              }
            })
            .catch((err) => {
              this.$message.error("取消共享失败!");
            });
        });
      } else {
        this.$message.warning("请选择要取消共享的图片！");
      }
    },
    // 删除图片
    handleDeleteWork() {
      if (this.checkedList.length) {
        this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          let ids = this.checkedList.map((item) => item.id).join();
          this.$http
            .delete("/resource-info/deleteBatch", { params: { ids } })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.checkedList = [];
                this.isSelectedAll = false;
                this.$message.success("删除成功!");
                this.getData(true);
              }
            })
            .catch((err) => {
              this.$message.error("删除失败!");
            });
        });
      } else {
        this.$message.warning("请选择要删除的数据！");
      }
    },
    // 删除单张图片
    deleteSingleWork(item) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let ids = item.id;
        this.$http
          .delete("/resource-info/deleteBatch", { params: { ids } })
          .then((res) => {
            if (res.code == this.SUCCESS_CODE) {
              this.$message.success("删除成功!");
              this.getData(true);
            }
          })
          .catch((err) => {
            this.$message.error("删除失败!");
          });
      });
    },
    // 彻底删除数据
    handleDeleteReal() {
      if (this.checkedList.length) {
        this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          let ids = this.checkedList.map((item) => item.id).join();
          this.$http
            .delete("/resource-info/deleteReal", { params: { ids } })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.checkedList = [];
                this.isSelectedAll = false;
                this.$message.success("删除成功!");
                this.getData(true);
              }
            })
            .catch((err) => {
              this.$message.error("删除失败!");
            });
        });
      } else {
        this.$message.warning("请选择要删除的数据！");
      }
    },
    // 恢复图片
    handleRecover() {
      if (this.checkedList.length) {
        this.$confirm("此操作将恢复该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          let ids = this.checkedList.map((item) => item.id).join();
          this.$http
            .post("/resource-info/recover", null, { params: { ids } })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.checkedList = [];
                this.isSelectedAll = false;
                this.$message.success("恢复成功");
                this.getData(true);
              }
            })
            .catch((err) => {
              this.$message.error("恢复失败！");
            });
        });
      } else {
        this.$message.warning("请选择要恢复的数据！");
      }
    },
    CopyResourse(data) {
      if (this.isFocus) {
        this.checkedList[0] = this.imgDataInfo;
      }
      if (this.checkedList.length) {
        this.getFolderData(); // 获取文件夹数据
        this.dialogCopyVisible = true;
        this.copyForm.resourse = this.checkedList
          .map((item) => item.name)
          .join();
      } else {
        this.$message.warning("请选择要复制的图片！");
      }
    },
    // 获取文件夹列表
    getFolderData() {
      this.$http
        .get("/directory-info/nodes")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.folderData = res.data;
          }
        })
        .catch((err) => {
          this.folderData = [];
        });
    },
    // 复制图片
    handleSubmitCopy() {
      this.$refs.copyForm.validate((valid) => {
        if (valid) {
          let id = this.copyForm.folderId;
          let params = { ids: this.checkedList.map((item) => item.id).join() };
          this.$http
            .post("/resource-info/copy/" + id, null, { params })
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                // 重置表单数据
                this.copyForm = {
                  resourse: "",
                  folderId: "",
                };
                this.$refs.copyForm.resetFields(); // 重置表单
                this.dialogCopyVisible = false;
                this.checkedList = [];
                this.isSelectedAll = false;
                this.getData(true);
                this.$message.success("复制成功！");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error("复制失败！");
            });
        } else {
          return false;
        }
      });
    },
    // 取消复制
    handleCancelCopy() {
      this.$refs.copyForm.resetFields(); // 重置表单
      this.dialogCopyVisible = false; // 关闭弹框
    },
    // 保存下载记录
    saveDownloadRecords() {
      this.pageLoading = true;
      let ids = this.checkedList.map((item) => item.id).join();
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.checkedList.forEach((item) => {
              if (item.type == 0) {
                downPic(item.obsUrl, item.name);
              } else if (item.type == 1) {
                if (item.objectId) {
                  downloadModelV2(item.objectId, item.name);
                } else {
                  new_downloadModel(item.realiboxSceneId, item.name);
                }
              }
            });
            if (!this.isFocus) {
              this.cancelSelect();
              this.handleDrawerClose();
            }
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.pageLoading = false;
        });
    },
    // 单张图片下载
    handleDownloadPic(item) {
      this.pageLoading = true;
      let ids = item.id;
      console.log(item);
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (item.type == 0) {
              downPic(item.obsUrl, item.name, item.format);
            } else if (item.type == 1) {
              if (item.objectId) {
                downloadModelV2(item.objectId, item.name);
              } else {
                new_downloadModel(item.realiboxSceneId, item.name);
              }
            }
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.pageLoading = false;
        });
    },
    // 批量下载资源
    downloadResourse() {
      if (this.isFocus) {
        this.checkedList[0] = this.imgDataInfo;
      }
      if (this.checkedList.length) {
        let text =
          "此操作将下载" + this.checkedList.length + "张作品, 是否继续?";
        this.$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }).then(() => {
          this.saveDownloadRecords();
        });
      } else {
        this.$message.warning("请选择需要下载的作品！");
      }
    },
    // 由双击详情页返回到列表页面
    handleBackToList() {
      EventBus.$emit("toggleBar");
      this.showAll(true);
    },
    // 鼠标进入图片得事件
    handleMouseEnter(data) {
      if (this.menuIndex == 6) {
        this.queryOffShelfReason(data.id);
      } else {
        return;
      }
    },
    handleMouseLeave() {
      this.offComment = "";
    },
    // 查询下架原因
    queryOffShelfReason(id) {
      let params = { rid: id };
      this.$http.get("/off-shelf-log/load", { params }).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.offComment = res.data.comment || "";
        }
      });
    },
    handleCopy(e) {
      let linkUrl = this.shareLink;
      let clipboard = new Clipboard(e.target, { text: () => linkUrl });
      clipboard.on("success", () => {
        this.$message.success("已复制分享链接");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log("复制失败", e);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    getBase64Data() {
      // this.$http
      //   .get("/resource-info/getBase64Url?url=" + this.bg_img)
      //   .then((res) => {
      //     if (res.code == this.SUCCESS_CODE) {
      //       console.log(res.data)
      //       this.handleCopyToClip(res.data);
      //     }
      //   });
      imgToBase64(this.bg_img, this.handleCopyToClip);
    },
    handleCopyToClip(base64Data) {
      try {
        //  const base64Data = 'aHR0cHM6Ly9sbWFuLXRlc3Qub2JzLmNuLWVhc3QtMy5teWh1YXdlaWNsb3VkLmNvbS9kMDMwODIwMi1hNmIxLTQ3NjAtYjkyYi1kYTYzY2EyZTEzOWFwZXhlbHMtdmFuZXNzYS1sb3JpbmctNTk2ODIzMS5qcGc='
        if (document.body.createControlRange) {
          //IE 11 需要有个img标签
          var controlRange;
          var imgs = document.getElementById("hidImag");
          imgs.onload = function () {
            controlRange = document.body.createControlRange();
            imgs.contentEditable = "true";
            controlRange.addElement(imgs);
            try {
              var successful = controlRange.execCommand("copy");
              var msg = successful ? "successful" : "unsuccessful";
              console.log("Copying text command was " + msg);
            } catch (err) {
              console.log(err);
            }
          };
          imgs.src = "data:image/png;base64," + base64Data;
        } else {
          //chrome
          const blobInput = convertBase64ToBlob(base64Data, "image/png");
          const clipboardItemInput = new ClipboardItem({
            "image/png": blobInput,
          });
          navigator.clipboard.write([clipboardItemInput]);
          console.log("success");
        }
      } catch (e) {
        console.log(e);
      }
    },
    copyImage() {
      console.log("-----------copyimage");
      let image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = this.bg_img;
      console.log(this.bg_img);
      // image.src =
      //   "https://lman-test.obs.cn-east-3.myhuaweicloud.com/99e909f323054e14a5b18fd503892540.png";
      image.onload = async function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.getContext("2d").drawImage(image, 0, 0);
        const data = await fetch(canvas.toDataURL("image/png"));
        const blob = await data.blob();
        console.log(blob);
        await navigator.clipboard.write([
          // eslint-disable-next-line no-undef
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
        console.log("Fetched image copied.");
      };
    },
    beforeUploadCoverClose(done) {
      this.$refs.uploadModelCover.beforeClose();
      done();
    },
    // 上传模型封面
    uploadCover(item) {
      this.uploadCoverShow = true;
      this.modelInfo = Object.assign({}, item);
    },
    //点击链接跳转
    handleOpenUrl(url) {
      url && window.open(url);
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
/deep/ .navbar .user-name {
  max-width: 80px;
}
.img_url_hover_qty {
  cursor: pointer;
}
</style>
