<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <div class="sidebar-logo-link">
        <div class="sidebar-title">
          <!-- <img
            src="../../../assets/images/sidebar_logo.png"
            class="sidebar-logo"
          /> -->
          <!-- <h1 v-show="!collapse" class="sidebar-title-txt">{{ title }}</h1> -->
        </div>

        <!-- <div class="sidebar-user" v-if="!collapse">
          <div class="avatar">
            <img
              src="https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png"
              alt=""
            />
          </div>
          <p>{{username}}</p>
        </div> -->
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    collapse(newval) {
      // console.log(newval)
    },
  },
  created() {
    let data = localStorage.getItem("userInfo");
    let userInfo = JSON.parse(data);
    this.username = userInfo.name;
  },
  data() {
    return {
      title: "迎新管理",
      username: "",
    };
  },
  methods: {
    handleToggleRole(command) {
      // EventBus.$emit("toggleRole", command);
      // sessionStorage.setItem("role", command);
      switch (command) {
        case "a":
          // this.handleLogout();
          localStorage.clear();
          this.$router.replace("/login");
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 0.38s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  .sidebar-logo-link {
    height: 100%;
    width: 100%;
    padding: 12px 15px 0;
    .sidebar-title {
      overflow: hidden;
      padding-left: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #58a07e;
      .sidebar-logo {
        height: 39px;
      }
    }
  }
}
</style>
