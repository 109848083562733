<template>
    <div>
        <!-- 拖拽上传 -->
        <div v-if="type === 'drag'">
            <div
                class="upload-wrapper"
                v-if="show && !visible"
                id="upload"
                @dragleave.stop="$emit('update:show', false)"
                @drop.stop="$emit('update:show', false)"
                v-loading="uploadLoading"
            >
                <el-upload
                    class="upload-box"
                    drag
                    :headers="uploadHeaders"
                    :multiple="true"
                    action=""
                    :on-error="handleError"
                    :before-upload="beforeUpload"
                    :http-request="customUpload"
                    :file-list="fileList"
                    :disabled="uploadLoading"
                    accept=".jpg,.jpeg,.png,.bmp,.gif"
                >
                    <div class="upload-btn-wrapper">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将作品拖到此处上传</div>
                    </div>
                </el-upload>
            </div>
        </div>

        <!-- 避免拖拽逻辑混乱 -->
        <div v-else-if="type === 'modal'">
            <!-- 按钮上传弹窗 -->
            <el-dialog :visible.sync="visible" @close="close">
                <div id="upload" class="upload-modal-wrapper" v-loading="uploadLoading">
                    <el-upload
                        ref="upload"
                        class="upload-box"
                        drag
                        :headers="uploadHeaders"
                        :show-file-list="false"
                        :multiple="true"
                        action=""
                        :on-error="handleError"
                        :before-upload="beforeUpload"
                        :http-request="customUpload"
                        :disabled="uploadLoading"
                        accept=".jpg,.jpeg,.png,.bmp,.gif"
                    >
                        <div class="upload-btn-wrapper">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">点击上传 或 将作品拖到此处上传</div>
                        </div>
                    </el-upload>
                </div>
            </el-dialog>
        </div>

        <!-- 图片去重弹框 -->
        <el-dialog :close-on-click-modal="false" :visible.sync="dialogSameVisible" :show-close="false" custom-class="compare-dialog" width="60%">
            <div class="compare-container">
                <div class="compare-content">
                    <div class="content-item content-left">
                        <div class="title">已存在作品</div>
                        <div class="compare-img">
                            <img :src="sameFileOrigin.src" alt="" />
                        </div>
                        <div class="attr">
                            <p>{{ sameFileOrigin.name }}</p>
                        </div>
                    </div>
                    <div class="content-item content-right">
                        <div class="title">上传作品</div>
                        <div class="compare-img">
                            <img :src="sameFile.src" alt="" />
                        </div>
                        <div class="attr">
                            <p>{{ sameFile.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="compare-footer">
                    <el-button type="primary" @click="saveResourceInfoAfterCrc">导入作品</el-button>
                    <el-button @click="cancelUpload">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Message } from "element-ui";
import { storageLocal, blobToDataURL, dataURLtoFile } from "../../../util/storage.js";
export default {
    inject: ["model"],
    props: {
        type: {
            type: String,
            default: "drag"
        },

        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,

            uploadHeaders: {
                token: localStorage.getItem("token")
            },
            fileList: [],
            dialogSameVisible: false,
            sameFile: {
                src: "",
                name: ""
            },
            sameFileOrigin: {
                src: "",
                name: ""
            },
            uploadQueQueList: [],
            timer: null,
            hasUpload: false
        };
    },
    computed: {
        uploadLoading() {
            return this.$store.state.isBatchUploading;
        }
    },
    methods: {
        open() {
            this.hasUpload = false;
            this.visible = true;
        },

        close() {
            if (this.hasUpload) {
                this.$emit("close");
            }
        },
        beforeUpload(file) {
            let types = ["image/jpeg", "image/jpg", "image/gif", "image/bmp", "image/png"];
            const isImage = types.includes(file.type);
            if (!isImage) {
                this.$message.warning("上传图片只能是 JPG、JPEG、gif、bmp、PNG 格式!");
                return false;
            }
            if (file.size > 1048576 * 500) {
                //图片不能超过500M
                this.$message.warning(file.name + "超过500M，取消上传该图片");
                return false;
            }
            return true;
        },
        handleError(err, file, fileList) {
            console.log(err);
            this.$message.warning(err);
        },
        //判断图片类型
        isAssetTypeAnImage(ext) {
            return ["png", "jpg", "jpeg", "bmp"].indexOf(ext.toLowerCase()) !== -1;
        },
        // 自定义上传方法
        customUpload(e) {
            let self = this;
            if (!self.$store.state.isBatchUploading) {
                self.$store.commit("setBatchUploadDirecotry", self.model.directoryId);
            }
            self.$store.commit("batchUpload");
            // self.uploadByQueue(e.file);
            self.uploadQueQueList.push(e.file);
            clearTimeout(self.timer);
            self.timer = setTimeout(() => {
                self.uploadQueQueList.reverse().forEach(file => {
                    self.uploadByQueue(file);
                });
                self.uploadQueQueList = [];
            }, 500);
        },
        /**
         * 排队上传文件，每次上传8张
         * 这里有性能问题
         */
        async uploadByQueue(file) {
            let self = this;
            if (self.$store.state.batchUploadCount < self.$store.state.batchUploadLimit) {
                self.$store.commit("startUploadImage");
                var key = uuidv4() + self.cleanFileName(file) + ".png";
                if (file.size < 10 * 1024 * 1024 && this.isAssetTypeAnImage(file.type.split("/")[1])) {
                    self.getBase64AndSize(file)
                        .then(async res => {
                            let min = Math.min(res.width, res.height);
                            let max = Math.max(res.width, res.height);
                            // console.log(res.width, res.height, self.calcBase64Size(res.base64)/1024/1024);
                            //图像增强要求：base64编码后大小不超过10M(参考：原图大约为8M以内），最短边至少10px，最长边最大5000px，长宽比4：1以内
                            if (min >= 10 && max <= 5000 && max / min <= 4 && self.calcBase64Size(res.base64) < 10 * 1024 * 1024) {
                                let access_token = storageLocal.get("access_token");
                                if (!access_token) {
                                    access_token = await this.getAccessToken();
                                }
                                if (!access_token) {
                                    self.obsUpload(file, key, self.saveFile);
                                    return;
                                }
                                const image = await self.sharpnessEnhancement(access_token, res.base64, file.type);
                                if (image) {
                                    const newFile = dataURLtoFile(`data:${file.type};base64,` + image, file.name);
                                    self.obsUpload(newFile, key, self.saveFile);
                                } else {
                                    self.obsUpload(file, key, self.saveFile);
                                }
                            } else {
                                console.log("图片尺寸不合规，跳过图像增强");
                                self.obsUpload(file, key, self.saveFile);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            self.obsUpload(file, key, self.saveFile);
                        });
                    return;
                } else {
                    self.obsUpload(file, key, self.saveFile);
                }

                // 当图片大于25MB时，上传图片缩略图
                // if (file.size > 26214400) {
                //     var reader = new FileReader();
                //     reader.readAsDataURL(file);
                //     reader.onload = function (event) {
                //         console.log("----base64----" + reader.result);
                //         self.dealImage(this.result, { width: 210 }, function (base) {
                //             var filet = self.dataURLtoFile(base, file.name);
                //             var thumbKey = "thumb-" + key;
                //             self.obsUpload(filet, thumbKey, null);
                //         });
                //     };
                // }
            } else {
                setTimeout(() => {
                    self.uploadByQueue(file);
                }, 2500);
            }
        },
        //获取图片base64和尺寸大小
        getBase64AndSize(file) {
            return new Promise(function (resolve, reject) {
                try {
                    blobToDataURL(file, base64 => {
                        let img = new Image();
                        img.src = base64;
                        img.onload = function () {
                            resolve({ width: this.width, height: this.height, base64: base64 });
                        };
                    });
                } catch (e) {
                    reject();
                }
            });
        },
        //计算base64图片大小
        calcBase64Size(base64String) {
            // x = (n * (3/4)) - y
            // 1. x is the size of a file in bytes
            // 2. n is the length of the Base64 String
            // 3. y will be 2 if Base64 ends with '==' and 1 if Base64 ends with '='.
            // Remove MIME-type from the base64 if exists
            let commaIndex = base64String.indexOf(",");
            if (commaIndex > -1) {
                base64String = base64String.substr(commaIndex);
            }
            let fileSizeInByte = Math.ceil(base64String.length / 4) * 3;
            if (base64String.endsWith("==")) {
                return fileSizeInByte - 2;
            } else if (base64String.endsWith("=")) {
                return fileSizeInByte - 1;
            } else {
                return fileSizeInByte;
            }
        },
        //获取百度智能云Access Token
        async getAccessToken() {
            let secretKey = "G4Q0UROf5W1uQnT7LGXnkaD9t2920vmr";
            let appKey = "XSicipXGSEtzRU66BdNyBWIm";
            const res = await axios.post(`/oauth/2.0/token?grant_type=client_credentials&client_id=${appKey}&client_secret=${secretKey}`);
            let accessToken;
            if (res.data) {
                accessToken = storageLocal.set("access_token", res.data.access_token, res.data.expires_in * 1000);
            }
            return accessToken;
        },
        //清晰度增强
        async sharpnessEnhancement(token, image, type) {
            image = image.replace(`data:${type};base64,`, "");
            const formData = new FormData();
            formData.append("image", image);
            try {
                const res = await axios.post(
                    "https://aip.baidubce.com/rest/2.0/image-process/v1/image_definition_enhance?access_token=" + token,
                    formData,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                );
                if (res.data.image) {
                    return res.data.image;
                } else {
                    return false;
                }
            } catch (err) {
                // console.log(err)
                return false;
            }
        },
        obsUpload(file, key, callback) {
            let self = this;
            var ak = self.$OBS_AK;
            var sk = self.$OBS_SK;
            var server = self.$OBS_SERVER;
            var bucket = self.$OBS_BUCKET;
            var obsClient = new ObsClient({
                access_key_id: ak,
                secret_access_key: sk,
                server: server,
                timeout: 60 * 5
            });
            // put objects
            obsClient
                .putObject({
                    Bucket: bucket,
                    Key: key,
                    Metadata: { property: "property-value" },
                    SourceFile: file
                })
                .then(function (result) {
                    if (result.CommonMsg.Status == 200) {
                        // 上传成功后，关闭上传界面，打开修改名称弹框
                        callback && callback(file, key);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    self.$store.commit("closeUploadImage");
                    self.$message.error(err);
                });
        },
        getHash(file, key, cb) {
            let reader = new FileReader();
            // 读取文件 file
            reader.readAsArrayBuffer(file);

            reader.onload = function () {
                console.log("----buffer----" + reader.result);
                var wordArray = CryptoJS.lib.WordArray.create(reader.result);
                var hash = CryptoJS.SHA1(wordArray).toString(); // 计算其他加密算法，SHA1改为MD5、SHA256即可
                console.log("hash:", hash);
                cb && cb(file, key, hash);
            };
        },
        /** 图片压缩 返回base64 */
        dealImage(path, obj, callback) {
            console.log(path);
            var img = new Image();
            img.src = path;
            img.onload = function () {
                var that = this;
                // 默认按比例压缩
                var w = that.width,
                    h = that.height,
                    scale = w / h;
                w = obj.width || w;
                h = obj.height || w / scale;
                var quality = 0.75; // 默认图片质量为0.9
                //生成canvas
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                // 创建属性节点
                var anw = document.createAttribute("width");
                anw.nodeValue = w;
                var anh = document.createAttribute("height");
                anh.nodeValue = h;
                canvas.setAttributeNode(anw);
                canvas.setAttributeNode(anh);
                ctx.drawImage(that, 0, 0, w, h);
                // 图像质量
                if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
                    quality = obj.quality;
                }
                // quality值越小，所绘制出的图像越模糊
                var base64 = canvas.toDataURL("image/jpeg", quality);
                // 回调函数返回base64的值
                callback(base64);
            };
        },

        /** base64转file
         * filename图片的名字，dataurl是base64地址
         */
        dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, { type: mime });
        },
        // 上传图片
        saveFile(file, key) {
            this.hasUpload = true;
            let self = this;
            self.getHash(file, key, self.uploadImageInfo);
        },
        // 上传图片信息
        uploadImageInfo(file, key, crcCode) {
            console.log(crcCode);
            let self = this;
            var reader = new FileReader();
            let prefix = self.$OBS_PREFIX;
            reader.readAsDataURL(file);
            // reader.readAsArrayBuffer(file);
            reader.onload = function () {
                var imgResult = reader.result;
                var width, height, ratio, shape, format, type;
                let img = new Image();
                img.src = imgResult;
                img.onload = function (e) {
                    // console.log(e);
                    width = this.width;
                    height = this.height;
                    ratio = (width / height).toFixed(2);
                    shape = ratio == 1 ? 2 : ratio > 1 ? 1 : 0;
                    format = file.type.split("/")[1];
                    type = file.type.split("/")[0] == "image" ? 0 : 1;
                    let obsUrl = prefix + key;
                    // 当文件大小大于25MB时无法生成缩略图
                    // let obsThumbUrl = file.size > 26214400 ? prefix + "thumb-" + key : obsUrl + "?x-image-process=image/resize,w_210,limit_0";
                    let obsThumbUrl = file.size > 26214400 ? null : obsUrl + "?x-image-process=image/resize,w_210,limit_0";
                    const fileNameArr = self.cleanFileName(file);
                    console.log("upload directory ", self.$store.state.batchUploadDirecotry);
                    let resourceInfo = {
                        comment: "",
                        crcCode,
                        directoryId: self.$store.state.batchUploadDirecotry,
                        format,
                        name: fileNameArr,
                        obsThumbUrl,
                        obsUrl,
                        width,
                        height,
                        ratio: ratio + "",
                        shape,
                        size: (file.size / 1024).toFixed(2),
                        type,
                        url: "",
                        userId: "",
                        realiboxUrl: "",
                        isPublic: 0
                    };
                    let colorInfos = [];
                    let params = {
                        colorInfos,
                        resourceInfo
                    };

                    self.saveResourceInfo(params);

                    // 取消查重
                    // console.log(params);
                    // self.$http
                    //     .get("/resource-info/checkCrc?crc=" + crcCode)
                    //     .then(res => {
                    //         self.$store.commit('startUpload');
                    //         if (res.code == 0) {
                    //             if (res.data) {
                    //                 self.dialogSameVisible = true;
                    //                 localStorage.setItem("resourceInfo", JSON.stringify(params));
                    //                 self.sameFile.name = resourceInfo.name;
                    //                 self.sameFile.src = obsUrl;
                    //                 self.sameFileOrigin.name = res.data.name;
                    //                 self.sameFileOrigin.src = res.data.obsUrl;
                    //             } else {
                    //                 self.saveResourceInfo(params);
                    //             }
                    //         }
                    //     })
                    //     .catch(err => {
                    //         self.$store.commit('closeUploadImage');
                    //         self.$message.error("系统异常！");
                    //     });
                };
            };
        },
        // 保存作品信息
        saveResourceInfo(params) {
            let self = this;
            self.$http
                .post("/resource-info/save2", params)
                .then(res => {
                    // console.log(res);
                    self.$store.commit("closeUploadImage");
                    //   self.isUpload = false;
                    self.$emit("update:show", false);
                    if (res.code == 0) {
                        if (res.data) {
                            //   self.isEmpty = false;
                            //   self.waterfallList.unshift(res.data);
                            //   self.totalImage++;
                            self.$emit("afterUploadImage", res.data);
                        } else {
                            self.$message.error("上传失败！");
                        }
                    }
                })
                .catch(err => {
                    self.$store.commit("closeUploadImage");
                    //   self.isUpload = false;
                    self.$emit("update:show", false);
                    self.$message.error("上传失败！");
                });
        },
        // 校验后保存作品信息
        saveResourceInfoAfterCrc() {
            let resourceInfo = localStorage.getItem("resourceInfo");
            if (resourceInfo) resourceInfo = JSON.parse(resourceInfo);
            this.dialogSameVisible = false;
            this.saveResourceInfo(resourceInfo);
        },
        // 取消文件上传
        cancelUpload() {
            this.dialogSameVisible = false;
            this.$store.commit("closeUploadImage");
            //   this.isUpload = false;
            this.$emit("update:show", false);
        },
        //返回去除特殊字符后的图片名
        cleanFileName(file) {
            let fileNameNoExt = file.name;
            let extIndex = file.name.lastIndexOf(".");
            if (extIndex > -1) {
                fileNameNoExt = file.name.substring(0, extIndex);
            }
            //文件名不得含特殊字符
            fileNameNoExt = fileNameNoExt.replace(/[\\*&$!@%#\\.。；;，,？\\+]/gi, "_");
            return fileNameNoExt;
        }
    }
};
</script>
