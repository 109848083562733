<template>
  <el-popover
    ref="popover"
    placement="bottom-start"
    trigger="click"
    @show="onShowPopover"
    @hide="onHidePopover"
  >
    <div class="type-list-wrapper" :style="`min-width: ${treeWidth}`">
      <div class="type-list-item">
        <!-- <div class="type-title">空间全部</div> -->
        <ul class="type-list">
          <li
            @click="onClickNode(item)"
            v-for="item in typeList"
            :key="item.id"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <el-input
      slot="reference"
      ref="input"
      v-model.trim="labelModel"
      :style="`width: ${width}px`"
      :class="{ rotate: showStatus }"
      suffix-icon="el-icon-arrow-down"
      placeholder=""
    >
    </el-input>
  </el-popover>
</template>

<script>
export default {
  name: "TypeSelect",
  props: ["value", "width"],
  watch: {
    labelModel(val) {
      if (!val) {
        this.valueModel = "";
      }
      // this.$refs.tree.filter(val);
    },
    value(val) {
      // console.log(val)
      // this.labelModel = this.queryTree(this.data, val);
      if (!val) this.labelModel = "";
      this.typeList.forEach((item) => {
        if (item.id == this.value) {
          this.labelModel = item.name;
        }
      });
    },
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      // 树状菜单显示状态
      showStatus: false,
      // 菜单宽度
      treeWidth: "auto",
      // 输入框显示值
      labelModel: "",
      // 实际请求传值
      valueModel: "",
      typeList: [],
    };
  },
  created() {
    // 获取类型列表
    this.getTypeList();

    // 检测输入框原有值并显示对应 label
    // if (this.value) {
    //   this.labelModel = this.queryTree(this.data, this.value);
    //   this.labelModel = this.value;
    // } else {
    //   this.labelModel = "";
    // }
    // 获取输入框宽度同步至树状菜单宽度
    this.$nextTick(() => {
      this.treeWidth = `${
        (this.width || this.$refs.input.$refs.input.clientWidth) - 24
      }px`;
    });
  },
  methods: {
    // 获取分类列表
    getTypeList() {
      this.$http
        .get("/dict-category/list")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.typeList = res.data;
            this.typeList.forEach((item) => {
              if (item.id == this.value) {
                this.labelModel = item.name;
              }
            });
          }
        })
        .catch((err) => {});
    },
    // 单击节点
    onClickNode(node) {
      console.log(node);
      //   this.labelModel = node[this.props.label];
      //   this.valueModel = node[this.props.value];
      this.valueModel = node.id;
      this.labelModel = node.name;
      this.onCloseTree();
    },
    // 隐藏树状菜单
    onCloseTree() {
      this.$refs.popover.showPopper = false;
    },
    // 显示时触发
    onShowPopover() {
      this.showStatus = true;
    },
    // 隐藏时触发
    onHidePopover() {
      this.showStatus = false;
      this.$emit("selected", this.valueModel);
    },
  },
};
</script>

<style lang="less">
.el-input.el-input--suffix {
  cursor: pointer;
  overflow: hidden;
}
.el-input.el-input--suffix.rotate .el-input__suffix {
  transform: rotate(180deg);
}

.type-list-wrapper {
  width: 100%;
  display: flex;

  .type-list-item {
    padding: 0px 5px;
    .type-title {
      text-align: center;
      color: #aaa;
      font-size: 16px;
      line-height: 16px;
      padding: 15px 0 8px;
    }
    .type-list {
      > li {
        color: #393939;
        width: 88px;
        text-align: center;
        line-height: 30px;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
</style>
