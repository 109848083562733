<template>
  <!-- 图片详情弹框 -->
  <div
    class="image-property-drawer animated"
    :class="{ slideInRight: show, slideOutRight: !show }"
    v-if="show"
    v-loading="isLoading"
  >
    <div class="div-scroll">
      <div class="total-container" v-show="!infoData.id">
        共{{ total }}张作品
      </div>
      <div class="property-container" v-show="infoData.id">
        <div class="prop-type-title">
          <span>分类详情</span>
          <el-divider direction="vertical"></el-divider>
          <span>已选定</span>
        </div>
        <div class="prop-thumbnail mb10">
          <!-- <img :src="infoData.obsUrl || infoData.obsThumbUrl" alt="" /> -->
          <img v-if="!isCollectionDetail" :src="infoData.obsThumbUrl || infoData.obsUrl" alt="" />
        </div>
        <ul class="prop-color-card mb10">
          <li
            class="color-caard-item"
            v-for="(color, index) in colors"
            :key="index"
            :title="
              'rgb(' + color.rval + ',' + color.gval + ',' + color.bval + ')'
            "
            :style="{
              'background-color':
                'rgb(' + color.rval + ',' + color.gval + ',' + color.bval + ')',
            }"
            @click="handleColorScreen(color)"
          ></li>
        </ul>
        <div class="prop-img-name mb10">
          <el-input
            v-model="dataInfo.name"
            @change="handleModifyInfo"
            :disabled="disabled"
          >
          </el-input>
        </div>
        <el-popover
          v-model="isAdd"
          placement="left-start"
          width="272"
          trigger="click"
          popper-class="tag-add-popper"
          @hide="handleLabelPopperHide"
        >
          <div
            class="prop-img-label mb10"
            slot="reference"
            :class="{ disabled: disabled }"
          >
            <!-- v-if="dataInfo.type != 1" -->
            <div class="mask" @click.stop="isAdd = false"></div>
            <div
              class="no-label"
              v-if="!photoDefaultTagList.length && !photoCustomTagList.length"
            >
              添加标签
            </div>
            <div class="label-item" v-if="photoDefaultTagList.length">
              <div class="photo-tag-title">默认标签</div>
              <div class="tag-wrapper">
                <el-tag
                  v-for="tag in photoDefaultTagList"
                  :key="tag.tagId"
                  :closable="!disabled"
                  type="info"
                  @close="handleDeleteDefaultTagList(tag)"
                >
                  {{ tag.tagName }}
                </el-tag>
              </div>
            </div>
            <div class="label-item" v-if="photoCustomTagList.length">
              <div class="photo-tag-title">自定义标签</div>
              <div class="tag-wrapper">
                <el-tag
                  v-for="tag in photoCustomTagList"
                  :key="tag.id"
                  :closable="!disabled"
                  type="info"
                  @close="handleDeleteCustomTagList(tag)"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </div>
          </div>
          <div class="tag-add-wrapper">
            <div class="tab-container">
              <div
                class="tab-item"
                :class="{ active: activeTab == 'default' }"
                @click="handleTabChange('default')"
                v-if="dataInfo.type != 1"
              >
                默认标签
              </div>
              <div
                class="tab-item"
                :class="{ active: activeTab == 'custom' }"
                @click="handleTabChange('custom')"
              >
                自定义标签
              </div>
            </div>
            <div class="tab-content">
              <div class="default-wrapper" v-if="activeTab == 'default'">
                <el-autocomplete
                  v-model="state"
                  size="small"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="标签搜索"
                  value-key="tagName"
                  @select="handleSelect"
                  style="width: 100%"
                  clearable
                ></el-autocomplete>
                <div class="default-tag-list">
                  <el-tag
                    size="small"
                    v-for="tag in defaultTagList"
                    :key="tag.tagId"
                    type="info"
                    effect="plain"
                    @click="handleDefaultTagClick(tag)"
                  >
                    {{ tag.tagName }}
                  </el-tag>
                </div>
              </div>
              <div class="custom-wrapper" v-if="activeTab == 'custom'">
                <div class="input-container">
                  <el-input
                    v-model.trim="newtag"
                    size="small"
                    placeholder="添加标签"
                    maxlength="12"
                  ></el-input>
                  <el-button size="small" @click="handleCreateTag"
                    >创建</el-button
                  >
                </div>
                <div class="history-tag">
                  <div class="history-tag-title">历史标签</div>
                  <div class="history-tag-list">
                    <el-tag
                      size="small"
                      v-for="tag in historyTagList"
                      :key="tag.id"
                      closable
                      type="info"
                      effect="plain"
                      @click="handleCustomTagClick(tag)"
                      @close="handleDeleteHistoryTag(tag)"
                    >
                      {{ tag.name }}
                    </el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-popover>

        <div class="prop-img-remark mb10" v-if="dataInfo.type != 1">
          <el-input
            type="textarea"
            placeholder="添加注释"
            :rows="5"
            v-model="dataInfo.comment"
            @change="handleModifyInfo"
            :disabled="disabled"
          >
          </el-input>
        </div>
        <div class="prop-img-link mb10 fix-clearable" v-if="dataInfo.type != 1">
          <el-input
            v-model="dataInfo.url"
            placeholder="http://"
            @change="handleModifyInfo"
            :disabled="disabled"
            clearable
            ref="MyInput"
            @focus="goto(dataInfo.url)"
          >
          </el-input>
        </div>
        <div class="prop-img-folder mb10">
          <div class="basic-title">作品集</div>
          <div class="folder-select">
            <el-input
              v-if="disabled"
              v-model="infoData.directoryName"
              @change="handleModifyInfo"
              :disabled="disabled"
            >
            </el-input>
            <el-select
              v-else
              v-model="directoryId"
              clearable
              placeholder="请选择"
              @change="addToFolder"
              @clear="addToFolder"
              :disabled="disabled"
            >
              <el-option
                v-for="item in data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.isPublic == 0 ? "私有" : "公开"
                }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="prop-img-folder mb10" v-if="dataInfo.isOffShelf == 1">
          <div class="basic-title" style="margin-bottom: 5px">下架原因</div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="offComment"
            @change="handleModifyInfo"
            disabled
          >
          </el-input>
        </div>
        <div class="prop-img-basic mb10">
          <div class="basic-title">基本信息</div>
          <ul>
            <li v-if="dataInfo.type != 1">
              <span class="basic-label">尺寸</span>
              <span class="basic-value">{{
                dataInfo.width + "*" + dataInfo.height
              }}</span>
            </li>
            <li>
              <span class="basic-label">作品大小</span>
              <span class="basic-value" v-if="dataInfo.size < 1024"
                >{{ dataInfo.size }}KB</span
              >
              <span class="basic-value" v-else
                >{{ (dataInfo.size / 1024).toFixed(2) }}MB</span
              >
            </li>
            <li>
              <span class="basic-label">作品类型</span>
              <!-- <span class="basic-value">{{ dataInfo.type == 1 ? "模型" : dataInfo.format }}</span> -->
              <span class="basic-value">{{ dataInfo.format || "--" }}</span>
            </li>
            <li>
              <span class="basic-label">创建时间</span>
              <span class="basic-value">{{ dataInfo.createTime }}</span>
            </li>
            <li v-if="dataInfo.type == 1">
              <span class="basic-label">发布状态</span>
              <span class="basic-value">
                {{ dataInfo.realiboxIsPublish == 1 ? "已发布" : "未发布" }}
              </span>
            </li>
            <li v-if="menuIndex != 4">
              <span class="basic-label">公开状态</span>
              <span class="basic-value">
                {{ dataInfo.isPublic == 1 ? "公开" : "私有" }}
              </span>
            </li>
            <li v-if="menuIndex != 4">
              <span class="basic-label">共享状态</span>
              <span class="basic-value">
                {{ shareList && shareList.length > 0 ? "共享" : "不共享" }}
              </span>
            </li>
            <li v-if="shareList && shareList.length > 0" class="share-wrapper">
              <span class="basic-label">{{
                menuIndex == 4 ? "共享者" : "共享对象"
              }}</span>
              <span
                class="basic-value"
                v-if="menuIndex == 3 || menuIndex == 4"
                >{{ nickname }}</span
              >
              <div class="share-list" v-if="menuIndex != 4">
                <el-tag
                  v-for="(tag, index) in shareList"
                  :key="tag.username"
                  :closable="true"
                  type="info"
                  @close="handleUnshared(tag, index)"
                >
                  {{ tag.nickname }}
                </el-tag>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapMutations } from "vuex"
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      nickname: "",
      colors: [],
      folderList: [], // 文件夹列表
      shareList: [], // 分享列表
      data: [],
      defaultProps: {
        children: "child",
        label: "name",
      },
      dataInfo: {},
      directoryId: "",
      isLoading: false,
      disabled: false,
      tagList: [
        { id: 1, name: "建筑" },
        { id: 2, name: "建筑" },
        { id: 3, name: "建筑" },
        { id: 4, name: "建筑" },
        { id: 5, name: "建筑" },
      ], // 图片标签列表
      isAdd: false,
      activeTab: "default",
      state: "", // 远程搜索默认标签
      newtag: "", // 新增标签名称
      defaultTagList: [], //默认标签列表
      historyTagList: [], //历史标签列表
      photoCustomTagList: [], // 图片绑定的自定义标签
      photoDefaultTagList: [], // 图片 绑定的默认标签
      offComment: null, // 下架原因
    };
  },
  props: {
    infoData: {
      type: Object,
    },
    total: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    menuIndex: Number,
  },
  watch: {
    infoData: {
      handler(val) {
        console.log(val);
        if (val.id && val.id != this.dataInfo.id) {
          this.getImageInfo(val.id);
          this.getDefaultTagList(); // 获取默认标签
          this.getPhotoDefaultTagList(); // 获取图片绑定的默认标签
          this.getPhotoTagList(); // 获取图片绑定的自定义标签
        }
      },
      deep: true,
      immediate: true,
    },
    show: {
      handler(val) {
        this.isAdd = false;
      },
      deep: true,
      immediate: true,
    },
    menuIndex: {
      handler(val) {
        console.log(val);
        this.isAdd = false;
      },
      immediate: true,
      deep: true,
    },
  },
  computed:{
    // 处于作品详情显示状态
    isCollectionDetail(){
      return this.$route.query.id&&this.$route.path==='/home'
    }
  },
  created() {
    // this.getTreeFolder();
    this.getHistoryTagList(); // 获取自定义历史标签
  },
  mounted() {
    EventBus.$on("updateFolderList", () => {
      this.getTreeFolder();
    });
    EventBus.$on("disabledUpdate", (data) => {
      console.log(data);
      if (data == 4 || data == 3) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    });
  },
  methods: {
    ...mapMutations("collectionSidebar",["shiftCollection"]),
    handleCheckChange(data, node) {
      console.log(data);
      this.$refs.folderTree.setCheckedKeys([]);
      this.$refs.folderTree.setCheckedKeys([data.id]);
      this.addToFolder(data);
    },
    handleClose(data, index) {
      // this.folderList.splice(index, 1);
      this.addToFolder({});
    },
    /**
     * 添加至文件夹
     * 如果当前处于首页作品作品集预览模式，作品更改到其他作品集时，
     * 当前预览项会改为当前作品的最近的下一个非模型图片项
     * @param {object} data 
     */
    addToFolder(data) {
      // console.log(data);
      // console.log(this.directoryId);
      // if (this.infoData.directoryId == data.id) return;
      // 判断如果是模型时，则需要判断是否发布，未发布则不能保存到公开文件夹
      // if (this.dataInfo.type == 1 && data.isPublic == 1) {
      //   this.directoryId = null;
      //   return this.$message.warning("模型未发布，暂不可添加至公开文件夹！");
      // }
      let rIds = this.infoData.id;
      
      let params = {
        rIds,
        dId: data,
      };
      this.$http
        .post("/resource-info/addToDirectory", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            console.log(res);
            this.$parent.getData(true);
            this.getImageInfo(this.dataInfo.id);
            this.$message.success("修改成功！");
            if(this.isCollectionDetail){
              this.shiftCollection({
                id:rIds,
                callback:(nextId)=>{
                  if(nextId===-1){
                    this.$emit("closePreviewMode")
                    this.$router.replace("/home")
                    return
                  }
                  this.$router.push({
                    path:this.$route.path,
                    query:{
                      id:nextId
                    }
                  })
                }
              })
            }
          } else {
            this.$message.error("修改失败！");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败！");
          console.log("error:",err)
        });
    },
    getFormatDesc(data) {
      if (data) {
        let obj = this.$store.state.dic._FORMAT.filter(
          (item) => item.id == data
        );
        return obj[0].name || "其他";
      } else {
        return "";
      }
    },
    // 获取图片详情
    getImageInfo(id) {
      this.isLoading = true;
      this.$http
        .get("/resource-info/" + id)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data) {
              this.dataInfo = res.data.resourceInfo;
              this.directoryId = this.dataInfo.directoryId;
              this.colors = res.data.colors;
              this.nickname = res.data.nickname;
              if (this.dataInfo.type == 1) {
                this.activeTab = "custom";
              }
              if (
                this.dataInfo.type == 1 &&
                this.dataInfo.realiboxIsPublish != 1
              ) {
                this.getTreeFolder("model");
              } else {
                this.getTreeFolder();
              }
              if (res.data.users) {
                this.shareList = res.data.users;
              }
              if (this.dataInfo.isOffShelf) {
                this.queryOffShelfReason(this.dataInfo.id);
              }
            } else {
              this.dataInfo = Object.assign({}, this.infoData);
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.dataInfo = {};
          this.isLoading = false;
        });
    },
    // 修改图片信息
    handleModifyInfo() {
      this.$http
        .post("/resource-info/update", this.dataInfo)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("修改成功！");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败！");
        });
    },
    // 取消分享
    handleUnshared(data, index) {
      let rId = this.dataInfo.id;
      let uIds = data.id;
      this.$http
        .post("/share-info/unshared/" + rId, null, { params: { uIds } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.shareList.splice(index, 1);
            this.$message.success("取消共享成功！");
            if (this.shareList.length == 0) {
              // this.$emit("screenImage", { menuSelect: 5 });
              this.$emit("screenImage");
            }
          } else {
            this.$message.error("取消共享失败！");
          }
        })
        .catch((err) => {
          this.$message.error("取消共享失败！");
        });
    },
    // 获取文件夹数
    getTreeFolder(flag = "") {
      let params = {};
      if (flag == "model") {
        params.isPublic = false;
      }
      this.$http
        .get("/directory-info/nodes", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.data = res.data;
            // 回显当前的文件夹
            // this.data.forEach((item) => {
            //   if (item.id == this.dataInfo.directoryId) {
            //     this.directoryId = item;
            //   }
            // });
          }
        })
        .catch((err) => {
          this.data = [];
        });
    },
    // 颜色筛选
    handleColorScreen(color) {
      EventBus.$emit("colorScreen", color);
    },
    handleTabChange(tab) {
      this.activeTab = tab;
    },
    // -------------默认标签相关操作---------------------
    // 隐藏popper
    handleLabelPopperHide() {
      this.newtag = "";
      this.state = "";
    },
    // 远程搜索默认标签
    querySearchAsync(queryString, cb) {
      this.getDefaultTagList(queryString, cb);
    },
    // 获取图片所有默认标签
    getDefaultTagList(tagName = "", cb) {
      let resourceId = this.infoData.id;
      let params = { tagName };
      this.$http
        .get("/resource-com-tag/list/" + resourceId, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.defaultTagList = res.data;
            cb && cb(res.data);
          } else {
            this.$message.error(res.msg);
            this.defaultTagList = [];
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.defaultTagList = [];
        });
    },
    // 选中默认标签
    handleSelect(item) {
      this.handleDefaultTagClick(item);
    },
    // 获取图片绑定的默认标签
    getPhotoDefaultTagList() {
      let resourceId = this.infoData.id;
      this.$http
        .get("/resource-com-tag/listOn/" + resourceId + "?isPublic=false")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.photoDefaultTagList = res.data;
          } else {
            this.photoDefaultTagList = [];
          }
        })
        .catch((err) => {
          this.photoDefaultTagList = [];
        });
    },
    // 删除图片绑定的默认标签
    handleDeleteDefaultTagList(tag) {
      if (this.photoDefaultTagList.length < 2) {
        return this.$message.warning("至少需保留一个默认标签！");
      }
      this.$confirm("此操作将删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$http
            .post("/resource-com-tag/remove", tag.id)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.$message({
                  message: "恭喜你，删除成功！",
                  type: "success",
                });
                // this.pageDelAfterFn(res);
                this.getPhotoDefaultTagList();
              } else {
                this.$message.error(`删除数据失败，${res.msg}`);
              }
            })
            .catch((err) => {
              console.warn(`删除数据失败，${err}`);
              this.$message.error(`删除数据失败，${err}`);
            });
        })
        .catch((err) => console.warn(err));
    },
    // 默认标签点击----绑定至图片
    handleDefaultTagClick(tag) {
      if (this.photoDefaultTagList.length >= 6) {
        return this.$message.warning("最多只能有6个默认标签！");
      }
      this.$http.post("/resource-com-tag/add", tag.id).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.getPhotoDefaultTagList();
        }
      });
    },
    // -------------------自定义标签相关操作---------------------
    // 获取自定义历史记录标签
    getHistoryTagList(name = "") {
      let params = { name };
      this.$http
        .get("/tag-info/historyList", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.historyTagList = res.data;
          } else {
            this.$message.error(res.msg);
            this.historyTagList = [];
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.historyTagList = [];
        });
    },
    // 创建自定义标签
    handleCreateTag() {
      if (this.photoCustomTagList.length >= 4) {
        return this.$message.warning("最多只能有4个自定义标签！");
      }
      let params = {
        name: this.newtag,
        resourceId: this.dataInfo.id,
      };
      this.$http.post("/tag-info/save", params).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.newtag = "";
          this.getHistoryTagList();
          this.getPhotoTagList();
        }
      });
    },
    // 删除历史标签
    handleDeleteHistoryTag(tag) {
      this.$confirm("此操作将删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$http
            .delete("/tag-info/" + tag.id)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.$message({
                  message: "恭喜你，删除成功！",
                  type: "success",
                });
                this.getHistoryTagList();
                // this.pageDelAfterFn(res);
              } else {
                this.$message.error(`删除数据失败，${res.msg}`);
              }
            })
            .catch((err) => {
              console.warn(`删除数据失败，${err}`);
              this.$message.error(`删除数据失败，${err}`);
            });
        })
        .catch((err) => console.warn(err));
    },
    // 获取图片绑定的自定义标签
    getPhotoTagList() {
      let resourceId = this.infoData.id;
      this.$http
        .get("/tag-info/list/" + resourceId)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.photoCustomTagList = res.data;
          } else {
            this.photoCustomTagList = [];
          }
        })
        .catch((err) => {
          this.photoCustomTagList = [];
        });
    },
    // 点击自定义标签--绑定到图片上
    handleCustomTagClick(tag) {
      if (this.photoCustomTagList.length >= 4) {
        return this.$message.warning("最多只能有4个自定义标签！");
      }
      let params = {
        tagId: tag.id,
        resourceId: this.dataInfo.id,
      };
      this.$http
        .post("/tag-info/addRelation", params)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.getPhotoTagList();
          } else {
            this.$message.error(`添加数据失败，${res.msg}`);
          }
        })
        .catch((err) => {
          this.$message.error(`添加数据失败，${err}`);
        });
    },
    // 删除图片绑定的自定义标签
    handleDeleteCustomTagList(tag) {
      this.$confirm("此操作将删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            tagId: tag.id,
            resourceId: this.dataInfo.id,
          };
          this.$http
            .post("/tag-info/deleteRelation", params)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.$message({
                  message: "恭喜你，删除成功！",
                  type: "success",
                });
                // this.pageDelAfterFn(res);
                this.getPhotoTagList();
              } else {
                this.$message.error(`删除数据失败，${res.msg}`);
              }
            })
            .catch((err) => {
              console.warn(`删除数据失败，${err}`);
              this.$message.error(`删除数据失败，${err}`);
            });
        })
        .catch((err) => console.warn(err));
    },
    // 查询下架原因
    queryOffShelfReason(id) {
      let params = { rid: id };
      this.$http.get("/off-shelf-log/load", { params }).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.offComment = res.data.comment || "";
        }
      });
    },
    goto(url) {
      if (url) {
        this.$refs.MyInput.blur();
        console.log(url);
        window.open(url, "_blank");
      }
    },
  },
  beforeDestroy() {
    this.isAdd = false;
    EventBus.$off("updateFolderList");
  },
};
</script>

<style lang="less">
.image-property-drawer {
  width: 240px;
  height: 100%;
  // padding: 10px;
  padding: 15px 0;
  // margin-left: 20px;
  background-color: #fff;
  position: relative;
  .div-scroll {
    overflow-x: hidden;
  }
  .total-container {
    width: 240px;
    padding: 20px 0;
    text-align: center;
  }
  .close-btn {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 5px;
    transition: all ease 0.38s;

    &:hover {
      transform: rotate(90deg);
      color: #8565f6;
    }
  }

  .el-drawer__body {
    background-color: #f5f6fa;
  }

  .property-container {
    width: 240px;
    // height: 100%;
    padding: 0 20px;
    border-radius: 4px;

    .el-input__inner,
    .el-textarea__inner {
      background-color: #f5f6fa;
      color: #464646;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    .prop-type-title {
      padding: 10px 0 20px;
      display: flex;
      // justify-content: space-between;
      justify-content: space-around;
      align-items: center;
      color: #505050;
      .el-divider--vertical {
        // height: 13px;
        background-color: #d3d3d3;
      }
    }

    .prop-thumbnail {
      > img {
        width: 100%;
      }
    }

    .prop-color-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .color-caard-item {
        width: 19%;
        height: 15px;
        margin-bottom: 4px;
        border-radius: 2px;
        cursor: pointer;
        transition: all ease 0.38s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .prop-img-name {
    }

    .prop-img-label {
      min-height: 100px;
      background-color: #f5f6fa;
      padding: 8px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      &.disabled {
        cursor: not-allowed;
        position: relative;
        .mask {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
      .label-item {
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        .photo-tag-title {
          line-height: 26px;
          color: #505050;
          font-size: 12px;
        }
        .tag-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .el-tag {
            margin-right: 5px;
            margin-bottom: 5px;
            background-color: #fff;
            color: #3c3c3c;
            .el-tag__close {
              color: #3c3c3c;
            }
          }
        }
      }

      .no-label {
        font-size: 12px;
        color: #c0c4cc;
      }

      .icon-tag-add {
        font-size: 20px;
        cursor: pointer;
      }
    }
    .prop-img-folder {
      padding-top: 10px;
      .basic-title {
        font-size: 12px;
        color: #505050;
      }
      .folder-select {
        padding: 5px 0;
        .el-input__icon {
          line-height: 30px !important;
        }
      }
      .folder-list {
        padding: 10px 0;
        color: #8c8c8c;
        font-size: 12px;
        .el-tag {
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .folder-add {
          cursor: pointer;
        }
        .el-icon-circle-plus {
          font-size: 14px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
    .prop-img-basic {
      padding-top: 10px;

      .basic-title {
        font-size: 12px;
        color: #505050;
      }

      > ul {
        li {
          line-height: 26px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          color: #8c8c8c;
          font-size: 12px;
          .basic-label {
            width: 70px;
          }
          .basic-value {
            width: calc(100% - 70px);
            display: flex;
            justify-content: flex-end;
            word-break: break-all;
          }
        }
      }
    }

    .share-wrapper {
      align-items: flex-start !important;
      .share-list {
        flex: 1;
        padding: 10px 0 10px 20px;
        color: #8c8c8c;
        font-size: 12px;
        .el-tag {
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
.fix-clearable{
  line-height: 30px;
  .el-input__icon{
    line-height: 30px;
  }
}
</style>
