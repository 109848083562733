<template>
    <div class="sidetree-wrapper">
        <div class="sidetree-box">
            <el-scrollbar wrap-class="scrollbar-wrapper">
                <div class="side-menu">
                    <div class="side-menu-title" @click="handleCollapse">
                        <span>我的作品</span>
                        <i class="el-icon-arrow-down" :class="{ collapse: isCollapse }"></i>
                    </div>
                    <ul class="side-submenu" :class="{ collapse: isCollapse }">
                        <li :class="{ active: current == 0 }" @click="handleMenuClick(0)">
                            <div class="menu-item-wrapper">
                                <div class="icon-wrapper">
                                    <img class="icon-all" src="../../assets/images/icon/icon-all.png" alt="" />
                                </div>
                                <div class="text-wrapper">
                                    <span>全部作品</span>
                                    <!-- <span class="work-num">51</span> -->
                                </div>
                            </div>
                        </li>
                        <li :class="{ active: current == 1 }" @click="handleMenuClick(1)">
                            <div class="menu-item-wrapper">
                                <div class="icon-wrapper">
                                    <img class="icon-unclassified" src="../../assets/images/icon/icon_weifenlei@2x.png" alt="" />
                                </div>
                                <div class="text-wrapper">
                                    <span>未分类作品</span>
                                    <!-- <span class="work-num">10</span> -->
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="menu-item-wrapper" @click="isCollapseTree = !isCollapseTree">
                                <div class="icon-wrapper">
                                    <img class="icon-folder" src="../../assets/images/icon/icon_wenjianjia@2x.png" alt="" />
                                </div>
                                <div class="text-wrapper">
                                    <span>我的作品集</span>
                                    <span class="work-num">
                                        <!-- <el-popover
                      placement="right"
                      width="240"
                      trigger="click"
                      v-model="folderFlag"
                    >
                      <div class="new-folder-wrapper">
                        <div class="new-title">
                          <span>输入作品集名称</span>
                          <el-button size="mini" @click="handleAddFolder"
                            >确定</el-button
                          >
                        </div>
                        <el-input
                          placeholder="输入名称"
                          v-model="folderName"
                        ></el-input>
                      </div>
                      <i class="el-icon-plus" @click.stop slot="reference"></i>
                    </el-popover> -->
                                        <i class="el-icon-plus" @click.stop="handleAddPrivateFolder"></i>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li
                            class="folder-tree-wrapper"
                            :class="{ collapse: isCollapseTree }"
                            v-if="folderList && folderList.length > 0"
                            v-loading="folderLoading"
                        >
                            <div class="folder-tree">
                                <el-tree
                                    ref="folderTree1"
                                    :data="folderList"
                                    node-key="id"
                                    default-expand-all
                                    highlight-current
                                    draggable
                                    :props="defaultProps"
                                    @node-click="handleNodeClick"
                                    @node-drop="handleDrop"
                                    :expand-on-click-node="false"
                                    :allow-drop="allowDrop"
                                >
                                    <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <div class="input-rename" v-if="data.id == currentNodeId">
                                            <el-input ref="renameInput1" v-model.trim="currentRename" @blur.prevent="handleRename"></el-input>
                                        </div>
                                        <span class="ellipsis" :title="data.name">{{ data.name }}</span>
                                        <el-popover placement="right-start" trigger="hover">
                                            <ul class="gengduo-popover">
                                                <!-- <li @click="handleAddSubFolder(data)">新建子文件夹</li> -->
                                                <li @click="handleRenameFolder(data, false)">重命名</li>
                                                <li @click="handleShareFolder(data)">共享链接</li>
                                                <li @click="handleAddPublicFolder(data)">分享到社区</li>
                                                <li @click="handleDeleteFolder(node, data)">删除作品集</li>
                                                <li @click="handleUploadModel(data.id)">
                                                    <model-upload>
                                                        <span>上传模型</span>
                                                    </model-upload>
                                                </li>
                                            </ul>
                                            <i class="iconfont icon-gengduo" style="float: right; margin-right: 0" slot="reference"></i>
                                        </el-popover>
                                    </span>
                                </el-tree>
                            </div>
                        </li>

                        <li>
                            <div class="menu-item-wrapper" @click="isPublicCollapseTree = !isPublicCollapseTree">
                                <div class="icon-wrapper">
                                    <img class="icon-folder" src="../../assets/images/icon/icon_gongkaizuop@2x.png" alt="" />
                                </div>
                                <div class="text-wrapper">
                                    <span>我的公开作品</span>
                                    <span class="work-num">
                                        <i class="el-icon-plus" @click.stop="handleAddPublicFolder(null)"></i>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li
                            class="folder-tree-wrapper"
                            :class="{ collapse: isPublicCollapseTree }"
                            v-if="publicFolderList && publicFolderList.length > 0"
                            v-loading="publicFolderLoading"
                        >
                            <div class="folder-tree">
                                <el-tree
                                    ref="folderTree2"
                                    :data="publicFolderList"
                                    node-key="id"
                                    default-expand-all
                                    highlight-current
                                    draggable
                                    :props="defaultProps"
                                    @node-click="handlePublicNodeClick"
                                    :expand-on-click-node="false"
                                    @node-drop="handlePublicNodeDrop"
                                    :allow-drop="allowDrop"
                                >
                                    <!-- draggable -->
                                    <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <div class="input-rename" v-if="data.id == currentNodeId">
                                            <el-input ref="renameInput2" v-model.trim="currentRename" @blur.prevent="handleRename"></el-input>
                                        </div>
                                        <span class="ellipsis" :title="data.name">{{ data.name }}</span>
                                        <el-popover placement="right-start" trigger="hover">
                                            <ul class="gengduo-popover">
                                                <li @click="handleRenameFolder(data, true)">重命名</li>
                                                <li @click="handleShareLink(data, $event)" id="copy">分享链接</li>
                                                <li @click="handleToPrivate(data)">设置私有</li>
                                                <li @click="handleDeleteFolder(node, data)">删除作品集</li>
                                            </ul>
                                            <i class="iconfont icon-gengduo" style="float: right; margin-right: 0" slot="reference"></i>
                                        </el-popover>
                                    </span>
                                </el-tree>
                            </div>
                        </li>
                        <!-- <li :class="{ active: current == 6 }" @click="handleMenuClick(6)">
              <div class="menu-item-wrapper">
                <div class="icon-wrapper">
                  <img
                    class="icon-xiajia"
                    src="../../assets/images/icon/icon_xiajia.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>下架作品</span>
                </div>
              </div>
            </li> -->
                        <!-- <li :class="{ active: current == 3 }" @click="handleMenuClick(3)">
              <div class="menu-item-wrapper">
                <div class="icon-wrapper">
                  <img
                    class="icon-trash"
                    src="../../assets/images/icon/icon_trash@2x.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>回收站</span>
                </div>
              </div>
            </li> -->
                    </ul>
                </div>
                <div class="side-menu">
                    <!-- @click="handleMenuClick(4)" -->
                    <div class="side-menu-title" @click="isShareItWithCollapseTree = !isShareItWithCollapseTree">
                        <!-- <i class="iconfont icon-shoudaodegongxiang"></i> -->
                        <img class="icon-shoudaodegongxiang" src="../../assets/images/icon/icon_shoudaodegongxiang@2x.png" alt="" />
                        <span>共享给我的作品</span>
                    </div>
                </div>
                <div class="side-menu">
                    <ul class="side-submenu" :class="{ collapse: isCollapse }">
                        <li
                            class="folder-tree-wrapper"
                            :class="{ collapse: isShareItWithCollapseTree }"
                            v-if="shareItWithMeFolderOptions && shareItWithMeFolderOptions.length > 0"
                            v-loading="folderLoading"
                        >
                            <div class="folder-tree">
                                <el-tree
                                    ref="folderTree3"
                                    :data="shareItWithMeFolderOptions"
                                    node-key="id"
                                    default-expand-all
                                    highlight-current
                                    :props="defaultProps"
                                    @node-click="handleShareItWhiteNodeClick"
                                    :expand-on-click-node="false"
                                >
                                    <span class="custom-tree-node" slot-scope="{ data }">
                                        <span class="ellipsis" :title="data.name">{{ data.name }}</span>
                                    </span>
                                </el-tree>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="side-menu">
                    <div class="side-menu-title" @click="isIShareItWithCollapseTree = !isIShareItWithCollapseTree">
                        <!-- <i class="iconfont icon-fachudegongxiang"></i> -->
                        <img class="icon-fachudegongxiang" src="../../assets/images/icon/icon_fachudegongxiang.png" alt="" />
                        <span>我共享的作品</span>
                    </div>
                </div>

                <div class="side-menu">
                    <ul class="side-submenu" :class="{ collapse: isCollapse }">
                        <li
                            class="folder-tree-wrapper"
                            :class="{ collapse: isIShareItWithCollapseTree }"
                            v-if="iShareItWithMeFolderOptions && iShareItWithMeFolderOptions.length > 0"
                            v-loading="folderLoading"
                        >
                            <div class="folder-tree">
                                <el-tree
                                    ref="folderTree4"
                                    :data="iShareItWithMeFolderOptions"
                                    node-key="id"
                                    default-expand-all
                                    highlight-current
                                    :props="defaultProps"
                                    @node-click="handleIShareItWhiteNodeClick"
                                    :expand-on-click-node="false"
                                >
                                    <span class="custom-tree-node" slot-scope="{ data }">
                                        <span class="ellipsis" :title="data.name">{{ data.name }}</span>
                                    </span>
                                </el-tree>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="side-menu">
                    <div class="side-menu-title" :class="{ active: current == 9 }" @click="handleMenuClick(9)">
                        <img src="../../assets/images/icon/icon_shoucang.png" alt="" class="icon-bg-model" />
                        <span>收藏的作品</span>
                    </div>
                </div>
                <div class="side-menu">
                    <div class="side-menu-title" :class="{ active: current == 10 }" @click="handleMenuClick(10)">
                        <img src="../../assets/images/icon/icon_models.png" alt="" class="icon-bg-model" />
                        <span>收藏的模型</span>
                    </div>
                </div>
                <div class="side-menu">
                    <div class="side-menu-title" :class="{ active: current == 6 }" @click="handleMenuClick(6)">
                        <img class="icon-xiajia" src="../../assets/images/icon/icon_xiajia.png" alt="" />
                        <span>下架作品</span>
                    </div>
                </div>
                <div class="side-menu">
                    <div class="side-menu-title" :class="{ active: current == 3 }" @click="handleMenuClick(3)">
                        <img class="icon-trash" src="../../assets/images/icon/icon_trash.png" alt="" />
                        <span>回收站</span>
                    </div>
                </div>
                <div class="side-menu">
                    <div class="side-menu-title" :class="{ active: current == 8 }" @click="handleMenuClick(8)">
                        <!-- <i class="iconfont icon-bg-model"></i> -->
                        <img src="../../assets/images/icon/icon_list.png" alt="" class="icon-bg-model" />
                        <span>上传模型列表</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <div class="search-container">
            <el-input
                placeholder="筛选作品集"
                prefix-icon="el-icon-search"
                v-model="searchVal"
                clearable
                @clear="screenTreeFolder"
                @change="screenTreeFolder"
            >
            </el-input>
        </div>
        <!-- 创建作品集弹框 -->
        <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" :show-close="false" custom-class="public-dialog" width="40%">
            <div class="dialog-title" slot="title">{{ dialogTitle }}</div>
            <div class="dialog-content">
                <el-form :model="publicForm" ref="publicForm" :rules="formRules">
                    <el-form-item label="名称" :label-width="'120px'" prop="name">
                        <el-input
                            maxlength="100"
                            v-model="publicForm.name"
                            placeholder="作品集名称"
                            :disabled="isChangeToPublic"
                            show-word-limit
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="分类" :label-width="'120px'" prop="categoryId" v-if="publicForm.isPublic == 1">
                        <!-- <type-select
              :value="publicForm.categoryId"
              @selected="handleTypeSelect"
            ></type-select> -->
                        <el-select v-model="publicForm.categoryId" placeholder="按作品领域" style="width: 100%">
                            <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="描述" :label-width="'120px'" prop="comment">
                        <el-input maxlength="255" v-model="publicForm.comment" placeholder="请输入" type="textarea" show-word-limit></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="私有" :label-width="'120px'" v-if="isPrivate">
            <el-switch
              v-model="publicForm.isPublic"
              active-color="#8565f6"
              inactive-color="#ff4949"
              :active-value="0"
              :inactive-value="1"
              @change="handleSwitchFolderStatus"
            >
            </el-switch>
            <span style="margin-left: 10px">开启后，仅自己和共享者可见</span>
          </el-form-item> -->
                </el-form>
                <div class="footer-box">
                    <el-button @click="handleCancel" class="cancel-btn">取消</el-button>
                    <el-button type="primary" @click="handleSubmit" class="confirm-btn">创建作品集</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import TypeSelect from "./typeSelect.vue";
import Clipboard from "clipboard";
import modelUpload from "@/page/home/component/modelUpload";
export default {
    components: {
        TypeSelect,
        modelUpload
    },
    data() {
        return {
            SUCCESS_CODE: 0,
            current: 0,
            isCollapse: false, // 是否折叠我的作品菜单
            isPublicCollapseTree: false, // 是否折叠我的公开作品
            isShareItWithCollapseTree: false, // 是否折叠共享给我的作品
            isIShareItWithCollapseTree: false, //是否折叠我共享的作品
            isCollapseTree: false, // 是否折叠树菜单
            isDesignerCollapse: false, // 是否折叠设计师菜单
            searchVal: "", // 搜索关键字
            folderList: [], // 文件夹列表
            folderName: "", // 新建文件夹名称
            folderFlag: false, // 新建folder弹框控制
            defaultProps: {
                children: "child",
                label: "name"
            },
            currentNodeId: "", // 当前节点id
            currentNode: null, // 当前节点
            currentRename: "", // 当前重命名名称
            folderLoading: false,
            publicFolderList: [], // 公开作品集
            publicFolderLoading: false,
            dialogVisible: false, // 创建作品集弹框控制
            dialogTitle: "创建公开作品集",
            isPrivate: false, // 是否是私有文件夹弹框
            isChangeToPublic: false, // 分享到社区时，禁用修改名称
            formRules: {},
            publicForm: {
                name: "", // 文件夹名称
                categoryId: "", // 分类id
                comment: "", // 描述
                isPublic: 0 // 公开是1，不公开是0
            }, // 作品集表单
            publicRules: {
                name: [{ required: true, message: "请输入作品集名称", trigger: "blur" }],
                categoryId: [{ required: true, message: "请选择作品集类型", trigger: "change" }]
            },
            privateRules: {
                name: [{ required: true, message: "请输入作品集名称", trigger: "blur" }]
            },
            options: [],
            shareItWithMeFolderOptions: [], //共享给我的作品文件夹列表
            iShareItWithMeFolderOptions: [], //我共享的作品文件夹列表
            activeChildrenId: null, //我的作品集下面的id
            publicNodeClickId: null, //我的公开作品集下面的id
            shareItWithMeNodeClickId: null, //共享给我的作品集下面的id
            iShareItWithMeNodeClickId: null //我的共享的作品集下面的id
        };
    },
    created() {
        this.getTypeOptions();
        // 获取公开和私有文件夹列表
        this.getTreeFolder(true);
        this.getTreeFolder(false);
        this.getShareItWithMeFolder();
        let current = this.$route.query.current;
        if (current) {
            this.initMenu(current);
        } else {
            this.initMenu(0);
        }
    },
    mounted() {
        EventBus.$on("toggleBar", () => {
            if (this.current == 2) {
                let data = this.$refs.folderTree1.getCurrentNode();
                this.handleNodeClick(data);
            } else if (this.current == 7) {
                let data = this.$refs.folderTree2.getCurrentNode();
                this.handlePublicNodeClick(data);
            } else {
                this.handleMenuClick(this.current);
            }
        });
        EventBus.$on("setCurrent", data => {
            // this.handleMenuClick(this.current);
            this.current = data;
            this.$store.commit("setCurrentMenu", data);
        });
        EventBus.$on("cancelFolderSelected", () => {
            this.$nextTick(() => {
                if (this.$refs.folderTree1) {
                    this.$refs.folderTree1.setCurrentKey(null);
                }
                if (this.$refs.folderTree2) {
                    this.$refs.folderTree2.setCurrentKey(null);
                }
                if (this.$refs.folderTree3) {
                    this.$refs.folderTree3.setCurrentKey(null);
                }
                if (this.$refs.folderTree4) {
                    this.$refs.folderTree4.setCurrentKey(null);
                }
            });
        });
    },
    methods: {
        //获取共享给我的作品文件夹列表
        getShareItWithMeFolder() {
            this.$http
                .get("/share-info/directory")
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        try {
                            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                            const id = userInfo.id;
                            const I = [];
                            const O = [];
                            res.data.forEach(item => {
                                if (item.userId === id) {
                                    I.push(item);
                                } else {
                                    O.push(item);
                                }
                            });
                            this.shareItWithMeFolderOptions = O;
                            this.iShareItWithMeFolderOptions = I;
                        } catch (error) {
                            console.error("getShareItWithMeFolder error", error);
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        //   获取类型options
        getTypeOptions() {
            this.$http
                .get("/dict-category/list")
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        this.options = res.data;
                    }
                })
                .catch(err => {});
        },
        // 筛选文件夹
        screenTreeFolder() {
            this.getTreeFolder(true);
            this.getTreeFolder(false);
        },
        // 获取树状文件夹(isPublic为true为公开文件夹，否则为私有文件夹)
        getTreeFolder(isPublic) {
            if (isPublic) {
                this.publicFolderLoading = true;
            } else {
                this.folderLoading = true;
            }
            let params = { search: this.searchVal, isPublic };
            this.$http
                .get("/directory-info/nodes", { params })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        if (isPublic) {
                            // 公开文件夹
                            this.publicFolderList = res.data;
                        } else {
                            // 私有文件夹
                            this.folderList = res.data;
                        }
                    }
                    this.folderLoading = false;
                    this.publicFolderLoading = false;
                })
                .catch(err => {
                    this.folderList = [];
                    this.publicFolderList = [];
                    this.folderLoading = false;
                });
        },
        // 展开/折叠我的作品(菜单)
        handleCollapse() {
            this.isCollapse = !this.isCollapse;
        },
        // 展开/折叠我是设计师(菜单)
        handleDesignerCollapse() {
            this.isDesignerCollapse = !this.isDesignerCollapse;
        },
        handleMenuClick(current) {
            this.publicNodeClickId = null;
            this.activeChildrenId = null;
            this.shareItWithMeNodeClickId = null;
            // console.log(current);
            // 0全部 1未分类 3回收站 4共享给我 5我共享的 6下架作品 8模型 9收藏的作品 10收藏的模型
            if (this.current === current) return;
            this.current = current;
            this.$store.commit("setCurrentMenu", current);
            if (this.$route.query && this.$route.query.id) {
                this.$router.replace({ query: {} });
                this.initMenu(current);
            } else {
                this.initMenu(current);
            }
        },
        initMenu(current) {
            this.$emit("getMenuIndex", current);
            if (this.$refs.folderTree1) {
                this.$refs.folderTree1.setCurrentKey(null);
            }
            if (this.$refs.folderTree2) {
                this.$refs.folderTree2.setCurrentKey(null);
            }
            if (this.$refs.folderTree3) {
                this.$refs.folderTree3.setCurrentKey(null);
            }
            if (this.$refs.folderTree4) {
                this.$refs.folderTree4.setCurrentKey(null);
            }
            switch (current) {
                case 0:
                    EventBus.$emit("toggleToolBar", 0);
                    break;
                case 1:
                    EventBus.$emit("toggleToolBar", 99);
                    break;
                case 2:
                    EventBus.$emit("toggleToolBar", 99);
                    break;
                case 3:
                    EventBus.$emit("toggleToolBar", 2);
                    break;
                case 4:
                    EventBus.$emit("toggleToolBar", 3);
                    break;
                case 5:
                    EventBus.$emit("toggleToolBar", 4);
                    break;
                case 6:
                    EventBus.$emit("toggleToolBar", 5);
                    break;
                case 8:
                    // 我的模型
                    EventBus.$emit("toggleToolBar", 8);
                    break;
                case 9:
                    // 我的模型
                    EventBus.$emit("toggleToolBar", 9);
                    break;
                case 10:
                    // 我的模型
                    EventBus.$emit("toggleToolBar", 10);
                    break;
                default:
                    EventBus.$emit("toggleToolBar", 99);
                    break;
            }
            EventBus.$emit("clearParams", null);
            EventBus.$emit("disabledUpdate", current);
            // if (current != 2 && current != 6) {
            this.$emit("screenImage", { menuSelect: current });
            // }
        },
        handleDesignerMenuClick(current) {
            this.current = current;
            this.$store.commit("setCurrentMenu", current);
            this.$emit("getMenuIndex", current);
            // 隐藏筛选工具栏
            EventBus.$emit("hiddenToolBar", null);
        },
        //我共享的作品
        handleIShareItWhiteNodeClick(data) {
            if (data.id === this.iShareItWithMeNodeClickId) return;
            this.$refs.folderTree1 && this.$refs.folderTree1.setCurrentKey(null);
            this.$refs.folderTree2 && this.$refs.folderTree2.setCurrentKey(null);
            this.$refs.folderTree3 && this.$refs.folderTree3.setCurrentKey(null);
            this.publicNodeClickId = null;
            this.activeChildrenId = null;
            this.shareItWithMeNodeClickId = null;
            this.iShareItWithMeNodeClickId = data.id;
            this.current = 5;
            this.$store.commit("setCurrentMenu", 5);
            EventBus.$emit("disabledUpdate", this.current);
            EventBus.$emit("clearParams");
            this.$emit("clickFolder", data, 5);
        },
        //共享给我的作品文件夹节点点击
        handleShareItWhiteNodeClick(data) {
            if (data.id === this.shareItWithMeNodeClickId) return;
            this.$refs.folderTree1 && this.$refs.folderTree1.setCurrentKey(null);
            this.$refs.folderTree2 && this.$refs.folderTree2.setCurrentKey(null);
            this.$refs.folderTree4 && this.$refs.folderTree4.setCurrentKey(null);
            this.publicNodeClickId = null;
            this.activeChildrenId = null;
            this.iShareItWithMeNodeClickId = null;
            this.shareItWithMeNodeClickId = data.id;
            this.current = 4;
            this.$store.commit("setCurrentMenu", 4);
            EventBus.$emit("disabledUpdate", this.current);
            EventBus.$emit("clearParams");
            this.$emit("clickFolder", data, 4);
        },
        // 私有图集树节点点击
        handleNodeClick(data) {
            console.log(data);
            if (data.id === this.activeChildrenId) return;
            this.publicNodeClickId = null;
            this.shareItWithMeNodeClickId = null;
            this.iShareItWithMeNodeClickId = null;
            this.activeChildrenId = data.id;
            this.current = 2;
            this.$store.commit("setCurrentMenu", 2);
            EventBus.$emit("disabledUpdate", this.current);
            if (this.$refs.folderTree2) {
                this.$refs.folderTree2.setCurrentKey(null);
            }
            if (this.$refs.folderTree3) {
                this.$refs.folderTree3.setCurrentKey(null);
            }
            if (this.$refs.folderTree4) {
                this.$refs.folderTree4.setCurrentKey(null);
            }
            EventBus.$emit("clearParams");
            this.$emit("clickFolder", data, 2);
        },
        // 公开图集树节点点击
        handlePublicNodeClick(data) {
            if (this.publicNodeClickId === data.id) return;
            this.activeChildrenId = null;
            this.shareItWithMeNodeClickId = null;
            this.iShareItWithMeNodeClickId = null;
            this.publicNodeClickId = data.id;
            this.current = 7;
            this.$store.commit("setCurrentMenu", 7);
            EventBus.$emit("disabledUpdate", this.current);
            if (this.$refs.folderTree1) {
                this.$refs.folderTree1.setCurrentKey(null);
            }
            if (this.$refs.folderTree3) {
                this.$refs.folderTree3.setCurrentKey(null);
            }
            if (this.$refs.folderTree4) {
                this.$refs.folderTree4.setCurrentKey(null);
            }
            EventBus.$emit("clearParams");
            this.$emit("clickFolder", data, 7);
        },

        allowDrop(draggingNode, dropNode, type) {
            return type !== "inner";
        },
        // 私有图集节点拖拽
        handleDrop(draggingNode, dropNode, dropType, ev) {
            if (dropType == "inner") {
                return;
            } else {
                console.log(this.folderList);
                this.directorySequence(this.folderList);
                // draggingNode.data.parentId = dropNode.data.parentId;
            }
        },
        // 公开图集节点拖拽
        handlePublicNodeDrop(draggingNode, dropNode, dropType, ev) {
            if (dropType == "inner") {
                return;
            } else {
                console.log(this.publicFolderList);
                this.directorySequence(this.publicFolderList);
                // draggingNode.data.parentId = dropNode.data.parentId;
            }
        },
        // 文件夹排序
        directorySequence(data) {
            let ids = data.map(item => item.id).join();
            this.$http.post("/directory-info/sequence", null, { params: { ids } }).then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    // 更新后请求列表
                    // this.getTreeFolder(true);
                    // this.getTreeFolder(false);
                }
            });
        },
        // 保存文件夹
        saveFolder(data, parentData, isPublic) {
            this.$http.post("/directory-info/save", data).then(res => {
                console.log(res);
                if (res.code == this.SUCCESS_CODE) {
                    // 更新其他页面的文件夹列表
                    EventBus.$emit("updateFolderList");
                    if (isPublic) {
                        this.getTreeFolder(true);
                    } else {
                        this.getTreeFolder(false);
                    }
                    // if (!parentData) {
                    //   console.log(res.data);
                    //   // 根节点添加子节点
                    //   if (isPublic == 1) {
                    //     this.publicFolderList.push(res.data);
                    //   } else {
                    //     this.folderList.push(res.data);
                    //   }
                    // } else {
                    //   // 父节点添加子节点
                    //   if (!parentData.child) {
                    //     this.$set(parentData, "child", []);
                    //   }
                    //   parentData.child.push(res.data);
                    //   this.$nextTick(() => {
                    //     this.handleRenameFolder(data, isPublic);
                    //   });
                    // }
                }
            });
        },
        // 添加文件夹
        handleAddFolder() {
            let folderObj = {
                name: this.folderName,
                parentId: -1
            };
            this.saveFolder(folderObj, null);
        },

        // 新建子文件夹
        handleAddSubFolder(data) {
            console.log(data);
            const newChild = {
                parentId: data.id,
                name: "未命名作品集",
                child: []
            };
            this.saveFolder(newChild, data);
        },
        // 更新节点
        updateNode(data) {
            this.$http.post("/directory-info/update", data).then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    EventBus.$emit("updateFolderList");
                    this.$message.success("修改成功！");
                    // 更新文件夹列表
                    this.getTreeFolder(true);
                    this.getTreeFolder(false);
                }
            });
        },
        // 重命名文件夹
        handleRenameFolder(data, flag) {
            console.log(data);
            this.currentRename = data.name;
            this.currentNodeId = data.id;
            if (flag) {
                // 文本框聚焦
                this.$nextTick(() => {
                    this.currentNode = this.$refs.folderTree2.getNode(data);
                    this.currentNode.data.isPublic = 1;
                    this.$refs.renameInput2.focus();
                });
            } else {
                // 文本框聚焦
                this.$nextTick(() => {
                    this.currentNode = this.$refs.folderTree1.getNode(data);
                    this.currentNode.data.isPublic = 0;
                    this.$refs.renameInput1.focus();
                });
            }
        },
        // 重命名
        handleRename(event) {
            console.log(event);
            this.currentNodeId = "";
            this.currentNode.data.name = this.currentRename ? this.currentRename : "未命名作品集";
            console.log(this.currentNode);
            this.updateNode(this.currentNode.data);
        },
        handleShareLink(data, e) {
            let linkUrl = window.location.origin + window.location.pathname + "#/community/collection/" + data.id;
            let clipboard = new Clipboard(e.target, { text: () => linkUrl });
            clipboard.on("success", () => {
                this.$message.success("已复制分享链接");
                // 释放内存
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                console.log("复制失败", e);
                // 释放内存
                clipboard.destroy();
            });
            clipboard.onClick(e);
        },
        // 删除文件夹
        handleDeleteFolder(node, data) {
            console.log(node);
            this.$confirm("此操作将删除作品集及作品集中的资源, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true
            }).then(() => {
                let params = { isConfirm: true };
                this.$http
                    .delete("/directory-info/" + data.id, { params })
                    .then(res => {
                        if (res.code == this.SUCCESS_CODE) {
                            EventBus.$emit("updateFolderList");
                            const parent = node.parent;
                            const children = parent.data.child || parent.data;
                            const index = children.findIndex(d => d.id === data.id);
                            children.splice(index, 1);
                            this.handleMenuClick(0);
                        }
                    })
                    .catch(err => {
                        this.$message.error("删除失败！");
                    });
            });
        },
        handleUploadModel(directoryId) {
            this.$store.commit("setUploadModelDirecotry", directoryId);
        },
        // 克隆文件夹
        handleCloneFolder(data) {
            console.log(data);
        },
        // 共享文件夹
        handleShareFolder(data) {
            console.log(data);
            this.$emit("shareWork", data, 2);
        },
        // 切换文件夹状态（公开/私有）
        handleSwitchFolderStatus(flag) {
            if (flag == 0) {
                this.formRules = Object.assign({}, this.privateRules);
            } else {
                this.formRules = Object.assign({}, this.publicRules);
            }
            this.$refs.publicForm.clearValidate();
        },
        // 添加公开作品集
        handleAddPublicFolder(data) {
            if (data) {
                this.isChangeToPublic = true;
                this.publicForm = Object.assign({}, data);
                this.dialogTitle = "分享到社区";
            } else {
                this.isChangeToPublic = false;
                this.dialogTitle = "创建公开作品集";
            }
            this.isPrivate = false;
            this.formRules = Object.assign({}, this.publicRules);
            this.publicForm.isPublic = 1;

            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.publicForm.clearValidate();
            });
        },
        // 添加私有作品集
        handleAddPrivateFolder() {
            console.log(this.publicForm);
            this.isPrivate = true;
            this.isChangeToPublic = false;
            this.formRules = Object.assign({}, this.privateRules);
            this.publicForm.isPublic = 0;
            this.dialogTitle = "创建私有作品集";
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.$refs.publicForm.clearValidate();
            });
        },
        // 创建文件夹提交
        handleSubmit() {
            this.$refs.publicForm.validate(valid => {
                if (valid) {
                    let folderObj = Object.assign({}, this.publicForm);
                    folderObj.parentId = -1;
                    if (this.isChangeToPublic) {
                        // 判断是否是分享至社区
                        this.shareToCommunity(folderObj);
                    } else {
                        // 创建文件夹
                        this.saveFolder(folderObj, null, this.publicForm.isPublic);
                    }
                    this.$refs.publicForm.resetFields();
                    this.dialogVisible = false;
                }
            });
        },
        // 取消创建文件夹
        handleCancel() {
            this.$refs.publicForm.resetFields();
            this.dialogVisible = false;
        },
        // 类型选中
        handleTypeSelect(data) {
            // console.log(data);
            // this.typeName = data;
            this.publicForm.categoryId = data;
        },
        // 设为私有图集
        handleToPrivate(data) {
            this.$confirm("此操作将图集设为私有, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true
            }).then(() => {
                console.log(data);
                // data.isPublic = 0;
                // this.updateNode(data);
                let params = {
                    id: data.id,
                    cid: data.categoryId,
                    comment: data.comment
                };
                this.$http.post("/directory-info/toPrivate", params).then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        // console.log(res);
                        this.$message.success("设为私有成功！");
                        this.getTreeFolder(true);
                        this.getTreeFolder(false);
                    }
                });
            });
        },
        // 分享到社区
        shareToCommunity(data) {
            console.log(data);
            let params = {
                id: data.id,
                cid: data.categoryId,
                comment: data.comment
            };
            this.$http
                .post("/directory-info/toPublic", params)
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        // console.log(res);
                        this.$message.success("分享成功！");
                        this.getTreeFolder(true);
                        this.getTreeFolder(false);
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    this.$message.error(err);
                });
        }
    },
    beforeDestroy() {
        EventBus.$off("toggleBar");
        EventBus.$off("setCurrent");
    }
};
</script>
