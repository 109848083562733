<template>
  <!-- 图片详情弹框 -->
  <div
    class="multiple-image-drawer animated"
    :class="{ slideInRight: show, slideOutRight: !show }"
    v-if="show"
  >
    <div class="div-scroll">
      <div class="property-container">
        <div class="prop-thumbnail mb10">
          <div
            v-for="(item, index) in infoData"
            :key="index"
            class="img-wrapper"
          >
            <el-image
              class="img"
              :src="item.obsThumbUrl || item.obsUrl"
              fit="cover"
            ></el-image>
          </div>

          <!-- <img
            :src="item.obsThumbUrl || item.obsUrl"
            alt=""
            v-for="(item, index) in infoData"
            :key="index"
          /> -->
        </div>
        <div class="prop-img-total">{{ infoData.length }}个作品已选</div>
        <!-- <div class="prop-img-label mb10">
          <div class="div-scroll">
            <ul>
              <li
                class="label-item"
                v-for="(label, index) in labels"
                :key="index"
              >
                <span>{{ label }}</span>
                <i class="el-icon-close"></i>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="prop-img-remark mb10" v-show="!disabled">
          <el-input
            type="textarea"
            placeholder="添加注释"
            :rows="5"
            v-model.trim="comment"
            @change="updateBatch"
          >
          </el-input>
        </div>
        <div class="prop-img-link mb10" v-show="!disabled">
          <el-input
            v-model.trim="url"
            placeholder="添加链接"
            @change="updateBatch"
          >
          </el-input>
        </div>
        <div class="prop-img-folder mb10" v-show="!disabled">
          <div class="basic-title">作品集</div>
          <div class="folder-select">
            <el-select
              v-model="directoryId"
              clearable
              placeholder="请选择"
              @change="addToFolder"
              @clear="addToFolder"
            >
              <el-option
                v-for="item in data"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.isPublic == 0 ? "私有" : "公开"
                }}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="prop-img-basic mb10">
          <div class="basic-title">基本信息</div>
          <ul>
            <li>
              <span>作品大小</span>
              <span v-if="size < 1024">{{ size }}KB</span>
              <span v-else>{{ (size / 1024).toFixed(2) }}MB</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      size: 0,
      labels: [], // 标签列表
      folderList: [], // 文件夹列表
      data: [],
      defaultProps: {
        children: "child",
        label: "name",
      },
      comment: "",
      url: "",
      directoryId: "",
      disabled: false,
    };
  },
  props: {
    infoData: {
      type: Array,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    infoData: {
      handler(val) {
        console.log(val);
        let size = 0;
        this.directoryId = "";
        val.forEach((item) => {
          size += Number(item.size);
        });
        this.url = ""
        this.size = size.toFixed(2);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getTreeFolder();
  },
  mounted() {
    EventBus.$on("updateFolderList", () => {
      this.getTreeFolder();
    });
    EventBus.$on("disabledUpdate", (data) => {
      if (data == 4) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    });
  },
  methods: {
    handleCheckChange(data, node) {
      console.log(data);
      this.$refs.folderTree.setCheckedKeys([]);
      this.$refs.folderTree.setCheckedKeys([data.id]);
      this.addToFolder(data);
    },
    handleClose(data) {
      // this.folderList.splice(index, 1);
      this.addToFolder({});
    },
    // 添加至文件夹
    addToFolder() {
      let rIds = this.infoData.map((item) => item.id).join();
      let params = {
        rIds,
        dId: this.directoryId,
      };
      this.$http
        .post("/resource-info/addToDirectory", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            // console.log(res);
            this.$parent.modifyMultiplelFolder();
            this.$parent.getData(true);
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error("操作失败！");
        });
    },
    // 获取文件夹数
    getTreeFolder() {
      this.$http
        .get("/directory-info/nodes")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          this.data = [];
        });
    },
    // 批量修改文件
    updateBatch() {
      let rIds = this.infoData.map((item) => item.id).join();
      this.$http
        .post("/resource-info/updateBatch", null, { params: { rIds,comment: this.comment,
        url: this.url, } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.data);
          } else {
            this.$message.error("修改失败");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败");
        });
    },
  },
  beforeDestroy() {
    EventBus.$off("updateFolderList");
  },
};
</script>

<style lang="less">
.multiple-image-drawer {
  width: 240px;
  height: 100%;
  // padding: 10px;
  padding: 15px 0;
  // margin-left: 20px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  .div-scroll {
    overflow-x: hidden;
  }

  .close-btn {
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: 5px;
    transition: all ease 0.38s;

    &:hover {
      transform: rotate(90deg);
      color: #8565f6;
    }
  }

  .el-drawer__body {
    background-color: #f5f6fa;
  }

  .property-container {
    width: 240px;
    // height: 100%;
    padding: 0 20px;
    border-radius: 4px;

    .el-input__inner,
    .el-textarea__inner {
      background-color: #f5f6fa;
      color: #464646;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
    }
    .prop-type-title {
      padding: 10px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #505050;
      .el-divider--vertical {
        // height: 13px;
        background-color: #d3d3d3;
      }
    }

    .prop-thumbnail {
      padding: 10px;
      position: relative;
      width: 200px;
      height: 200px;
      overflow: hidden;
      .img-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;

        .img {
          max-width: 80%;
          max-height: 80%;
          box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
          // box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
          // box-shadow: 0px 0px 3px 0px rgba(95, 95, 95, 0.31);
        }
        &:nth-child(2) {
          transform: translate(10px, 10px);
        }
        &:nth-child(n + 3) {
          transform: translate(20px, 20px);
        }
      }
      // > img {
      //   position: absolute;
      //   left: 50%;
      //   top: 50%;
      //   max-width: 80%;
      //   max-height: 80%;
      //   transform-origin: center;
      //   transform: translate(-50%,-50%);
      //   &:nth-child(even) {
      //     transform: translate(-50%, -50%) rotate(10deg);
      //   }
      //   &:nth-child(odd) {
      //     transform: translate(-50%, -50%) rotate(-10deg);
      //   }
      // }
    }
    .prop-img-total {
      padding-top: 30px;
      padding-bottom: 20px;
      text-align: center;
    }
    .prop-img-label {
      height: 100px;
      background-color: #e8e9f0;
      padding: 8px;

      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .label-item {
          padding: 5px 10px;
          margin-right: 5px;
          margin-bottom: 5px;
          border-radius: 10px;
          font-size: 12px;
          color: #464646;
          cursor: pointer;
          background-color: #fff;

          > span {
            margin-right: 8px;
          }
        }
      }
    }
    .prop-img-folder {
      padding-top: 10px;
      .basic-title {
        font-size: 12px;
        color: #505050;
      }
      .folder-select {
        padding: 5px 0;
        .el-input__icon {
          line-height: 30px !important;
        }
      }
      .folder-list {
        padding: 10px 0;
        color: #8c8c8c;
        font-size: 12px;
        .el-tag {
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .folder-add {
          cursor: pointer;
        }
        .el-icon-circle-plus {
          font-size: 14px;
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }

    .prop-img-basic {
      padding-top: 10px;

      .basic-title {
        font-size: 12px;
        color: #505050;
      }

      > ul {
        li {
          line-height: 26px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #8c8c8c;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
