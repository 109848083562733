export const listA = [
    {
        id: 4,
        name: '共享给我的作品',
        url: 'work-shareTo',
        // icon: 'icon-xueshengguanli',
        type: 0,
        children: [
            // {
            //     id: '4-1',
            //     name: '走廊',
            //     url: '/share/corridor',
            //     type: 1,
            //     children: [],
            // },
        ]
    },
    {
        id: 6,
        name: '我共享的作品',
        url: 'work-toShare',
        // icon: 'icon-kebiaoguanli',
        type: 0,
        children: [
            // {
            //     id: '3-1',
            //     name: '我共享的作品',
            //     url: '/sharing',
            //     type: 1,
            //     children: [],
            // },
        ]
    },
    // {
    //     id: 3,
    //     name: '我的公开作品',
    //     url: '/public',
    //     // icon: 'icon-kebiaoguanli',
    //     type: 0,
    //     children: [
    //         {
    //             id: '3-1',
    //             name: '我的公开作品',
    //             url: '/public',
    //             type: 1,
    //             children: [],
    //         },
    //     ]
    // },
    // {
    //     id: 2,
    //     name: '智能文件夹',
    //     url: '/smartFolder',
    //     // icon: 'icon-susheguanli',
    //     type: 0,
    //     children: [
    //         {
    //             id: '2-1',
    //             name: '智能文件夹',
    //             url: '/smartFolder',
    //             type: 1,
    //             children: [],
    //         },
    //     ],
    // },
    // {
    //     id: 5,
    //     name: '我是设计师',
    //     url: '/designer',
    //     // icon: 'icon-chengjiguanli',
    //     type: 0,
    //     children: [
    //         {
    //             id: '5-1',
    //             name: '我的关注',
    //             url: '/designer/follow',
    //             type: 1,
    //             children: [],
    //         },
    //         {
    //             id: '5-2',
    //             name: '我的粉丝',
    //             url: '/designer/fans',
    //             type: 1,
    //             children: [],
    //         },
    //         {
    //             id: '5-3',
    //             name: '我的积分',
    //             url: '/designer/integral',
    //             type: 1,
    //             children: [],
    //         },
    //         {
    //             id: '5-4',
    //             name: '我的下载',
    //             url: '/designer/download',
    //             type: 1,
    //             children: [],
    //         },
    //         {
    //             id: '5-5',
    //             name: '我的点赞',
    //             url: '/designer/thumbsup',
    //             type: 1,
    //             children: [],
    //         },
    //     ],
    // },
]


