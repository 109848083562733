<template>
  <!-- <el-dialog
    title="上传模型封面"
    append-to-body
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :show-close="true"
    custom-class="info-dialog"
    :before-close="beforeClose"
    width="40%"
  > -->
  <div>
    <div class="content-upload" v-loading="loading">
      <el-upload
        class="upload-model-box"
        :class="{ hidden: obsThumbUrl }"
        :headers="uploadHeaders"
        :multiple="false"
        :limit="1"
        action=""
        :on-error="handleImageError"
        :before-upload="beforeImageUpload"
        :http-request="customImageUpload"
        :on-remove="handleRemove"
        :on-exceed="handleExceed"
        :file-list="imgList"
        :disabled="uploading"
        accept="image/jpeg,image/jpg,image/png,image/bmp"
        list-type="picture-card"
      >
        <i class="el-icon-plus" slot="default"></i>
        <div class="el-upload__tip" slot="tip" style="text-align: center">
          上传模型封面
        </div>
      </el-upload>
    </div>
    <div style="text-align: right; padding-top: 20px">
      <el-button
        key="back"
        type="primary"
        @click="updatersource"
        :loading="loading"
        :disabled="obsThumbUrl == ''"
      >
        确定
      </el-button>
    </div>
  </div>

  <!-- </el-dialog> -->
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  inject: ["model"],
  props: {
    did: Number,
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    modelInfo: {
      type: Object,
    },
  },
  watch:{
    modelInfo:{
      handler(val){
        if(val.obsUrl){
          this.obsUrl = val.obsUrl;
          this.obsThumbUrl = val.obsThumbUrl;
          this.imgList = [{
            name:val.name,
            url:val.obsUrl
          }]
        }
      },
      deep:true,
      immediate:true,
    }
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      uploadHeaders: {
        token: localStorage.getItem("token"),
      },
      imgList: [],
      uploading: false,
      loading: false,
      obsUrl: "",
      obsThumbUrl: "",
      //   dialogVisible: false,
    };
  },
  created() {},
  methods: {
    beforeImageUpload(file) {
      console.log(file);
    },
    handleImageError(err, file, fileList) {
      console.log(err);
    },
    handleRemove(file) {
      this.imgList = [];
      this.obsThumbUrl = "";
    },
    handleExceed(files, fileList) {
      console.log("exceed");
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeClose() {
      this.imgList = [];
      this.obsUrl = "";
      this.obsThumbUrl = "";
      //   done();
    },
    customImageUpload(e) {
      let self = this;
      var file = e.file;
      var key = uuidv4() + file.name;
      self.obsUpload(e.file, key, null);
    },
    // obs上传图片
    obsUpload(file, key, callback) {
      let self = this;
      this.uploading = true;
      var ak = self.$OBS_AK;
      var sk = self.$OBS_SK;
      var server = self.$OBS_SERVER;
      var bucket = self.$OBS_BUCKET;
      let prefix = self.$OBS_PREFIX;
      var obsClient = new ObsClient({
        access_key_id: ak,
        secret_access_key: sk,
        server: server,
        timeout: 60 * 5,
      });
      // put objects
      obsClient
        .putObject({
          Bucket: bucket,
          Key: key,
          Metadata: { property: "property-value" },
          SourceFile: file,
        })
        .then(function (result) {
          if (result.CommonMsg.Status == 200) {
            // 上传成功后，关闭上传界面，打开修改名称弹框
            callback && callback(file, key);
            let obsUrl = prefix + key;
            let thumbSrc =
              obsUrl + "?x-image-process=image/resize,w_210,limit_0";
            console.log(thumbSrc);
            self.obsUrl = obsUrl;
            self.obsThumbUrl = thumbSrc;
          }
          self.uploading = false;
        })
        .catch(function (err) {
          self.uploading = false;
          console.log(err);
        });
    },
    // 更新缩略图属性
    updatersource() {
      this.loading = true;
      if (this.obsUrl && this.obsThumbUrl) {
        this.modelInfo.obsUrl = this.obsUrl;
        this.modelInfo.obsThumbUrl = this.obsThumbUrl;
        this.$http
          .post("/resource-info/update", this.modelInfo)
          .then((res) => {
            if (res.code == this.SUCCESS_CODE) {
              // 清空列表
              this.beforeClose();
              //   this.$emit("update:dialogVisible", false);
              this.model.uploadCoverShow = false;
              this.model.getData(true);
            }
            this.$message.success(res.msg);
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error("修改失败！" + err);
          });
      }
    },
  },
};
</script>

<style scoped lang="less">
.info-dialog {
  .step-container {
    width: 80%;
    padding: 20px 50px;
    margin: 0 auto;
  }
}
.content-upload {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 200px;
  .cover {
    width: 100%;
    height: 100%;
  }
  .upload-model-box {
    &.hidden {
      /deep/ .el-upload {
        display: none;
      }
    }
  }
}
</style>
