<template>
  <div id="signal" class="signal-image" @mousewheel="bbimg($event)">
    <img
      id="signalImage"
      border="0"
      :src="imgSrc"
      ref="signalImage"
      @load="imgLoad"
    />
    <!-- 上一张、下一张按钮 -->
    <i
      class="el-icon-arrow-left img-opt img-opt-pre"
      v-if="imgInfo.prevId"
      @click="goto(imgInfo.prevId)"
    ></i>
    <i
      class="el-icon-arrow-right img-opt img-opt-next"
      v-if="imgInfo.nextId"
      @click="gotoNext(imgInfo.nextId)"
    ></i>
  </div>
</template>

<script>
import merge from "webpack-merge";
import { mapActions, mapGetters } from "vuex"
export default {
  props: {
    imgSrc: String,
    imgInfo: Object,
  },
  watch: {
    imgSrc: {
      handler(val) {},
      deep: true,
      immediate: true,
    },
    imgInfo: {
      handler(val) {},
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      params: {
        zoomVal: 1,
        left: 0,
        top: 0,
        currentX: 0,
        currentY: 0,
        flag: false,
        imgId: "img",
      },
    };
  },
  computed:{
    ...mapGetters("collectionSidebar",["previewIsLatestItem"])
  },
  mounted() {
    // this.startDrag(
    //   document.getElementById("signalImg"),
    //   document.getElementById("signalImg")
    // );
  },
  methods: {
    ...mapActions("collectionSidebar",["loadMoreCollectionData"]),
    imgLoad() {
      this.$nextTick(() => {
        this.startDrag(
          document.getElementById("signalImage"),
          document.getElementById("signalImage")
        );
      });
    },
    // 初始化图片位置，解决初次抖动问题
    initPosition() {
      let wrapper = document.getElementById("signal");
      let img = document.getElementById("signalImage");
      let wrapperWidth = wrapper.clientWidth;
      let wrapperHeight = wrapper.clientHeight;
      let imgWidth = img.clientWidth;
      let imgHeight = img.clientHeight;
      // console.log(wrapperWidth, wrapperHeight);
      // console.log(imgWidth, imgHeight);
      if (imgWidth < wrapperWidth) {
        img.style.left = (wrapperWidth - imgWidth) / 2 + "px";
      } else {
        img.style.left = 0 + "px";
      }
      if (imgHeight < wrapperHeight) {
        img.style.top = (wrapperHeight - imgHeight) / 2 + "px";
      } else {
        img.style.top = 0 + "px";
      }
      // 恢复原始缩放
      img.style.transform = "scale(1)";
      // console.log(img.style.top);
      // console.log(img.style.left);
    },
    bbimg(event) {
      //   var o = document.getElementsByTagName("img")[0];
      var o = document.getElementById("signalImage");
      this.params.zoomVal += event.wheelDelta / 1200;
      if (this.params.zoomVal >= 0.2) {
        o.style.transform = "scale(" + this.params.zoomVal + ")";
      } else {
        this.params.zoomVal = 0.2;
        o.style.transform = "scale(" + this.params.zoomVal + ")";
        return false;
      }
    },
    //获取相关CSS属性
    getCss(o, key) {
      return o.currentStyle
        ? o.currentStyle[key]
        : document.defaultView.getComputedStyle(o, false)[key];
    },
    //拖拽的实现
    startDrag(bar, target, callback) {
      this.initPosition();
      let self = this;
      if (this.getCss(target, "left") !== "auto") {
        this.params.left = this.getCss(target, "left");
      }
      if (this.getCss(target, "top") !== "auto") {
        this.params.top = this.getCss(target, "top");
      }
      console.log(this.params);
      //o是移动对象
      bar.onmousedown = function (event) {
        self.params.flag = true;
        if (!event) {
          event = window.event;
          //防止IE文字选中
          bar.onselectstart = function () {
            return false;
          };
        }
        var e = event;
        self.params.currentX = e.clientX;
        self.params.currentY = e.clientY;
      };
      document.onmouseup = function () {
        self.params.flag = false;
        if (self.getCss(target, "left") !== "auto") {
          self.params.left = self.getCss(target, "left");
        }
        if (self.getCss(target, "top") !== "auto") {
          self.params.top = self.getCss(target, "top");
        }
      };
      document.onmousemove = function (event) {
        var e = event ? event : window.event;
        if (self.params.flag) {
          var nowX = e.clientX,
            nowY = e.clientY;
          var disX = nowX - self.params.currentX,
            disY = nowY - self.params.currentY;
          target.style.left = parseInt(self.params.left) + disX + "px";
          target.style.top = parseInt(self.params.top) + disY + "px";
          if (typeof callback == "function") {
            callback(
              (parseInt(params.left) || 0) + disX,
              (parseInt(params.top) || 0) + disY
            );
          }
          if (event.preventDefault) {
            event.preventDefault();
          }
          return false;
        }
      };
    },
    goto(id) {
      this.$router.push({
        query: merge(this.$route.query, { id: id }),
      });
    },
    /**
     * 跳转到下一个预览项，如果当前预览项是已加载作品集的最后一项，则加载更多作品集
     * @param {number} id 
     */
    gotoNext(id) {
      this.$router.push({
        query: merge(this.$route.query, { id: id }),
      });
      if(!this.previewIsLatestItem) return
      this.loadMoreCollectionData()
    },
  },
};
</script>

<style lang="less">
.signal-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 50px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  > img {
    position: absolute;
    // left: 50px;
    // top: 50px;
    // max-height: 100%;
    max-width: 100%;
    max-height: 100%;
    cursor: move;
  }
  .img-opt {
    position: absolute;
    top: 50%;
    z-index: 999;
    font-size: 36px;
    font-weight: 500;
    color: #333;
    cursor: pointer;
    transform: translateY(-50%);

    &.img-opt-pre {
      left: 20px;
    }

    &.img-opt-next {
      right: 20px;
    }
  }
}
</style>
