<template>
    <!-- 图片详情弹框 -->
    <div class="folder-property-drawer animated" :class="{ slideInRight: show, slideOutRight: !show }" v-if="show">
        <div class="div-scroll">
            <div class="property-container">
                <!-- <div class="prop-img-name mb10">
          <el-input v-model="infoData.label" placeholder="作品集名称">
          </el-input>
        </div> -->
                <!-- <div class="prop-img-remark mb10">
          <el-input
            type="textarea"
            placeholder="添加注释"
            :rows="5"
            v-model="infoData.remark"
          >
          </el-input>
        </div> -->
                <div class="prop-img-basic mb10">
                    <div class="basic-title">基本信息</div>
                    <ul>
                        <li>
                            <span class="basic-label">作品集名称</span>
                            <span class="basic-value">{{ infoData.name }}</span>
                        </li>
                        <li>
                            <span class="basic-label">作品集分类</span>
                            <span class="basic-value">{{ infoData.categoryName }}</span>
                        </li>
                        <li>
                            <span class="basic-label">创建时间</span>
                            <span class="basic-value">{{ infoData.createTime }}</span>
                        </li>
                        <li>
                            <span class="basic-label">作品集状态</span>
                            <span class="basic-value">{{ infoData.isPublic == 0 ? "私有" : "公开" }}</span>
                        </li>
                        <li>
                            <span class="basic-label">作品集描述</span>
                            <span class="basic-value">{{ infoData.comment }}</span>
                        </li>
                        <li>
                            <span class="basic-label">作品总数</span>
                            <span class="basic-value">{{ infoData.count }}</span>
                        </li>
                        <!-- <li style="padding-top: 20px">
              <el-button size="medium" @click="handleShare"
                >共享文件夹</el-button
              >
            </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
    data() {
        return {
            SUCCESS_CODE: 0,
            infoData: {}
        };
    },
    props: {
        // infoData: {
        //   type: Object,
        // },
        id: String,
        menuIndex: [String, Number],
        show: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        // infoData: {
        //   handler(val) {
        //     // console.log(val);
        //   },
        //   deep: true,
        //   immediate: true,
        // },
        id: {
            handler(val) {
                console.log("watch id", val);
                if (val) {
                    this.getDetailInfo(val);

                    // if (this.menuIndex !== 4) {
                    //     this.getDetailInfo(val);

                    // } else {
                    //     this.getDetailInfo2(val);
                    // }
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {},
    methods: {
        handleShare() {
            EventBus.$emit("shareWork", this.infoData, 2);
        },
        // 获取文件夹详情
        getDetailInfo(id) {
            this.$http.get("/directory-info/" + id).then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    this.infoData = res.data;
                }
            });
        }
        // // 获取文件夹详情
        // getDetailInfo2(id) {
        //     this.$http.get("/resource-info/listPage/" + id).then(res => {
        //         if (res.code == this.SUCCESS_CODE) {
        //             this.infoData = res.data;
        //         }
        //     });
        // }
    }
};
</script>

<style lang="less" scoped>
.folder-property-drawer {
    width: 240px;
    height: 100%;
    // padding: 10px;
    padding: 15px 0;
    // margin-left: 20px;
    background-color: #fff;
    position: relative;
    .div-scroll {
        overflow-x: hidden;
    }
    .close-btn {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        top: 0;
        right: 5px;
        transition: all ease 0.38s;

        &:hover {
            transform: rotate(90deg);
            color: #8565f6;
        }
    }

    .el-drawer__body {
        background-color: #fff;
    }

    .property-container {
        width: 240px;
        // height: 100%;
        padding: 0 20px;
        border-radius: 4px;

        .el-input__inner,
        .el-textarea__inner {
            background-color: #f5f6fa;
            color: #464646;
        }
        .el-input__inner {
            height: 30px;
            line-height: 30px;
        }
        .prop-type-title {
            padding: 10px 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #505050;
            .el-divider--vertical {
                // height: 13px;
                background-color: #d3d3d3;
            }
        }

        .prop-thumbnail {
            > img {
                width: 100%;
            }
        }

        .prop-color-card {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .color-caard-item {
                width: 19%;
                height: 15px;
                border-radius: 2px;
            }
        }

        .prop-img-name {
        }

        .prop-img-label {
            height: 100px;
            background-color: #e8e9f0;
            padding: 8px;

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .label-item {
                    padding: 5px 10px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                    border-radius: 10px;
                    font-size: 12px;
                    color: #464646;
                    cursor: pointer;
                    background-color: #fff;

                    > span {
                        margin-right: 8px;
                    }
                }
            }
        }
        .prop-img-folder {
            padding-top: 10px;
            .basic-title {
                font-size: 12px;
                color: #505050;
            }
            .folder-list {
                padding: 10px 0;
                color: #8c8c8c;
                font-size: 12px;
                .el-tag {
                    margin-right: 8px;
                    margin-bottom: 8px;
                }
                .folder-add {
                    cursor: pointer;
                }
                .el-icon-circle-plus {
                    font-size: 14px;
                    margin-right: 8px;
                    vertical-align: middle;
                }
            }
        }
        .prop-img-basic {
            padding-top: 10px;

            .basic-title {
                font-size: 12px;
                color: #505050;
            }

            > ul {
                li {
                    line-height: 26px;
                    display: flex;
                    justify-content: space-between;
                    // align-items: center;
                    color: #8c8c8c;
                    font-size: 12px;
                    .basic-label {
                        width: 70px;
                    }
                    .basic-value {
                        width: calc(100% - 70px);
                        display: flex;
                        justify-content: flex-end;
                        word-break: break-all;
                    }
                }
            }
        }
    }
}
</style>
