<template>
  <div :class="{ 'has-logo': showLogo }" class="sidebar-wrapper">
    <!-- <logo :collapse="isCollapse"/> -->
    <div class="sidebar-box">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu
          class="el-menu-vertical-side"
          :default-active="defActive"
          mode="vertical"
          menu-trigger="click"
          unique-opened
          :collapse="isCollapse"
        >
          <el-submenu index="1" class="custom-submenu">
            <template slot="title">
              <span>我的作品</span>
            </template>
            <el-menu-item index="work-all">
              <div class="menu-item-wrapper">
                <div class="icon-wrapper">
                  <img
                    class="icon-all"
                    src="../../../assets/images/icon/icon-all.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>全部作品</span>
                  <span class="work-num">51</span>
                </div>
              </div>
            </el-menu-item>
            <el-menu-item index="works-unclassified">
              <div class="menu-item-wrapper">
                <div class="icon-wrapper">
                  <img
                    class="icon-unclassified"
                    src="../../../assets/images/icon/icon_weifenlei@2x.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>未分类作品</span>
                  <span class="work-num">10</span>
                </div>
              </div>
            </el-menu-item>
            <!-- <el-menu-item index="/works/allLabels">
              <div class="icon-wrapper">
                <img
                  class="icon-allLabels"
                  src="../../../assets/images/icon/icon_quanbubiaoqian@2x.png"
                  alt=""
                />
              </div>
              <span>全部标签</span>
              <span class="work-num">51</span>
            </el-menu-item> -->
            <!-- <el-menu-item index="/works/unlabeled">
              <div class="icon-wrapper">
                <img
                  class="icon-unlabeled"
                  src="../../../assets/images/icon/icon_weibiaoqian@2x.png"
                  alt=""
                />
              </div>
              <span>未标签作品</span>
              <span class="work-num">51</span>
            </el-menu-item> -->
            <el-submenu class="folder-menu" index="works-folders">
              <div class="menu-item-wrapper" slot="title">
                <div class="icon-wrapper">
                  <img
                    class="icon-folder"
                    src="../../../assets/images/icon/icon_wenjianjia@2x.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>我的文件夹(50)</span>
                  <span class="work-num">
                    <el-popover
                      placement="right"
                      width="240"
                      trigger="click"
                      v-model="folderFlag"
                    >
                      <div class="new-folder-wrapper">
                        <div class="new-title">
                          <span>输入文件夹名称</span>
                          <el-button size="mini" @click="handleAddFolder"
                            >确定</el-button
                          >
                        </div>
                        <el-input
                          placeholder="输入名称"
                          v-model="folderName"
                        ></el-input>
                      </div>
                      <i class="el-icon-plus" @click.stop slot="reference"></i>
                    </el-popover>
                  </span>
                </div>
              </div>
              <el-menu-item
                v-for="(folder, i) in folderList"
                :key="i"
                :index="'work' + folder.id"
              >
                <span> {{ folder.name }} </span>
                <el-popover placement="right-start" trigger="hover">
                  <ul class="gengduo-popover">
                    <li>重命名</li>
                    <li>删除文件夹</li>
                    <li>展开/收起</li>
                    <li>克隆</li>
                    <li>
                      共享 <i class="el-icon-caret-right icon-gongxiang"></i>
                    </li>
                  </ul>
                  <i
                    class="iconfont icon-gengduo"
                    style="float: right; margin-right: 0"
                    slot="reference"
                  ></i>
                </el-popover>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="works-trash">
              <div class="menu-item-wrapper">
                <div class="icon-wrapper">
                  <img
                    class="icon-trash"
                    src="../../../assets/images/icon/icon_trash.png"
                    alt=""
                  />
                </div>
                <div class="text-wrapper">
                  <span>回收站</span>
                  <span class="work-num">51</span>
                </div>
              </div>
            </el-menu-item>
          </el-submenu>
          <sidebar-item
            v-for="(item, n) in menuList"
            :item="item"
            :navIndex="String(n)"
            :key="item.id"
          ></sidebar-item>
        </el-menu>
      </el-scrollbar>
    </div>
    <div class="search-container">
      <el-input
        placeholder="筛选文件夹"
        prefix-icon="el-icon-search"
        v-model="searchVal"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import CForm from "@/components/CForm/index.vue";
import { listA, listB } from "@/assets/json/menuList";
import { mapState, mapActions } from "vuex";
import { Loading } from "element-ui";
import { EventBus } from "@/EventBus";
export default {
  components: { Logo, SidebarItem, CForm },
  data() {
    return {
      SUCCESS_CODE: "",
      showLogo: true,
      userInfo: {},
      userRoleList: [],
      roleName: "",
      dialogFormVisible: false,
      menuList: null,
      handleData: {
        oldPassword: "",
        newPassword: "",
        verifyPassword: "",
      },
      searchVal: "",
      folderList: [], // 文件夹列表
      folderName: "", // 文件夹名称
      folderFlag: false, // folder弹框控制
      defActive: "work-all", // 当前激活菜单
    };
  },
  props: {
    isCollapse: Boolean,
  },
  watch: {
    isCollapse(val) {},
    defActive(val) {
      console.log(val);
    },
  },
  computed: {
    // ...mapState({
    //   menuList: (state) => state.auth.menuList,
    // }),
    // defActive() {
    //   return this.$route.path;
    // },
  },
  created() {
    let self = this;
    this.SUCCESS_CODE = this.$store.state.dic.SUCCESS_CODE;
    this.menuList = listA;
  },
  mounted() {
    // 增加文件夹菜单
    EventBus.$on("addFolderList", (data) => {
      // console.log(data)
      // A发送来的消息
      this.folderList.push(data);
      this.defActive = "work" + data.id;
    });
  },
  methods: {
    ...mapActions({
      initMenu: "auth/initMenu",
    }),
    handleOpen() {},
    handleClose() {},
    // 获取菜单列表
    getMenuList() {
      let roleId = JSON.parse(localStorage.getItem("userInfo")).roleId;
      this.$http
        .get("/account/sys/menu/getByRoleId?roleId=" + roleId)
        .then((res) => {
          console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            this.menuList = res.data;
            console.log(this.menuList);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error("获取失败" + err);
        });
    },
    // 切换角色或退出登录
    handleToggleRole(command) {
      console.log(command);
      if (command == "pwd") {
        // 用户修改密码，跳转到修改密码的页面
        // this.$router.push("/sys/users/modifyPwd");
        // 弹出修改密码弹框
        this.dialogFormVisible = true;
      } else if (command == "exit") {
        // 退出登录，清空localStorage
        localStorage.clear();
        this.$router.replace("/login");
        // 清除vuex中的数据
        this.$store.commit("auth/resetState");
      } else {
        let roleId = command.roleId;
        let roleName = command.roleName == "学生端" ? "" : command.roleName;
        localStorage.setItem("roleId", roleId ? roleId : "");
        localStorage.setItem("roleName", roleName);
        this.$store.commit("auth/resetState");
        window.location.reload();
      }
    },
    handleDialogClose() {
      this.$refs.cForm.$refs.cForm.resetFields();
      this.dialogFormVisible = false;
    },
    resetFields() {
      this.dialogFormVisible = false;
    },
    // 修改密码
    submit() {
      let data = {
        oldPassword: hex_sha1(this.handleData.oldPassword),
        newPassword: hex_sha1(this.handleData.newPassword),
      };
      this.$http
        .post("/account/sys/user/changePwd", data)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success(res.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    // 添加文件夹
    handleAddFolder() {
      let folderObj = {
        name: this.folderName,
        id: new Date().getTime(),
      };
      this.folderList.push(folderObj);
      this.folderFlag = false;
      this.folderName = "";
      this.defActive = "work" + folderObj.id;
    },
  },
  beforeDestroy() {},
};
</script>
