<template>
  <el-submenu v-if="item.type < 1 && item.children && item.children.length" :index="item.url">
    <!-- 创建父级菜单 -->
    <template slot="title">
      <i v-if="item.icon" class="iconfont" :class="item.icon"></i><span>{{ item.name }}</span>
    </template>
    <!-- 创建子菜单 -->
    <sidebar-item v-for="(subItem,i) in item.children" :key="navIndex+'-'+i" :navIndex="navIndex+'-'+i" :item="subItem"></sidebar-item>
  </el-submenu>
  <el-menu-item v-else-if="item.type < 2 " :index="item.url">
    <i v-if="item.icon" class="iconfont" :class="item.icon"></i><span>{{ item.name }}</span>
  </el-menu-item>
</template>

<script>
  export default {
    name: "SidebarItem",
    props: ["item", "navIndex"]
  };
</script>
