<template>
  <div class="model-list-page">
    <div class="table-wrapper">
      <div class="box-table">
        <el-table
          v-loading="pageLoading"
          :element-loading-text="loadingText"
          :data="pageData"
          style="width: 100%"
          height="100%"
        >
          <el-table-column prop="name" label="模型名称" align="center">
          </el-table-column>
          <el-table-column prop="size" label="模型大小(KB)" align="center">
          </el-table-column>
          <el-table-column label="上传模型任务状态" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.row.realiboxJobStatus
                  ? getJobStatus(scope.row.realiboxJobStatus)
                  : "任务中断"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="所属作品集" align="center">
            <template slot-scope="scope">
              <el-select
                class="zuopin"
                v-model="scope.row.directoryId"
                clearable
                placeholder="请选择"
                :disabled="scope.row.realiboxJobStatus != 200"
                @change="(data) => addToFolder(data, scope.row.id)"
                @clear="(data) => addToFolder(data, scope.row.id)"
              >
                <!-- value-key="id" -->
                <!-- <el-option
                  v-for="item in scope.row.realiboxIsPublish == 1
                    ? options1
                    : options2"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                > -->
                <el-option
                  v-for="item in options1"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.isPublic == 0 ? "私有" : "公开"
                  }}</span>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="resourceCount" label="发布状态" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.row.realiboxIsPublish == 1 ? "已发布" : "未发布"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300px">
            <template slot-scope="scope">
              <div class="opt-wrapper">
                <el-button
                  v-if="scope.row.objectId"
                  size="mini"
                  :style="{
                    visibility:
                      scope.row.realiboxJobStatus == 200 &&
                      scope.row.realiboxIsPublish !== 1
                        ? 'inherit'
                        : 'hidden',
                  }"
                  @click="handlePublishV2(scope.row)"
                  :disabled="scope.row.realiboxJobStatus != 200"
                  >手动发布</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  :style="{
                    visibility:
                      scope.row.realiboxJobStatus == 200 &&
                      scope.row.realiboxIsPublish !== 1
                        ? 'inherit'
                        : 'hidden',
                  }"
                  @click="handlePublish(scope.row)"
                  :disabled="scope.row.realiboxJobStatus != 200"
                  >手动发布</el-button
                >

                <!-- <el-button
                  size="mini"
                  @click="handlePreview(scope.row)"
                  :disabled="
                    scope.row.realiboxJobStatus != 200 ||
                    scope.row.realiboxIsPublish != 1
                  "
                  >预览</el-button
                > -->
                <el-button
                  v-if="scope.row.objectId"
                  size="mini"
                  @click="handleShareV2(scope.row, $event)"
                  :disabled="
                    scope.row.realiboxJobStatus != 200 ||
                    scope.row.realiboxIsPublish != 1
                  "
                  >分享</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  @click="handleShare(scope.row)"
                  :disabled="
                    scope.row.realiboxJobStatus != 200 ||
                    scope.row.realiboxIsPublish != 1
                  "
                  >分享</el-button
                >
                <el-button
                  v-if="scope.row.objectId"
                  size="mini"
                  @click="handleDownLoadV2(scope.row)"
                  :disabled="scope.row.realiboxJobStatus != 200"
                  >下载</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  @click="handleDownLoad(scope.row)"
                  :disabled="scope.row.realiboxJobStatus != 200"
                  >下载</el-button
                >
                <el-button
                  size="mini"
                  :loading="delLoadingIds.includes(scope.row.id)"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="info-dialog"
      :before-close="beforeClose"
      width="40%"
    >
      <div class="dialog-title" slot="title">{{ title }}</div>
      <div v-if="dialogType === 'share'" class="dialog-content">
        <div
          class="share-wrapper"
          v-loading="shareLoading"
          element-loading-text="链接生成中，请稍候。。。"
        >
          <div class="share-title">获得链接的所有人，可访问该数据</div>
          <el-form
            :model="shareForm"
            ref="shareForm"
            :rules="shareRules"
            label-width="100px"
            size="small"
          >
            <el-form-item label="">
              <el-checkbox v-model="shareForm.allow" @change="handleCheckChange"
                >需要密码访问</el-checkbox
              >
            </el-form-item>
            <el-form-item label="密码" prop="password" v-if="shareForm.allow">
              <el-input
                v-model="shareForm.password"
                placeholder="请输入四位数字/字母密码"
                >需要密码访问</el-input
              >
            </el-form-item>
          </el-form>
          <div class="share-link-item">
            <el-button plain size="small" @click="handleCreateShreLink"
              >生成共享链接</el-button
            >
          </div>
          <div class="link-wrapper" v-if="shareLink">
            <el-input v-model="shareLink"></el-input>
            <el-button
              type="success"
              class="copy-btn"
              plain
              @click="handleShareLink($event)"
              id="copy"
              >复制链接</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="model-refresh">
      <el-tooltip
        class="item"
        effect="dark"
        content="刷新列表"
        placement="bottom"
      >
        <i class="el-icon-refresh" @click="getPageData(true)"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { isNumSymbol } from "@/libs/checker.js";
import Clipboard from "clipboard";
import { downloadModelV2, new_downloadModel, previewModel } from "@/util/model";
import { mapState } from "vuex";
import { EventBus } from "@/EventBus";
const SUCCESS_CODE = 0;
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value) {
        if (isNumSymbol(value)) {
          callback();
        } else {
          callback(new Error("请输入四位数字/字母密码"));
        }
      } else {
        callback(new Error("请输入密码"));
      }
    };
    return {
      SUCCESS_CODE: 0,
      title: "",
      pageParams: {
        menuSelect: 8,
        total: 0,
        currentPage: 1,
        pageNum: 1,
        pageSize: 10,
      },
      pageData: [],
      pageLoading: false,
      dialogType: "",
      dialogVisible: false,
      shareLink: "",
      shareForm: {
        allow: "",
        password: "",
        sceneId: "",
      },
      shareRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
      },
      shareLoading: false,
      loadingText: "",
      options1: [],
      options2: [],
      delLoadingIds: [], //删除中的记录id，用于启用loading状态
      modelListRefreshTimer: null, //模型列表刷新定时器
    };
  },
  computed: {
    ...mapState("dic", ["_JOB_STATUS"]),
    // 需要定时刷新模型列表状态，当列表存在任务开始（100）/正在处理（120）的模型时为true
    shouldIntervalRefresh() {
      return this.pageData.some((item) => {
        const status = Number(item.realiboxJobStatus);
        return status === 100 || status === 120;
      });
    },
  },
  destroy() {
    clearTimeout(this.modelListRefreshTimer);
  },
  created() {
    this.getPageData(true);
    this.getTreeFolder(); // 获取所有文件夹
    this.getTreeFolder(false); // 获取私有文件夹
    EventBus.$on("refreshModelList", () => {
      console.log("refreshModelList");
      this.getPageData(true);
    });
  },
  methods: {
    /**
     * 刷新模型列表,存在上传中作品时30秒刷新一次
     */
    refreshModelList() {
      clearTimeout(this.modelListRefreshTimer);
      this.modelListRefreshTimer = setTimeout(() => {
        this.getPageData(true);
        if (this.shouldIntervalRefresh) {
          this.refreshModelList();
        }
      }, 30 * 1000);
    },
    getJobStatus(data) {
      if (data) {
        let obj = this._JOB_STATUS.find((item) => item.value == data);
        if (obj && obj.label) {
          return obj.label;
        } else {
          return "等待";
        }
      } else {
        return null;
      }
    },
    beforeClose(done) {
      // this.shareForm = {
      //   allow: "",
      //   password: "",
      //   sceneId: "",
      // };
      // this.shareLink = "";
      // this.$refs.shareForm.resetFields();
      done();
    },
    /**
     * 删除模型
     * @param {{id:number}} item
     */
    async handleDelete(item) {
      const { id: ids } = item;
      try {
        await this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        });
        this.delLoadingIds.push(ids);
        try {
          const ret = await this.$http.delete("/resource-info/deleteBatch", {
            params: {
              ids,
            },
          });
          if (ret.code === SUCCESS_CODE) {
            this.$message.success("删除模型成功");
            this.getPageData();
          } else {
            throw new Error("删除模型失败");
          }
        } catch {
          this.$message.error("删除模型失败");
        }
      } catch {
        console.log("cancel delete");
      } finally {
        this.delLoadingIds = this.delLoadingIds.filter((i) => i !== ids);
      }
    },
    handleShareLink(e) {
      let linkUrl = this.shareLink;
      let clipboard = new Clipboard(e.target, { text: () => linkUrl });
      clipboard.on("success", () => {
        this.$message.success("已复制分享链接");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log("复制失败", e);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    // 获取模型列表
    getPageData(flag) {
      console.log("list" + 123);
      if (flag) {
        this.pageData = [];
        this.pageParams = {
          menuSelect: 8,
          pageNum: 1,
          pageSize: 10,
          total: 0,
          currentPage: 0,
        };
      }
      let params = Object.assign({}, this.pageParams);
      this.pageLoading = true;
      this.$http
        .post("/resource-info/listPage", params)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data && res.data.records) {
              this.pageData = res.data.records;
              this.pageParams.total = res.data.total;
              this.pageParams.currentPage = res.data.current;
              console.log(this.pageData);
              if (this.shouldIntervalRefresh) {
                this.refreshModelList();
              }
            }
          } else {
            this.$message.error("获取数据失败" + res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.$message.error("获取数据失败," + err);
        });
    },
    // 分页
    handlePageChange(current) {
      this.pageParams.currentPage = current;
      this.pageParams.pageNum = current;
      this.getPageData();
    },
    // 预览
    handlePreview(item) {
      previewModel(item.id, item.objectId);
    },
    // 下载
    handleDownLoad(item) {
      let ids = item.id;
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            new_downloadModel(item.realiboxSceneId, item.name);
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.pageLoading = false;
        });
    },
    // 下载零件模型
    handleDownLoadV2(item) {
      let ids = item.id;
      this.$http
        .post("/download/download", null, { params: { ids } })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            downloadModelV2(item.objectId, item.name);
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.pageLoading = false;
        });
    },
    // 发布
    handlePublish(item) {
      this.pageLoading = true;
      this.loadingText = "正在发布中，请稍候。。。";
      this.$http
        .post("/user-job/publish/" + item.realiboxSceneId)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.dialogVisible = true;
            this.title = "发布成功";
            this.dialogType = "publish";
            this.shareForm.sceneId = item.realiboxSceneId;
            this.getPageData(true);
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
          this.loadingText = "";
        })
        .catch((err) => {
          this.pageLoading = false;
          this.loadingText = "";
          this.$message.error("发布失败!" + err);
        });
    },
    //新版发布（开启分享模型）
    handlePublishV2(item) {
      this.pageLoading = true;
      this.loadingText = "正在发布中，请稍候。。。";
      this.$http
        .post("/user-job/public2", { objectId: item.objectId, publicType: 1 })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.dialogVisible = true;
            this.title = "发布成功";
            this.dialogType = "publish";
            this.getPageData(true);
          } else {
            this.$message.error(res.msg);
          }
          this.pageLoading = false;
          this.loadingText = "";
        })
        .catch((err) => {
          this.pageLoading = false;
          this.loadingText = "";
          this.$message.error("发布失败!" + err);
        });
    },
    // 生成共享链接
    handleCreateShreLink() {
      this.$refs.shareForm.validate((valid) => {
        if (valid) {
          let params = {
            password: this.shareForm.password,
            publicType: this.shareForm.allow ? 2 : 1,
            sceneId: this.shareForm.sceneId,
          };
          this.shareLoading = true;
          this.$http
            .post("/user-job/public", params)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.shareLink = res.data;
              } else {
                this.$message.error("生成链接失败！" + res.msg);
                this.shareLoading = false;
              }
              this.shareLoading = false;
            })
            .catch((err) => {
              this.$message.error("生成链接失败！" + err);
              this.shareLoading = false;
            });
        } else {
          return;
        }
      });
    },
    // 分享
    handleShare(item) {
      if (item.realiboxIsPublish != 1) {
        return this.$message.warning("请先发布场景！");
      }
      this.title = "分享";
      this.shareForm.sceneId = item.realiboxSceneId;
      this.dialogVisible = true;
      this.dialogType = "share";
    },
    // 零件模型分享，直接拷贝模型详情页面链接
    handleShareV2(item, e) {
      if (item.realiboxIsPublish != 1) {
        return this.$message.warning("请先发布场景！");
      }
      this.shareLink =
        window.location.protocol +
        "//" +
        window.location.host +
        "/#/model/viewer/" +
        item.id;
      let linkUrl = this.shareLink;
      let clipboard = new Clipboard(e.target, { text: () => linkUrl });
      clipboard.on("success", () => {
        this.$message.success("已复制分享链接");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log("复制失败", e);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.onClick(e);
    },
    handleCheckChange(val) {
      this.shareLink = "";
    },
    // 获取文件夹列表
    getTreeFolder(flag = "all") {
      let params = {};
      if (!flag) {
        params.isPublic = false;
      }
      this.$http
        .get("/directory-info/nodes", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            let data = res.data;
            if (!flag) {
              this.options2 = data;
            } else {
              this.options1 = data;
            }
          }
        })
        .catch((err) => {
          this.options1 = [];
          this.options2 = [];
        });
    },
    // 添加至文件夹
    addToFolder(data, rids) {
      let rIds = rids;
      let params = {
        rIds,
        dId: data,
      };
      this.$http
        .post("/resource-info/addToDirectory", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            console.log(res);
            // this.getPageData(true);
            this.$message.success("修改成功！");
          } else {
            this.$message.error("修改失败！");
          }
        })
        .catch((err) => {
          this.$message.error("修改失败！");
        });
    },
  },
};
</script>

<style lang="less">
.model-list-page {
  width: 100%;
  height: 100%;
  position: relative;
  .table-wrapper {
    height: 100%;
    // padding: 20px 0;

    .operation-wrapper {
      padding: 20px 0;
      display: flex;
      align-items: center;
    }

    .box-table {
      height: calc(100% - 40px);
      border: 1px solid #e6e6e6;

      &.search-table {
        height: calc(100% - 112px);
      }

      .el-table {
        tr {
          th {
            background-color: #f4f4f4;
          }
        }

        &:before {
          height: 0;
        }
      }

      .opt-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .opt-icon {
          i {
            font-size: 22px;
            // font-weight: 700;
            color: #0cc08b;
            cursor: pointer;
          }
        }
      }
    }

    .page {
      padding-top: 8px;
      text-align: center;

      .el-pagination.is-background {
        .el-pager {
          li:not(.disabled).active {
            background-color: #0cc08b;
          }
        }
      }
    }
  }
  // 弹框样式
  .info-dialog {
    .el-dialog__headerbtn {
      top: 10px;
      right: 10px;

      .el-dialog__close {
        font-size: 24px;
      }
    }

    .dialog-title {
      // padding-left: 42px;
      padding-top: 40px;
      font-size: 24px;
      color: #8565f6;
      text-align: center;
    }

    .dialog-content {
      padding-right: 40px;
      text-align: center;
    }

    .share-title {
      color: #333;
      margin-bottom: 15px;
      font-size: 16px;
      text-align: left;
      padding-left: 100px;
    }

    .el-form {
      .el-form-item__label {
        font-size: 16px;
        color: #5a5a5a;
      }

      .el-form-item__content {
        text-align: left;
      }

      .el-autocomplete {
        width: 100%;
      }

      .el-select {
        width: 100%;
      }
    }

    .share-link-item {
      padding-left: 100px;
      margin-bottom: 15px;
      text-align: left;
    }

    .link-wrapper {
      padding-left: 100px;
      display: flex;
      align-items: center;

      .copy-btn {
        margin-left: 15px;
      }
    }
  }
  .model-refresh {
    width: 40px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    // box-shadow: 0 0 10px 0 rgba(0,0,0,.5);
    > i {
      color: #8565f6;
      font-size: 22px;
      font-weight: 700;
    }
  }
  .zuopin {
    color: #8565f6;
  }
}
</style>
