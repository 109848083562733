<template>
  <div class="collection-sidebar-container">
    <div class="collection-sidebar">
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <div @click="handleChangePreview(item.id, item.type)"
          :class="{ 'side-menu-item': true, 'active-item': Number(currentPreviewId) === item.id }" :key="item.id"
          v-for="item in data">
          <span class='item-name'>
            {{ item.name }}.{{ item.format }}
          </span>
        </div>
      </el-scrollbar>
    </div>
    <div class="load-more-btn">
      <el-button style="color:#8565f6" :loading="fetchLoading" type="text" @click="handleLoadMoreData">加载更多</el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"

/**
 * 首页作品集侧边栏
 */
export default {
  name: "CollectionSidebar",
  components: {
  },
  computed: {
    ...mapState("collectionSidebar", ["data", "fetchLoading", "currentPreviewId"]),
  },
  watch: {
    "$route.query.id": {
      handler(val) {
        this.setCurrentPreviewId(val ?? null)
      },
      immediate: true
    }
  },
  methods: {
    /**
     * 
     */
    handleLoadMoreData() {
      this.$store.dispatch("collectionSidebar/loadMoreCollectionData")
    },
    /**
    * 更改预览项目,如果类型是图片更改预览项，如果是模型则打开模型预览界面
    * @param {number} id 
    * @param {number} type 类型 1：模型 0:图片
    */
    handleChangePreview(id, type) {
      if (type === 1) {
        const r = this.$router.resolve({
          path: this.$route.path,
          query: {
            id
          }
        })
        window.open(r.href, "_blank")
        return
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          id
        }
      })
    },
    ...mapMutations("collectionSidebar", ["setCurrentPreviewId"])
  }
}
</script>

<style lang="less" scoped>
.collection-sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.38s;
  width: 213px;
  height: 100%;
  overflow: scroll;
  padding-bottom: 20px;
}

.collection-sidebar {
  padding: 15px 10px 0 10px;
}

.load-more-btn {
  color: #8565f6;
  text-align: center;
  margin: 0 auto;
  font-size: 13px;
  margin-top: 10px;
  cursor: pointer;
}

.side-menu-item {
  width: 100%;
  padding: 0px 20px;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 4px;

  .item-name {
    line-height: 40px;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }


  &:hover {
    background-color: rgba(103, 63, 245, 0.2);
    color: #999;
  }

  &.active-item {
    background: rgba(103, 63, 245, 0.2);
    color: #8565f6;
  }

}
</style>
